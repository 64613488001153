import lan from '../../translate';
import api from '../api/create';

function getAll(limit=10,page=1) {
    return api.get(`/work-flights?limit=${limit}&skip=${page}`)
}

function getAllFull() {
    return api.get(`/work-flights/full`)
}

function findAllLike(body,limit=1000,page=1) {
    return api.post(`/work-flights/find?limit=${limit}&skip=${page}`,body)
}

function findAllLikeSub(body,limit=1000,page=1) {
    return api.post(`/work-flights/find/findsub?limit=${limit}&skip=${page}`,body)
}

function findMultipleFilters(body,limit=1000,page=1) {
    return api.post(`/work-flights/find/multiple?limit=${limit}&skip=${page}`,body)
}

function findAllForSelect(body,limit=150) {
    return api.post(`/work-flights/find?limit=${limit}`,body)
}

function findRangeDate(body,limit=1000,page=1) {
    return api.post(`/work-flights/find/rangedate?limit=${limit}&skip=${page}`,body)
}

function remove(id) {
    return api.delete(`/work-flights/${id}`)
}

function save(body) {
    return api.post(`/work-flights/`,body)
}

function update(body,id) {
    return api.patch(`/work-flights/${id}`,body)
}


function headers() {
    const headers = {
        ident:lan.workflight_headers_ident,
        count:lan.workflight_headers_count,
        dateStart:lan.workflight_headers_dateStart,
        hour_start:lan.workflight_headers_hour_start,
        airlinesNames:lan.workflight_headers_airlines,
        code: lan.workflight_field_code,
        iataDestination:lan.workflight_headers_destination,
        supplier:lan.workflight_headers_suplier,
        type:{headerName:lan.workflight_headers_type,colorChoose:["COMPRADO:green-color","RESERVA:yellow-color", "EN ESPERA:orange-color","CANCELADO:red-color"]},
        dateCreate:lan.workflight_field_create,
        
    }

    return headers
}

function headersDocuments() {
    const headers = {
        ident:lan.workflight_headers_ident,
        count:lan.workflight_headers_count,
        dateStart:lan.workflight_headers_dateStart,
        dateReturn:lan.workflight_field_date_flight_return,
        hour_start:lan.workflight_headers_hour_start,
        airlinesNames:lan.workflight_headers_airlines,
        code: lan.workflight_field_code,
        iataDestination:lan.workflight_headers_destination,
        supplier:lan.workflight_headers_suplier,
        type:{headerName:lan.workflight_headers_type,colorChoose:["COMPRADO:green-color","RESERVA:yellow-color", "EN ESPERA:orange-color","CANCELADO:red-color"]},
        
    }

    return headers
}





function btnTable() {
    const btn = {
        create:{name:lan.workflight_btn_create, colorClass:"block px-3 py-2 text-sm font-semibold text-center text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" },
        update:{name:lan.airlines_btn_delete, colorClass:"text-blue-primary   hover:text-indigo-900"},
        delete:{name:lan.airlines_btn_update, colorClass:"text-red-800   hover:text-red-900"},
    }
    return btn
}

function btnTableDocuments() {
    const btn = {
       checkbox:{name:"",colorClass:"w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-600"},
       create:{name:lan.workflight_btn_create_document, colorClass:"block px-3 py-2 text-sm font-semibold text-center text-white bg-green-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" },
       update:{name:lan.table_btn_flight_delete, colorClass:"text-blue-primary   hover:text-indigo-900"},
       // delete:{name:lan.table_btn_flight_update, colorClass:"text-red-800   hover:text-red-900"},
    }
    return btn
}

function customFilter() {
    return [
        {
            type:"date",
            field:"dateStart",
            label:"Fecha de salida"
        },
        {
            type:"time",
            field:"flightInfo$hour_start",
            label:"Hora de salida"
        },
        {
            type:"number",
            field:"count",
            label:"Cantidad de pasajeros"
        },
        {
            type:"apiSelect",
            field:"type", 
            api_type:"type_reservation",
            label:"Tipo Rerserva", 
            value:"",
        },

        {
            type:"apiSelect",
            field:"supplier", 
            api_type:"supplier",
            label:"Proveedor", 
            value:"",
        }
    ]
}

export {
    getAll,
    findAllLike,
    findRangeDate,
    headers,
    btnTable,
    remove,
    save,
    update,
    getAllFull,
    findAllForSelect,
    findAllLikeSub,
    customFilter,
    findMultipleFilters,
    btnTableDocuments,
    headersDocuments
}
