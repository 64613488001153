import React, { useEffect, useState, useContext,useCallback } from 'react'
import * as Helpers from '../../helpers/helpers'
import AlertContext from '../../context/contextAlert'
import { useNavigate , useLocation, useLoaderData } from "react-router-dom"
import ServicesContext from '../../context/contextServices'
import lan from '../../translate'
import { country } from '../../helpers/country'
import ProfileContext from '../../context/contextProfile'
import moment from 'moment'
import Select from '../../components/select/select'

const initialState = {
    name:"",
    iata:"",
    icao:"",
    elevation:"",
    city:"",
    state:"",
    country:"",
    lat:"",
    lon:"",
    tz:"",
    dateCreate:moment().format('DD/MM/YYYY'),
    hourCreate:moment().hours() + ":"+ moment().minutes()

}

const fieldRequired = {
    name:true,
    iata:true,
    country:true,
    lat:true,
    lon:true,
    city:true,
    state:true,
}

const selectedValueInfo = {
    value:"",
    label:""
}




export default function AiportsCreate() {

    const services =  useContext(ServicesContext)
    const MySwal = useContext(AlertContext)
    const {user}  =  useContext(ProfileContext)
    const location = useLocation();
    const navigate = useNavigate();
    const [body,setBody] = useState(initialState)
    const [idUser, setIdUser] = useState(0)
    const [isUpdate, setIsUpdate] = useState(false)
    const [required,setRequired] = useState(fieldRequired)
    const [options, setOptions] = useState(selectedValueInfo)


    const setBodyData = useCallback(event => {
        const { name, value } = event.target;
        setBody({ ...body, [name]: value });
        if(!value){
            setRequired({ ...required, [name]: lan.user_alert_fielderror })
        }else{
            setRequired({ ...required, [name]: "" })
        }
    });

    const setBodyDataSelect = useCallback(event => {
        const { name, value } = event.target;
        setBody({ ...body, [name]: value.label });
        if(!value){
            setRequired({ ...required, [name]: lan.user_alert_fielderror })
        }else{
            setRequired({ ...required, [name]: "" })
        }
    });

    const setCheckActive = useCallback(event => {
        const { name, checked } = event.target;
        setBody({ ...body, [name]: checked });
      
    });


    async function getAllValue() {
        const dataServices = await services.Countries.getAllFull()
        const list = dataServices.data
        let allItem = list.map((data,i)=>{
            return {value:data.code,label:data.code}
        })
        setOptions(allItem)
    }

  

    function loadData(state) {
        if(state){
         setRequired({
             no:'',
             name:'',
         })
         setBody(state)
         setIdUser(state._id)
         setIsUpdate(true)
        }
        
    }

    useEffect(()=>{
        loadData(location.state)
        getAllValue()
    },[])


    async function save() {
        if(Helpers.checkIsRequired(required)){

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "warning",
                text:lan.user_alert_field_empty
              })
        }

        if(!Helpers.checkIsRequired(required)){
 
            const result = await services.Airports.save(body)
            if(result.status ===201){
                MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "success",
                    text:lan.alert_create_generic
                  })
                return navigate('/airports')
            }

            if(result.response && result.response.data.statusCode === 400){
                return  MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "error",
                    title:lan.alert_errorcreate_generic,
                    text: "Error: "+result.response.data.message
                    })
            }

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "error",
                text:lan.user_alert_error500
                })
                
        }
    }

    async function update() {
        delete body.create
        delete body.__v
        delete body._id
        delete body.dateCreate
        delete body.hourCreate
        
        if(Helpers.checkIsRequired(required)){

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "warning",
                text:lan.user_alert_field_empty
              })
        }

        if(!Helpers.checkIsRequired(required) ){
            const result = await services.Airports.update(body,idUser)
            if(result.status ===200){
                MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "success",
                    text:lan.alert_update_generic
                  })
                return 
            }

            if(result.response && result.response.data.statusCode === 400){
                return  MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "error",
                    title:lan.alert_errorupdate_generic,
                    text: "Error: "+result.response.data.message
                    })
            }

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "error",
                text:lan.user_alert_error500
                })
        }
        
        
    }

    return (
        <div className="space-y-10 divide-y divide-gray-900/10">
        <div className="grid grid-cols-1 pt-10 gap-x-8 gap-y-8 md:grid-cols-3">
            
          <div className="px-4 sm:px-0">
            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">{lan.airport_titlecreate}</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">{lan.airport_titlecreatesu}</p>
          </div>
    
          <div className="bg-white shadow-sm dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-slate-800 ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
            <div className="px-4 py-6 sm:p-8">
              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                   {lan.airport_field_name} <span className={required.name?"text-red-700":""}>*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name={"name"}
                      id={"name"}
                      value={body.name}
                      onChange={setBodyData}
                      autoComplete="given-name"
                      className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                    />
                  </div>
                  <span className='text-xs text-red-700'>{required.name?required.name:""}</span>
                </div>
    
                <div className="sm:col-span-3">
                  <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                   {lan.airport_field_iata} <span className={required.iata?"text-red-700":""}>*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name={"iata"}
                      id={"iata"}
                      value={body.iata}
                      onChange={setBodyData}
                      autoComplete="given-name"
                      className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                    />
                  </div>
                  <span className='text-xs text-red-700'>{required.iata?required.iata:""}</span>
    
                  
                </div>
    
    
                <div className="sm:col-span-3">
                  <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                   {lan.airport_field_icao} <span className={required.icao?"text-red-700":""}>*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name={"icao"}
                      id={"icao"}
                      value={body.icao}
                      onChange={setBodyData}
                      autoComplete="given-name"
                      className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                    />
                  </div>
                  <span className='text-xs text-red-700'>{required.icao?required.icao:""}</span>
    
                  
                </div>
    
    
                <div className="sm:col-span-3">
                  <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                   {lan.airport_field_elevation_ft} <span className={required.elevation?"text-red-700":""}>*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name={"elevation"}
                      id={"elevation"}
                      value={body.elevation}
                      onChange={setBodyData}
                      autoComplete="given-name"
                      className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                    />
                  </div>
                  <span className='text-xs text-red-700'>{required.elevation?required.elevation:""}</span>
    
                  
                </div>
    
    
                <div className="sm:col-span-3">
                  <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                   {lan.airport_field_gps_city} <span className={required.city?"text-red-700":""}>*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name={"city"}
                      id={"city"}
                      value={body.city}
                      onChange={setBodyData}
                      autoComplete="given-name"
                      className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                    />
                  </div>
                  <span className='text-xs text-red-700'>{required.city?required.city:""}</span>
    
                  
                </div>
    
    
                <div className="sm:col-span-3">
                  <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                   {lan.airport_field_ident} <span className={required.state?"text-red-700":""}>*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name={"state"}
                      id={"state"}
                      value={body.state}
                      onChange={setBodyData}
                      autoComplete="given-name"
                      className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                    />
                  </div>
                  <span className='text-xs text-red-700'>{required.state?required.state:""}</span>
    
                  
                </div>
    
                <div className="sm:col-span-3">
                  <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                   {lan.airport_field_iso_country} <span className={required.country?"text-red-700":""}>*</span>
                  </label>
                  <Select 
                  options={options} 
                  nameDefault={"country"}
                  onMethod={setBodyDataSelect} 
                  valueDefault={body.country}/>
                  <span className='text-xs text-red-700'>{required.country?required.country:""}</span>
    
                  
                </div>
                <div className="sm:col-span-3">
                  <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                   {lan.airport_field_iso_lan} <span className={required.lat?"text-red-700":""}>*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name={"lat"}
                      id={"lat"}
                      value={body.lat}
                      onChange={setBodyData}
                      autoComplete="given-name"
                      className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                    />
                  </div>
                  <span className='text-xs text-red-700'>{required.lat?required.lat:""}</span>
    
                  
                </div>
                <div className="sm:col-span-3">
                  <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                   {lan.airport_field_lon} <span className={required.lon?"text-red-700":""}>*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name={"lon"}
                      id={"lon"}
                      value={body.lon}
                      onChange={setBodyData}
                      autoComplete="given-name"
                      className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                    />
                  </div>
                  <span className='text-xs text-red-700'>{required.lon?required.lon:""}</span>
    
                  
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                   {lan.airport_field_tz} <span className={required.tz?"text-red-700":""}>*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name={"tz"}
                      id={"tz"}
                      value={body.tz}
                      onChange={setBodyData}
                      autoComplete="given-name"
                      className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                    />
                  </div>
                  <span className='text-xs text-red-700'>{required.tz?required.tz:""}</span>
    
                  
                </div>

            
    
     
                
              </div>
            </div>
           
          </div>
        </div>
    
        
    
       
    
        <div className="flex items-center justify-end px-4 py-4 border-t col-span-full gap-x-6 border-gray-900/10 dark:border-white/10 sm:px-8">
              <button type="button" className="text-sm font-semibold leading-6 text-blue-primary" onClick={()=>navigate(-1)}>
                {lan.user_button_cancel}
              </button>
              <button
                type="submit"
                onClick={()=> isUpdate? update() : save()}
                className="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-blue-primary hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {lan.user_button_save}
              </button>
            </div>
      </div>
      )

}