import lan from '../../translate';
import api from '../api/create';

function getAll(limit=10,page=1) {
    return api.get(`/rutes-flights?limit=${limit}&skip=${page}`)
}

function getFlightById(id) {
    return api.get(`/rutes-flights/${id}`)
}

function findAllForSelect(body,limit=150) {
    return api.post(`/rutes-flights/find?limit=${limit}`,body)
}


function findAllLike(body,limit=1000,page=1) {
    return api.post(`/rutes-flights/find?limit=${limit}&skip=${page}`,body)
}

function findAllLikeSub(body,limit=1000,page=1) {
    return api.post(`/rutes-flights/find/findsub?limit=${limit}&skip=${page}`,body)
}

function findRangeDate(body,limit=1000,page=1) {
    return api.post(`/rutes-flights/find/rangedate?limit=${limit}&skip=${page}`,body)
}

function remove(id) {
    return api.delete(`/rutes-flights/${id}`)
}

function save(body) {
    return api.post(`/rutes-flights/`,body)
}

function update(body,id) {
    return api.patch(`/rutes-flights/${id}`,body)
}


function headers() {
    const headers = {
        nameRute:lan.rute_fligt_field_name_rute,
        no_flight:lan.rute_fligt_field_no_fligt,
        hour_start:lan.rute_fligt_field_hour_start,
        iataOrigin:lan.rute_fligt_field_iata_origin,
        iataDestination:lan.rute_fligt_field_iata_departure,
    }

    return headers
}


function btnTable() {
    const btn = {
        create:{name:lan.table_btn_flight_create, colorClass:"block px-3 py-2 text-sm font-semibold text-center text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" },
        update:{name:lan.table_btn_flight_delete, colorClass:"text-blue-primary   hover:text-indigo-900"},
        delete:{name:lan.table_btn_flight_update, colorClass:"text-red-800   hover:text-red-900"},
    }
    return btn
}



export {
    getAll,
    findAllLike,
    findRangeDate,
    headers,
    btnTable,
    remove,
    save,
    update,
    findAllLikeSub,
    getFlightById,
    findAllForSelect
}
