import React, {lazy} from 'react';
const languaje = localStorage.getItem("lan")
const languaJson = JSON.parse(localStorage.getItem("lan-t"))
console.log('languaJson :>> ', languaJson);

if(!localStorage.getItem("lan-t")){
    let lan = (await import("./trans.es")).default;
    localStorage.setItem("lan-t",JSON.stringify(lan))
    window.location.href = "/login"
    
}







export default  languaJson