import React, { useEffect,useReducer, useState, useContext,useCallback } from 'react'
import * as Helpers from '../../helpers/helpers'
import AlertContext from '../../context/contextAlert'
import { PlusIcon } from '@heroicons/react/24/solid'

import { useNavigate , useLocation, useLoaderData } from "react-router-dom"
import FileDocument from '../../components/files/fileDocument'

import ServicesContext from '../../context/contextServices'
import lan from '../../translate'
import { country } from '../../helpers/country'
import ProfileContext from '../../context/contextProfile'
import moment from 'moment'
import Select from '../../components/select/select'
import DinamicField from '../../components/customForm/dinamicFields'
import Combobox from '../../components/combobox/combobox'
import FileOneDocument from '../../components/files/fileOneDocument'
const selectedValueInfo = {
    value:"",
    label:""
}


const initialState = {
    
    images:[],

}

export default function ToursCreate() {

    const services =  useContext(ServicesContext)
    const MySwal = useContext(AlertContext)
    const {user}  =  useContext(ProfileContext)
    const location = useLocation();
    const navigate = useNavigate();
    const [body,setBody] = useState([])
    
    const [customFields,setCustomFields] = useState([])
    const [idUser, setIdUser] = useState(0)
    const [isUpdate, setIsUpdate] = useState(false)
    const [required,setRequired] = useState([])
    const [options, setOptions] = useState(selectedValueInfo)
    let [lanList, dispatch] = useReducer((lanList, { type, value, position }) => {
        switch (type) {
          case "add":
            return [...lanList, value];
          case "update":
            lanList[position] =  value
            return [...lanList];
          case "remove":
            return lanList.filter((_, index) => index !== value);
          case "load":
            return value
          default:
            return lanList;
        }
      }, []);
    
    let [fileList, dispatchFile] = useReducer((fileList, { type, value, position }) => {
    switch (type) {
        case "add":
        return [...fileList, value];
        case "show":
        return value
        case "update":
            fileList[position] =  value
        return [...fileList];
        case "remove":
        return fileList.filter((data,i)=> data.name !== value)
        case "load":
        return value
        default:
        return fileList;
    }
    }, []);


    let [imageList, dispatchImage] = useReducer((imageList, { type, value, position }) => {
        switch (type) {
            case "add":
            return [...imageList, value];
            case "show":
            return value
            case "update":
                imageList[position] =  value
            return [...imageList];
            case "remove":
            return imageList.filter((data,i)=> data.name !== value)
            case "load":
            return value
            default:
            return imageList;
        }
        }, []);



    function sumPriceSell(e) {
        let { name, value,type,checked } = e.target;
        console.log('name,value :>> ', name,value);
        if(name === "ticket_cost" || name === "earnings" || 
        name === "price_cost" || name === "price_sell" ){
            let total = parseFloat(body.ticket_cost) + 
            parseFloat(body.price_cost) + parseFloat(body.earnings)
            setBody({...body,price_sell:total})
        }
        
    }


    const setBodyData = useCallback(event => {
        console.log('event :>> ', event);
        let { name, value,type,checked } = event.target;

        if(type==="checkbox" || type==="switch"){
            setBody({ ...body, [name]: checked });
            setRequired({ ...required, [name]: "" })
            body[name] = checked
            required[name] = checked
        }else{
            value = type ==="number"? parseFloat(value):value
            setBody({ ...body, [name]: value });
            if(!value){
                setRequired({ ...required, [name]: lan.user_alert_fielderror })
            }else{
                setRequired({ ...required, [name]: "" })
            }
            body[name] = value
            required[name] = value
        }
        
        console.log('required', required)
        

    });

    const setBodyDataSelect = useCallback(event => {
        const { name, value } = event.target;
        setBody({ ...body, [name]: value.label });
        if(!value){
            setRequired({ ...required, [name]: lan.user_alert_fielderror })
        }else{
            setRequired({ ...required, [name]: "" })
        }
    });

    const setCheckActive = useCallback(event => {
        const { name, checked } = event.target;
        setBody({ ...body, [name]: checked });
      
    });


    

  

    function loadData(state) {
        console.log('state', state)

        if(state){
         setRequired({
             no:'',
             name:'',
         })
         dispatch({ type: "load", value: state.language, position:0})
         dispatchFile({ type: "show", value: state.images, position:0})
         dispatchImage({ type: "show", value: state.portrait, position:0})
         setIdUser(state._id)
         setIsUpdate(true)
         setBody({...state})
        }
        
    }

    function loadFields() {
        
        const result = services.Tour.customFields()
        console.log('result', result)
         result.map((b,i)=>{
            body[b.name] = b.value
            required[b.name] = b.required
        })
        setBody({
            ...body,
            dateCreate:moment().format('DD/MM/YYYY'),
            hourCreate:moment().hours() + ":"+ moment().minutes(),
            user_id:user._id
        })
        setCustomFields(result)
    }

    useEffect(()=>{
        
   
        
        if(body.length===0){
            loadFields()
            loadData(location.state)
        }
    },[body])


    async function save() {
        body.images = []
        body.portrait = []
        console.log('imagesList :>> ', imageList);
        delete body.has_transport
        MySwal.fire({
            title: lan.loading_save,
            allowOutsideClick: false,
          })
          MySwal.showLoading();
        if(Helpers.checkIsRequired(required) ||  lanList.length ===0){

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "warning",
                text:lan.user_alert_field_empty
              })
        }
       

        if(!Helpers.checkIsRequired(required) && lanList.length>0){
            if(fileList.length>0){
                console.log('fileList save', fileList)
                const resultFile = await services.File.uplodMultipleFile({files:fileList})
                if(resultFile.status !== 200 && resultFile.status !==201){
                  return  MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "error",
                    title:lan.alert_errorcreate_generic,
                    text: "Error: FileServer"
                    }) 
                }
                body.images = resultFile.data
            }
            if(imageList.length>0){
                console.log('imageList save', imageList)
                const resultFileImage = await services.File.uplodMultipleFile({files:imageList})
                if(resultFileImage.status !== 200 && resultFileImage.status !==201){
                  return  MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "error",
                    title:lan.alert_errorcreate_generic,
                    text: "Error: FileServer"
                    }) 
                }
                body.portrait = resultFileImage.data
            }
            console.log('body.portrait', body.portrait)
            body.language = lanList;
            const result = await services.Tour.save(body)
            if(result.status ===201){
                MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "success",
                    text:lan.alert_create_generic
                  })
                return navigate('/worktours/tours')
            }

            if(result.response && result.response.data.statusCode === 400){
                return  MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "error",
                    title:lan.alert_errorcreate_generic,
                    text: "Error: "+result.response.data.message
                    })
            }

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "error",
                text:lan.user_alert_error500
                })
                
        }
    }

    async function update() {
        MySwal.fire({
            title: lan.loading_save,
            allowOutsideClick: false,
          })
          MySwal.showLoading();
        delete body.create
        delete body.__v
        delete body._id
        delete body.dateCreate
        delete body.hourCreate
        delete body.userInfo
        delete body.dateStart
        delete body.has_transport
        delete body.has_breaksfast
        if(Helpers.checkIsRequired(required) ||  lanList.length ===0){

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "warning",
                text:lan.user_alert_field_empty
            })
        }
        if(!Helpers.checkIsRequired(required) && lanList.length>0 ){
            const diffFile =  fileList.filter(
                fileBody => !body.images.some(newFile => fileBody.name === newFile.name)
                )
                
                if(diffFile.length>0){
                  const resultFile = await services.File.uplodMultipleFile({files:diffFile})
                  if(resultFile.status !== 200 && resultFile.status !==201){
                    return  MySwal.fire({
                      confirmButtonColor: '#1EA0D5',
                      icon: "error",
                      title:lan.alert_errorcreate_generic,
                      text: "Error: FileServer"
                      }) 
                  }
                  for (const key in resultFile.data) {
                    body.images.push(resultFile.data[key])
                  }
                 
                }else{
                    body.images = fileList
                }

                const diffFileImage =  imageList.filter(
                    fileBody => !body.portrait.some(newFile => fileBody.name === newFile.name)
                    )
                    
                    if(diffFileImage.length>0){
                      const resultFileImage = await services.File.uplodMultipleFile({files:diffFileImage})
                      if(resultFileImage.status !== 200 && resultFileImage.status !==201){
                        return  MySwal.fire({
                          confirmButtonColor: '#1EA0D5',
                          icon: "error",
                          title:lan.alert_errorcreate_generic,
                          text: "Error: FileServer"
                          }) 
                      }
                      for (const key in resultFileImage.data) {
                        body.portrait.push(resultFileImage.data[key])
                      }
                     
                    }else{
                        body.portrait = imageList
                    }
            body.language = lanList
            body.country = body.country?._id? body.country._id:body.country
           // body.images = fileList
            console.log('body.images', body.images)

            const result = await services.Tour.update(body,idUser)
            if(result.status ===200){
                MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "success",
                    text:lan.alert_update_generic
                  })
                  navigate("/worktours/tours")
                return 
            }

            if(result.response && result.response.data.statusCode === 400){
                return  MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "error",
                    title:lan.alert_errorupdate_generic,
                    text: "Error: "+result.response.data.message
                    })
            }

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "error",
                text:lan.user_alert_error500
                })
        }
        
        
    }

    useEffect(()=>{
    },[lanList])

  
    

    return (
        <div className="space-y-10 divide-y divide-gray-900/10">
        <div className="grid grid-cols-1 pt-10 gap-x-8 gap-y-8 md:grid-cols-3">
            
          <div className="px-4 sm:px-0">
            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">{lan.tour_title}</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">{lan.tour_titlesub}</p>
          </div>
    
          <div className="bg-white shadow-sm dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-slate-800 ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
            <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                {customFields.length>0&&customFields.map((fields,i)=>
                    <DinamicField 
                    type={fields.type}
                    value={body[fields.name]}
                    name={fields.name}
                    options={fields.options}
                    required={required[fields.name]}
                    onBlur={sumPriceSell}
                    label={fields.label}
                    setBodyData={setBodyData}
                    />
                )}
            </div>
                <br/>
                <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                {lan.tour_field_country} <span className={required.country?"text-red-700":""}>*</span>
                </label>
                <Combobox
                nameDefault={"country"}
                labels={["name","code"]}
                services={services.Countries.findAllLike} 
                value={body.country?.name}
                method={setBodyData}/>

                <span className='text-xs text-red-700'>{required.country?required.country:""}</span>
                </div>

            </div>
            
           
          </div>
        </div>


        <div className="grid grid-cols-1 pt-10 gap-x-8 gap-y-8 md:grid-cols-3">
            
          <div className="px-4 sm:px-0">
            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">{lan.tour_field_language}</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">{lan.generic_list_subtitle}</p>
          </div>
    
          <div className="bg-white shadow-sm dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-slate-800 ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
            <div className="px-4 py-6 sm:p-8">
                
            {lanList.length>0&& lanList.map((data,i) =>
            
                <div className="grid max-w-3xl grid-cols-1 p-1 gap-x-8 gap-y-8 sm:grid-cols-3">
                   
                    <input type='text' id={'list'+i} onChange={(e)=> dispatch({ type: "update", value: e.target.value, position:i})} value={lanList[i]}  className='block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6'/>
                    <div>
                        <button type="button" onClick={()=>dispatch({ type: "remove", value: i, position:i})} className="px-3 py-2 text-sm font-semibold text-white bg-red-700 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" >
                            {lan.generic_list_btn_delete}
                        </button>
                    </div>
                
                </div>  
            )}
            <br/>
            <button type="button" onClick={()=>dispatch({ type: "add", value: "", position:0})} className="px-3 py-2 text-sm font-semibold text-white bg-green-700 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" >
                {lan.generic_list_btn_add}
            </button>
            </div>

        
           
          </div>
        </div>
        <FileOneDocument
            dispatchFile={ dispatchImage }
            fileList={imageList}
            ruteFile={"trolbis/tours/individual/portrait/"+body.name}
            category={"portrait"}
            accept={".jpg,.jpeg,.png"}
            title={lan.tour_imges_portrait}
            subtitle={lan.tour_imges_sub_portrait}
        />


        <div  className="grid grid-cols-1 pt-10 gap-x-8 gap-y-8 md:grid-cols-3">
        
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">{lan.tour_field_images}</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">{lan.tour_imges_file}</p>
        </div>
  
        <div className="bg-white shadow-sm dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-slate-800 ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

        
            {fileList.length>0&&fileList.map((data,i)=>
              <div className="flex flex-wrap sm:col-span-3">
                <FileDocument ruteFile={"trolbis/tours/individual"+body.name} category={"images"}  position={i} fileList={fileList} setMethod={dispatchFile}/>
              </div>
            )}
             <div className="sm:col-span-3">
              <div onClick={()=>dispatchFile({ type: "add", value: "", position:0})} className='flex flex-wrap w-full gap-12 cursor-pointer'>
                <div className="flex justify-center px-6 py-8 mt-2 border border-indigo-600 border-dashed rounded-lg">
                  <div className="text-center">
                    <PlusIcon  className="h-8 mx-auto text-gray-300 w-15" aria-hidden="true"/>
                    <div className="flex justify-center mt-4 text-sm leading-6 text-center text-gray-600">
                     
                    </div>
                    <p className="text-xs leading-5 text-gray-600"> Agregar  Archivos </p>

                  </div>
              </div>
              </div>
                </div>
              
            </div>
          </div>
         
        </div>
    </div>
        
    
        
    
       
    
        <div className="flex items-center justify-end px-4 py-4 border-t col-span-full gap-x-6 border-gray-900/10 dark:border-white/10 sm:px-8">
              <button type="button" className="text-sm font-semibold leading-6 text-blue-primary" onClick={()=>navigate(-1)}>
                {lan.user_button_cancel}
              </button>
              <button
                type="submit"
                onClick={()=> isUpdate? update() : save()}
                className="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-blue-primary hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {lan.user_button_save}
              </button>
            </div>
      </div>
      )

}