import { useContext, useEffect, useState } from 'react'
import lan from '../../translate'

export default function ModalDocuments({docs=[],services}) {

    const [hotels,setHotels] = useState([])
    const [tours,setTours] = useState([])
    const [seguro,setSeguro] = useState([])
    const [returnFlight,setReturnFlight] = useState([])

    async function findAllLikeDocs(key,value) {
        let bodyFind = {
             key:key,
             value:value
        }
        const resul = await services.File.findAllLike(bodyFind)
        if(resul.status === 201 && resul.data.results.length>0){
            return resul.data.results
        }
        return []
        
    }
   
    async function loadData(params) {
        setHotels(await findAllLikeDocs("category","hoteles"))
        setTours(await findAllLikeDocs("category","tours"))
        setSeguro(await findAllLikeDocs("category","seguros"))
        setReturnFlight(await findAllLikeDocs("category","flightReturn"))
    }

    function setTemplate(e,data,option) {
        data[option] = e.target.value
    }

    useEffect( ()=>{
        loadData()
        console.log("Corriendo");
    },[])

    useEffect(()=>{

    },[docs])

  return (
    <div className='min-w-full'>
        <div>
            <center><b><h3>{lan.workflight_title_modal_doc}</h3></b></center>
        </div>
        <br/>
        <div className='inline-block w-full py-2 align-middle sm:px-6 lg:px-8'>
            <table className='w-full divide-y divide-gray-300'>
                {docs.length>0&& docs.map((data,i)=>
                    <tr >
                        
                        <td >{data.ident}</td>
                        <td className='p-1'>
                            <label>Hotel:</label>
                            <select onChange={(e)=> setTemplate(e,data,'hotelTemplate')} className='block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6'>
                                <option className='dark:bg-slate-900' value={false}>Seleccione</option>
                                {hotels.length>0&& hotels.map((data,i)=>
                                    <option className='dark:bg-slate-900' value={data.name}>{data.name}</option>
                                )}
                            </select>
                        </td>
                        <td className='p-1'>
                            <label>Seguro:</label>
                            <select onChange={(e)=> setTemplate(e,data,'seguroTemplate')} className='block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6'>
                            <option className='dark:bg-slate-900' value={false}>Seleccione</option>
                            {seguro.length>0&& seguro.map((data,i)=>
                                <option className='dark:bg-slate-900' value={data.name}>{data.name}</option>
                            )}
                            </select>
                        </td>
                        <td className='p-1'>
                            <label>Tours:</label>
                            <select onChange={(e)=> setTemplate(e,data,'tourTemplate')} className='block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6'>
                            <option className='dark:bg-slate-900' value={false}>Seleccione</option>
                            {tours.length>0&& tours.map((data,i)=>
                                <option className='dark:bg-slate-900' value={data.name}>{data.name}</option>
                            )}
                            </select>
                        </td>
                        <td className='p-1'>
                            <label>Eticket:</label>
                            <select onChange={(e)=> setTemplate(e,data,'eticket')} className='block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6'>
                                <option className='dark:bg-slate-900' value={false}>Seleccione</option>
                                <option className='dark:bg-slate-900' value={"eticketExit"}>Salida</option>
                            </select>
                        </td>
                        <td className='p-1'>
                            <label>Regreso:</label>
                            <select onChange={(e)=> setTemplate(e,data,'flightReturnTemplate')} className='block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6'>
                            <option className='dark:bg-slate-900' value={false}>Seleccione</option>
                            {returnFlight.length>0&& returnFlight.map((data,i)=>
                                <option className='dark:bg-slate-900' value={data.name}>{data.name}</option>
                            )}
                            </select>
                        </td>
                    </tr>

                )}
            </table>
            
        </div>
    </div>
  )
}
