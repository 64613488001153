import React, { useEffect } from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'

export default function SelectComponent({options=[],onMethod,valueDefault,nameDefault,onMethodClick=false, asyncOption=false}) {

 
       
    if(asyncOption){
        return (
            <AsyncSelect 
            options={options} 
            cacheOptions
            defaultOptions
            loadOptions={(value)=>onMethod({target:{value:value,name:nameDefault}})}
            onChange={(value)=>onMethodClick({target:{value:value,name:nameDefault}})}
            name={nameDefault}
            id={nameDefault}
            value={valueDefault}
            defaultInputValue={valueDefault}
            defaultValue={valueDefault}
            styles={{
             control: (baseStyles, state) => ({
               ...baseStyles,
               backgroundColor: 'transparent',
               borderColor:"#3F4958",
               color:"#025798",
             }),
             option: (provided, state) => ({
                 ...provided,
                 color:"black"
             }),
             placeholder: (provided, state) => ({
               ...provided,
               color:"#3F4958 "
             }),
             singleValue:(provided, state) => ({
               ...provided,
               color: window.matchMedia('prefers-color-scheme: dark').matches? "white":"#b5b5b5"
             }),
           }}
           />
          )
    }

    if(!asyncOption){
        return (
            <Select 
            options={options} 
            onChange={(value)=>onMethod({target:{value:value,name:nameDefault}})}
            name={nameDefault}
            id={nameDefault}
            value={options.length>0&&options.filter(option => 
                option.label === valueDefault)
            }
            defaultInputValue={valueDefault}
            defaultValue={valueDefault}
            styles={{
             control: (baseStyles, state) => ({
               ...baseStyles,
               backgroundColor: 'transparent',
               borderColor:"#3F4958",
               color:"#025798",
             }),
             option: (provided, state) => ({
                 ...provided,
                 color:"black"
             }),
             placeholder: (provided, state) => ({
               ...provided,
               color:"#3F4958 "
             }),
             singleValue:(provided, state) => ({
               ...provided,
               color: window.matchMedia('prefers-color-scheme: dark').matches? "white":"#b5b5b5"
             }),
           }}
           />
          )
    }
  
}
