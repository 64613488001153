import lan from '../../translate';
import api from '../api/create';

function getAll(limit=10,page=1) {
    return api.get(`/tours?limit=${limit}&skip=${page}`)
}

function getFlightById(id) {
    return api.get(`/tours/${id}`)
}

function getAllPackage(limit=10,page=1) {
    return api.get(`/tours/package?limit=${limit}&skip=${page}`)
}

function getAllBookings(limit=10,page=1) {
    return api.get(`/tours/bookings?limit=${limit}&skip=${page}`)
}

function getAllToursAndPackage(body) {
    return api.post(`/tours/package/tours/`,body)
}

function createFullPackage(body) {
    return api.post(`/tours/package`,body)
}

function createBookings(body) {
    return api.post(`/tours/bookings`,body)
}

function findAllForSelect(body,limit=150) {
    return api.post(`/tours/find?limit=${limit}`,body)
}


function findAllLike(body,limit=1000,page=1) {
    return api.post(`/tours/find?limit=${limit}&skip=${page}`,body)
}

function findMultipleFilters(body,limit=1000,page=1) {
    return api.post(`/tours/find/multiple?limit=${limit}&skip=${page}`,body)
}

function findMultipleFiltersPackage(body,limit=1000,page=1) {
    return api.post(`/tours/package/find/multiple?limit=${limit}&skip=${page}`,body)
}

function findAllLikeSub(body,limit=1000,page=1) {
    return api.post(`/tours/find/findsub?limit=${limit}&skip=${page}`,body)
}

function findRangeDate(body,limit=1000,page=1) {
    return api.post(`/tours/find/rangedate?limit=${limit}&skip=${page}`,body)
}

function remove(id) {
    return api.delete(`/tours/${id}`)
}

function removePackage(id) {
    return api.delete(`/tours/package/${id}`)
}

function save(body) {
    return api.post(`/tours/`,body)
}

function update(body,id) {
    return api.patch(`/tours/${id}`,body)
}

function updatePackage(body,id) {
    return api.patch(`/tours/package/${id}`,body)
}

function removeBookings(id) {
    return api.delete(`/tours/bookings/${id}`)
}

function updateBookings(body,id) {
    return api.patch(`/tours/bookings/${id}`,body)
}


function headers() {
    const headers = {
        name:lan.tour_field_name,
        country:{headerName:lan.tour_field_country, keyObject:"name"},
        city:lan.tour_field_city,
        type_tour:lan.tour_field_type_tour,
        price_cost:{headerName:lan.tour_field_price_cost,typeField:"currency"},
    }

    return headers
}

function headersPackage() {
    const headers = {
        name:lan.tour_field_name,
        country:{headerName:lan.tour_field_country, keyObject:"name"},
        tourNames:lan.tour_names_include,
        date_start:lan.tour_field_date_start,
        price_cost:{headerName:lan.tour_field_price_cost,typeField:"currency"},
        price_sell:{headerName:lan.tour_field_price_sell,typeField:"currency"},
        
    }

    return headers
}

function headersBookings() {
    const headers = {
        name:lan.tour_name_booking,
        description:lan.tour_names_include,
        quotes_id:"Id",
        price_total:{headerName:lan.tour_field_price_sell,typeField:"currency"},
        dateCreate:lan.tour_date_Create_bookings,

    }

    return headers
}


function btnTable() {
    const btn = {
        create:{name:lan.workflight_btn_create, colorClass:"block px-3 py-2 text-sm font-semibold text-center text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" },
        update:{name:lan.table_btn_flight_delete, colorClass:"text-blue-primary   hover:text-indigo-900"},
        delete:{name:lan.table_btn_flight_update, colorClass:"text-red-800   hover:text-red-900"},
    }
    return btn
}



function customFiledsPackage() {
    return  [
        {
            name:"name",
            value:"",
            required:true,
            type:"text",
            label:lan.tour_field_name
        },
        {
            name:"date_start",
            value:"",
            required:true,
            type:"date",
            label:lan.tour_field_date_start
        },
        {
            name:"description",
            value:"",
            required:true,
            type:"textarea",
            label:lan.tour_field_description
        },
        {
            name:"duration",
            value:"",
            required:true,
            type:"text",
            label:lan.tour_field_duration
        },
        {
            name:"price_cost",
            value:"",
            required:true,
            type:"number",
            label:lan.tour_field_price_cost
        },
        {
            name:"price_sell",
            value:"",
            required:true,
            type:"number",
            label:lan.tour_field_price_sell
        },

        {
            name:"is_offer",
            value:false,
            required:"",
            type:"switch",
            label:lan.tour_field_is_offer,

        },
    ]
}
function customFiledsBookings() {
    return  [
        {
            name:"name",
            value:"",
            required:true,
            type:"text",
            label:lan.tour_name_booking
        },
        {
            name:"date_booking",
            value:"",
            required:true,
            type:"date",
            label:lan.tour_field_date_start
        },
        {
            name:"tax",
            value:"",
            required:"",
            type:"number",
            label:"Tax"
        },
        {
            name:"price_total",
            value:"",
            required:"",
            type:"number",
            label:lan.tour_field_price_sell
        },
        {
            name:"description",
            value:"",
            required:"",
            type:"textarea",
            label:lan.tour_field_description
        }
        
    ]
}
function customFields() {
    return  [
        {
            name:"name",
            value:"",
            required:true,
            type:"text",
            label:lan.tour_field_name
        },
        {
            name:"description",
            value:"",
            required:true,
            type:"textarea",
            label:lan.tour_field_description,
        },
        {
            name:"city",
            value:"",
            required:true,
            type:"text",
            label:lan.tour_field_city,
        },
        {
            name:"modality",
            value:"",
            required:true,
            type:"selectLocal",
            label:lan.tour_field_modality,
            options:[
                  {
                    "value": "private",
                    "label": "private"
                  },
                  {
                    "value": "public",
                    "label": "public"
                  }
            ]

        },

        {
            name:"type_tour",
            value:"",
            required:true,
            type:"selectLocal",
            label:lan.tour_field_type_tour,
            options:[
                  {
                    "value": "internacional",
                    "label": "internacional"
                  },
                  {
                    "value": "nacional",
                    "label": "nacional"
                  }
            ]

        },

        {
            name:"pax_counter_min",
            value:"",
            required:true,
            type:"number",
            label:lan.tour_field_pax_counter_min,

        },

        {
            name:"pax_counter_max",
            value:"",
            required:true,
            type:"number",
            label:lan.tour_field_pax_counter_max,

        },
        {
            name:"ticket_cost",
            value:"",
            required:true,
            type:"number",
            label:lan.tour_field_ticket_cost,

        },

        {
            name:"price_cost",
            value:"",
            required:true,
            type:"number",
            label:lan.tour_field_price_cost,

        },

        {
            name:"earnings",
            value:"",
            required:"",
            type:"number",
            label:lan.tour_field_earnings,

        },

        {
            name:"price_sell",
            value:"",
            required:"",
            type:"number",
            label:lan.tour_field_price_sell,

        },

        

        {
            name:"last_day_for_payment",
            value:"",
            required:true,
            type:"text",
            label:lan.tour_field_last_day_for_payment,

        },

        {
            name:"hour_exit",
            value:"",
            required:true,
            type:"time",
            label:lan.tour_field_hour_exit,

        },
        {
            name:"hour_arrive",
            value:"",
            required:true,
            type:"time",
            label:lan.tour_field_hour_arrive,

        },

        {
            name:"duration",
            value:"",
            required:true,
            type:"text",
            label:lan.tour_field_duration,

        },

        {
            name:"difficulty",
            value:"",
            required:true,
            type:"selectLocal",
            label:lan.tour_field_difficulty,
            options:[
                  {
                    "value": 1,
                    "label": "facil"
                  },
                  {
                    "value": 2,
                    "label": "intermedio"
                  },
                  {
                    "value": 3,
                    "label": "dificil"
                  },
                  {
                    "value": 4,
                    "label": "extremo"
                  },
            ]

        },
        {
            name:"transport",
            value:"",
            required:true,
            type:"selectLocal",
            label:lan.tour_field_has_transport,
            options:[
                  {
                    "value": "TERRESTRE",
                    "label": "TERRESTRE"
                  },
                  {
                    "value": "MARITIMO",
                    "label": "MARITIMO"
                  },
                  {
                    "value": "AEREO",
                    "label": "AEREO"
                  },
            ]

        },
        {
            name:"has_refound",
            value:false,
            required:"",
            type:"checkbox",
            label:lan.tour_field_has_refound,

        },
        {
            name:"has_breakfast",
            value:false,
            required:"",
            type:"checkbox",
            label:lan.tour_field_has_break,

        },
        {
            name:"has_lunch",
            value:false,
            required:"",
            type:"checkbox",
            label:lan.tour_field_has_lunch,

        },
        {
            name:"has_dinner",
            value:false,
            required:"",
            type:"checkbox",
            label:lan.tour_field_has_dinner,

        },
        {
            name:"has_drinks_alcohol",
            value:false,
            required:"",
            type:"checkbox",
            label:lan.tour_field_has_drinks_alcohol,

        },
        {
            name:"has_drinks",
            value:false,
            required:"",
            type:"checkbox",
            label:lan.tour_field_has_drinks,

        },
        {
            name:"has_water",
            value:false,
            required:"",
            type:"checkbox",
            label:lan.tour_field_has_drinks_water,

        },
        {
            name:"has_open_bar",
            value:false,
            required:"",
            type:"checkbox",
            label:lan.tour_field_has_open_bar,

        },
        {
            name:"has_stop",
            value:false,
            required:"",
            type:"checkbox",
            label:lan.tour_field_has_stop,

        },
        
        {
            name:"tour_create_for_one_person",
            value:"",
            required:true,
            type:"selectLocal",
            label:lan.tour_field_tour_create_for_one_person,
            options:[
                  {
                    "value": "SI",
                    "label": "SI"
                  },
                  {
                    "value": "SI SUJETO A CAMBIO",
                    "label": "SI SUJETO A CAMBIO"
                  },
                  {
                    "value": "NO",
                    "label": "NO"
                  },
            ]

        },

        {
            name:"has_more_starting",
            value:false,
            required:"",
            type:"checkbox",
            label:lan.tour_field_has_more_starting,

        },
        {
            name:"more_starting",
            value:"",
            required:"",
            type:"text",
            label:lan.tour_field_more_starting,

        },
        {
            name:"who_drinks_provider",
            value:"",
            required:true,
            type:"selectLocal",
            label:lan.tour_field_provider_drinks,
            options:[
                  {
                    "value": "SI",
                    "label": "SI"
                  },
                  {
                    "value": "NO",
                    "label": "NO"
                  },
                  {
                    "value": "none",
                    "label": "NO INCLUIDA"
                  },

                  {
                    "value": "plan",
                    "label": "SOLO INCLUIDAS EN EL PLAN"
                  },
            ]

        },
        {
            name:"provider_jacket",
            value:"",
            required:true,
            type:"selectLocal",
            label:lan.tour_field_provider_jacket,
            options:[
                  {
                    "value": "SI",
                    "label": "SI"
                  },
                  {
                    "value": "OPCIONAL CON CARGO",
                    "label": "OPCIONAL CON CARGO"
                  },
                  {
                    "value": "NO",
                    "label": "NO"
                  },
            ]

        },
        {
            name:"jacket_is_necesary",
            value:false,
            required:"",
            type:"checkbox",
            label:lan.tour_field_jacket_is_necesary,

        },
        
        {
            name:"food_is_operator_or_provider",
            value:"",
            required:true,
            type:"selectLocal",
            label:lan.tour_field_food_isprovider_or_operator,
            options:[
                  {
                    "value": "operator",
                    "label": "operador"
                  },
                  {
                    "value": "provider",
                    "label": "proveedor"
                  },
                  {
                    "value": "none",
                    "label": "ninguno"
                  }
            ]

        },

        {
            name:"is_offer",
            value:false,
            required:"",
            type:"switch",
            label:lan.tour_field_is_offer,

        },
             
      
       
    ]
    
}



export {
    getAll,
    findAllLike,
    findRangeDate,
    headers,
    btnTable,
    remove,
    save,
    update,
    findAllLikeSub,
    getFlightById,
    customFields,
    findAllForSelect,
    findMultipleFilters,
    createFullPackage,
    getAllPackage,
    headersPackage ,
    removePackage,
    updatePackage,
    findMultipleFiltersPackage,
    customFiledsPackage,
    headersBookings,
    getAllBookings,
    getAllToursAndPackage,
    customFiledsBookings,
    createBookings,
    removeBookings,
    updateBookings
}
