import lan from '../../translate';
import api from '../api/create';

function getAll(limit=10,page=1) {
    return api.get(`/airports?limit=${limit}&skip=${page}`)
}

function getAllFull() {
    return api.get(`/airports/full`)
}

function findAllLike(body,limit=150,page=1) {
    return api.post(`/airports/find?limit=${limit}&skip=${page}`,body)
}

function findAllForSelect(body,limit=150) {
    return api.post(`/airports/name?limit=${limit}`,body)
}

function findRangeDate(body,limit=1000,page=1) {
    return api.post(`/airports/find/rangedate?limit=${limit}&skip=${page}`,body)
}

function remove(id) {
    return api.delete(`/airports/${id}`)
}

function save(body) {
    return api.post(`/airports/`,body)
}

function update(body,id) {
    return api.patch(`/airports/${id}`,body)
}


function headers() {
    const headers = {
        name:lan.airport_field_name,
        iata:lan.airport_field_iata,
        country:lan.airport_field_country,
        city:lan.airport_field_gps_city,
    }

    return headers
}



function btnTable() {
    const btn = {
        create:{name:lan.airport_btn_create, colorClass:"block px-3 py-2 text-sm font-semibold text-center text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" },
        update:{name:lan.airlines_btn_delete, colorClass:"text-blue-primary   hover:text-indigo-900"},
        delete:{name:lan.airlines_btn_update, colorClass:"text-red-800   hover:text-red-900"},
    }
    return btn
}

export {
    getAll,
    findAllLike,
    findRangeDate,
    headers,
    btnTable,
    remove,
    save,
    update,
    getAllFull,
    findAllForSelect
}
