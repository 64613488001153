import React, { useEffect, useState, useContext,useCallback } from 'react'
import * as Helpers from '../../helpers/helpers'
import AlertContext from '../../context/contextAlert'
import { useNavigate , useLocation, useLoaderData } from "react-router-dom"
import ServicesContext from '../../context/contextServices'
import lan from '../../translate'
import { country } from '../../helpers/country'
import ProfileContext from '../../context/contextProfile'
import moment from 'moment'
import Select from '../../components/select/select'
import Modal from '../../components/modal/modal'
import DinamicField from '../../components/customForm/dinamicFields'
import axios from "axios";
import EticketModal from './eticketModal'
import WebcamComponent from '../../components/webcam/webcam'

const selectedValueInfo = {
    value:"",
    label:""
}

let initialState = {
    "airline": "",
    "airlineAndFlight": "",
    "no_flight": "",
    "airportOrigin": "",
    "airportDestination": "",
    "passport_number": "",
    "surname": "",
    "given_names": "",
    "nationality": "",
    "date_of_birth": "",
    "gender": "",
    "country_code": "",
    "place_of_birth": "",
    "date_of_issue": "",
    "date_of_expiry": "",
    "country": "",
    "cityName": ""
}

const bodyRequired = {
    "airline": true,
    "no_flight": true,
    "passport_number": true,
    "surname": true,
    "given_names": true,
    "nationality": true,
    "date_of_birth": true,
    "gender": true,
    "country_code": true,
    "place_of_birth": true,
    "date_of_issue": true,
    "date_of_expiry": true,
    "country": true,
    "cityName": true
}


export default function EticketPhoto() {

    const services =  useContext(ServicesContext)
    const MySwal = useContext(AlertContext)
    const {user}  =  useContext(ProfileContext)
    const location = useLocation();
    const navigate = useNavigate();
    const [body,setBody] = useState(initialState)
    const [customFields,setCustomFields] = useState([])
    const [idUser, setIdUser] = useState(0)
    const [isUpdate, setIsUpdate] = useState(false)
    const [urlEticket, setUrlEticket] = useState(false)
    const [required,setRequired] = useState(bodyRequired)
    const [open,setOpen] = useState(false)
    const [url,setUrl] = useState("")
    const [options, setOptions] = useState(selectedValueInfo)


    const setBodyData = useCallback(event => {
        const { name, value } = event.target;
        setBody({ ...body, [name]: value });
        if(!value){
            setRequired({ ...required, [name]: lan.user_alert_fielderror })
        }else{
            setRequired({ ...required, [name]: "" })
        }
        console.log('body', body)
        body[name] = value
        required[name] = value

    });

    const setBodyDataApi = useCallback(event => {

        setBody({ ...body, ...event });
        console.log('evente', event)
        
        Object.keys(event).map((data,i)=>{
            console.log('event[data]', event[data])
            if(event[data]){
                required[data] = ""
            }else{
                required[data] = lan.user_alert_fielderror
            }
        })
        
        
        
        console.log('required', required)

    });

    const setBodyDataFile = useCallback(async event => {
        MySwal.fire({
            title: lan.loading_save,
            allowOutsideClick: false,
          })
          MySwal.showLoading();

        const file = event.target.files[0]
        // const maxAllowedSize = 2 * 1024 * 1024;
        // if(file.size> maxAllowedSize){
        //     return MySwal.fire({
        //         confirmButtonColor: '#1EA0D5',
        //         icon: "warning",
        //         text:"Archivo muy pesando, eliga uno que pese menos de 2mb"
        //       })
        // }
        const base64 = await convertBase64(file)
        setBody({ ...body, base64: base64.split(",")[1] });

        MySwal.fire({
            confirmButtonColor: '#1EA0D5',
            icon: "success",
            text:"Archivo subido"
          })
          console.log(body);
    });

    function convertBase64(file) {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file)
          fileReader.onload = () => {
            resolve(fileReader.result);
          }
          fileReader.onerror = (error) => {
            reject(error);
          }
        })
    }
    

  

    function loadData(state) {
        if(state){
         setRequired({
             no:'',
             name:'',
         })
         setBody(state)
         setIdUser(state._id)
         setIsUpdate(true)
        }
        
    }

    function loadFields() {
        
        const result = services.File.customEticketFields()
        console.log('result', result)
         result.map((b,i)=>{
            body[b.name] = b.value
            required[b.name] = b.required
        })
        setBody({
            ...body,
            base64:"",
            dateCreate:moment().format('DD/MM/YYYY'),
            hourCreate:moment().hours() + ":"+ moment().minutes(),
        })
        setCustomFields(result)
    }

    useEffect(()=>{
        loadData(location.state)
        loadFields()
        
    },[])

    useEffect(()=>{
        
    },[body,setBody])


    async function save() {
        MySwal.fire({
            title: "Creando Eticket, esto puede durar un minuto..",
            allowOutsideClick: false,
          })
          MySwal.showLoading();
          
        if(Helpers.checkIsRequired(required) || body.base64 === ""){
            setOpen(false)
           return  MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "warning",
                text:lan.user_alert_field_empty
              })
        }

        if(!Helpers.checkIsRequired(required)){
            setOpen(false)
            const bodyResult = await Helpers.bodyEticket(body)
            const result = await services.File.eticketPhoto(bodyResult)
            console.log('result', result)
            if(result.data.ok){
                MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "success",
                    text:lan.alert_create_generic
                  })
                  setUrlEticket(result.data.msg)
                return window.open(result.data.msg,"_blank")
            }else{
                MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "error",
                    text:result.data.msg
                    })
            }
            
                
        }
    }

    async function validateData() {
        //return setOpen(true)
        MySwal.fire({
            title: "Validando datos...",
            allowOutsideClick: false,
          })
          MySwal.showLoading();
        if(body.base64 && body.no_flight && body.dateStart && body.motivate && body.ocupacion && body.civil_state  ){
            const result = await services.File.getInfoPassport({
                no_flight: body.no_flight,
                dateStart:body.dateStart,
                base64:body.base64
            })
            if(result.status === 201 && result.data.ok){
                console.log('first', result.data.msg)
                setBodyDataApi(result.data.msg)
                MySwal.close();
                setOpen(true)
            }else{
                console.log('resu22lt', result)
                MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "error",
                text:result.data?result.data.msg:result.response.data.message
                })
            }
            console.log('result', body)
        }else{
            MySwal.fire({
            confirmButtonColor: '#1EA0D5',
            icon: "warning",
            text:lan.user_alert_field_empty
            })
        }
    }

    

    return (
        <div className="space-y-10 divide-y divide-gray-900/10">
        <div className="grid grid-cols-1 pt-10 gap-x-8 gap-y-8 md:grid-cols-3">
            
          <div className="px-4 sm:px-0">
            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">{lan.eticket_title}</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">{lan.eticket_subtitle}</p>
          </div>
    
          <div className="bg-white shadow-sm dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-slate-800 ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
            <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                {customFields.length>0&&customFields.map((fields,i)=>
                    <DinamicField 
                    type={fields.type}
                    value={body[fields.name]}
                    apiType={fields.apiType}
                    name={fields.name}
                    required={required[fields.name]}
                    label={fields.label}
                    options={fields.options}
                    setBodyData={setBodyData}
                    />
                )}
                <div className="sm:col-span-6">
                    <div className="mt-2">
                        <input
                        type={"file"}
                        name={"base64"}
                        id={"base64"}
                        accept=".jpg,.jpeg,.png"
                        onChange={setBodyDataFile}
                        autoComplete="given-name"
                        className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                        />
                    </div>
                    {/* <div className="sm:col-span-6">
                        <WebcamComponent setUrl={setUrl}/>
                    </div> */}
                </div>
            </div>
            </div>
           
          </div>
        </div>
    
        
    
       
    
        <div className="flex items-center justify-end px-4 py-4 border-t col-span-full gap-x-6 border-gray-900/10 dark:border-white/10 sm:px-8">
              <button type="button" className="text-sm font-semibold leading-6 text-blue-primary" onClick={()=>navigate(-1)}>
                {lan.user_button_cancel}
              </button>
              <button
                type="submit"
                hidden={!urlEticket}
                onClick={()=> window.open(urlEticket,"_blank")}
                className="px-3 py-2 text-sm font-semibold text-white bg-green-700 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Ver Eticket
              </button>

              <button
                type="submit"
                onClick={()=> validateData()}
                className="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-blue-primary hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {lan.user_button_save}
              </button>
            </div>
            <Modal 
            open={open} 
            setOpen={setOpen} 
            action={save} 
            body={
                <EticketModal 
                data={body} 
                required={required}
                setBodyData={setBodyData}
                />
            }/>
      </div>
      )

}