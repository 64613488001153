import lan from '../../translate';
import api from '../api/create';


function getFile(body) {
    return api.post(`/files/getfile`,body)
}

function getFileAllLang() {
  return api.get(`/files/lan/all`)
}
function getFileOneLang(lan) {
  return api.get(`/files/lan/${lan}`)
}

function saveWord(body) {
  return api.post(`/files/lan`,body)
}

function getAll(limit=10,page=1) {
    return api.get(`/files?limit=${limit}&skip=${page}`)
}

function findAllLike(body,limit=1000,page=1) {
    return api.post(`/files/find?limit=${limit}&skip=${page}`,body)
}

function uplodFile(body) {
    return api.post(`/files`,body)
}

function eticketPhoto(body) {
    return api.post(`/files/eticketphoto`,body)
}

function getInfoPassport(body) {
    return api.post(`/files/passportphoto`,body)
}

function getPassengerInfo(body) {
  return api.post(`/files/getpassengerInfo`,body)
}

function downloadFiles(body) {
    return api.post(`/files/downloadzip`,body)
}

function createDoc(body) {
    return api.post(`/doc-template`,body)
}

function uplodMultipleFile(body) {
    return api.post(`/files/multiple`,body)
}

function createQuotesTours(body) {
  return api.post(`/doc-template/quotes/tours`,body)
}

function removeFile(id) {
    return api.delete(`/files/${id}`)
}

function removeFileByRute(body) {
  return api.post(`/files/removefile`,body)
}

function headers() {
    const headers = {
        name:lan.file_header_name,
        fileRef:lan.file_header_public_id,
        category:lan.file_header_category,
    }

    return headers
}





function btnTable() {
    const btn = {
        create:{name:lan.workflight_btn_create, colorClass:"block px-3 py-2 text-sm font-semibold text-center text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" },
        update:{name:lan.airlines_btn_delete, colorClass:"text-blue-primary   hover:text-indigo-900"},
        delete:{name:lan.airlines_btn_update, colorClass:"text-red-800   hover:text-red-900"},
    }
    return btn
}


function customFields() {
    return  [
        {
            name:"description",
            value:"",
            required:true,
            type:"text",
            label:lan.file_header_description
        },
        {
            name:"category",
            value:"",
            required:true,
            type:"select",
            label:lan.file_header_category,
            apiType:"file_category"
        },
      
       
    ]
    
}

function customEticketFields() {
    return  [
        {
            name:"no_flight",
            value:"",
            required:true,
            type:"text",
            label:lan.eticket_field_noflight
        },
        {
            name:"permanentAddress",
            value:"",
            required:true,
            type:"text",
            label:lan.eticket_field_address,
        },
        {
            name:"dateStart",
            value:"",
            required:true,
            type:"date",
            label:lan.eticket_field_date_start,
        },
        {
            name:"civil_state",
            value:"",
            required:true,
            type:"selectLocal",
            label:lan.eticket_field_civil_state,
            options:[
                  {
                    "value": "5",
                    "label": "Soltero(a)"
                  },
                  {
                    "value": "4",
                    "label": "Casado(a)"
                  },
                  {
                    "value": "3",
                    "label": "Concubinato"
                  },
                  {
                    "value": "2",
                    "label": "Union Libre"
                  },
                  {
                    "value": "1",
                    "label": "Otros"
                  }
            ]

        },

        {
            name:"ocupacion",
            value:"",
            required:true,
            type:"selectLocal",
            label:lan.eticket_field_Ocupacion,
            options:[
                {
                    "value": "38",
                    "label": "No Labora"
                  },
                  {
                    "value": "37",
                    "label": "Tripulante"
                  },
                  {
                    "value": "36",
                    "label": "Diplomático"
                  },
                  {
                    "value": "35",
                    "label": "Pensionado"
                  },
                  {
                    "value": "34",
                    "label": "Estudiante"
                  },
                  {
                    "value": "33",
                    "label": "Independiente"
                  },
                  {
                    "value": "32",
                    "label": "Empleado Privado"
                  },
                  {
                    "value": "31",
                    "label": "Empleado Público"
                  },
                  {
                    "value": "5",
                    "label": "Empresario"
                  }
            ]

        },

        {
            name:"motivate",
            value:"",
            required:true,
            type:"selectLocal",
            label:lan.eticket_field_motivate,
            options:[
                {
                    "value": "2",
                    "label": "Visita Amigos y/o Parientes"
                  },
                  {
                    "value": "3",
                    "label": "Estudios"
                  },
                  {
                    "value": "4",
                    "label": "Convencion - Conferencia"
                  },
                  {
                    "value": "5",
                    "label": "Negocios"
                  },
                  {
                    "value": "6",
                    "label": "Recreo"
                  },
                  {
                    "value": "8",
                    "label": "Deporte-Competencia"
                  },
                  {
                    "value": "9",
                    "label": "Concierto"
                  },
                  {
                    "value": "11",
                    "label": "Boda/Luna de Miel"
                  },
                  {
                    "value": "12",
                    "label": "Emergencia"
                  }
            ]

        },

        
      
       
    ]
    
}


export {
    getFile,
    uplodFile,
    uplodMultipleFile,
    removeFile,
    getAll,
    headers,
    btnTable,
    customFields,
    findAllLike,
    createDoc,
    downloadFiles,
    eticketPhoto,
    customEticketFields,
    getInfoPassport,
    getPassengerInfo,
    removeFileByRute,
    createQuotesTours,
    getFileAllLang,
    saveWord,
    getFileOneLang
}

