import React, { useEffect, useState, useContext,useCallback } from 'react'
import {navigation, subNavigations, othersNavigation} from '../../routers'
import * as Helpers from '../../helpers/helpers'
import AlertContext from '../../context/contextAlert'
import { useNavigate , useLocation, useLoaderData } from "react-router-dom"
import ServicesContext from '../../context/contextServices'
import lan from '../../translate'
import moment from 'moment'
import  ProfileContext  from '../../context/contextProfile'

const initialState = {
    fullName:"",
    email:"",
    isActive:false,
    password:"",
    password2:"", 
    pages:[],
    roles:[ ],
    type:"",
    dateCreate:moment().format('DD/MM/YYYY'),
    hourCreate:moment().hours() + ":"+ moment().minutes()

}

const fieldRequired = {
    fullName:true,
    email:true,
    type:true,
    password:true,
    password2:true
}


export default function UserCreate() {
    const services =  useContext(ServicesContext)
    const MySwal = useContext(AlertContext)
    const {user}  =  useContext(ProfileContext)
    const location = useLocation();
    const navigate = useNavigate();
    const [body,setBody] = useState(initialState)
    const [idUser, setIdUser] = useState(0)
    const [isUpdate, setIsUpdate] = useState(false)
    const [required,setRequired] = useState(fieldRequired)


    const setBodyData = useCallback(event => {
        const { name, value } = event.target;
        setBody({ ...body, [name]: value });
        if(!value){
            setRequired({ ...required, [name]: lan.user_alert_fielderror })
        }else{
            setRequired({ ...required, [name]: "" })
        }
    });

    const setEmailValidate = useCallback(event => {
        const { name, value } = event.target;
        setBody({ ...body, [name]: value });
        
        if(!Helpers.validateEmail(value)){
            setRequired({ ...required, [name]: lan.user_alert_emailerror })
        }else{
            setRequired({ ...required, [name]: "" })
        }
    });



    const setCheckActive = useCallback(event => {
        const { name, checked } = event.target;
        setBody({ ...body, [name]: checked });
      
    });

    const setCheckRoles = useCallback(event => {
        const { name, checked } = event.target;
        if(checked){
            body.roles.push(name)
        }else{
            let index = body.roles.indexOf(name);
            if (index !== -1) {
                body.roles.splice(index, 1);
            }
        }
        
       
    });

    const setCheckPages = useCallback(event => {
        const { name, checked } = event.target;
        if(checked){
            body.pages.push({name:name,children:[]})
            
        }else{
            let i = body.pages.findIndex(i => i.name === name);
            if (i !== -1) {
                body.pages.splice(i, 1);
            }
        } 
    });
    function setCheckPagesChild(event,child){
        const { name, checked } = event.target;
        if(checked){
          let selected = body.pages.findIndex(i => i.name === child)
          body.pages[selected].children.push(name)
            
        }else{
          let selected = body.pages.findIndex(i => i.name === child)
            let i = body.pages[selected].children.indexOf(name)
            if (i !== -1) {
              body.pages[selected].children.splice(i, 1);
            }
        } 
    }

    const comparePass = useCallback(event => {
         const { name, value } = event.target;
        setBody({ ...body, [name]: value });
        
        if(body.password !== value){
            setRequired({ ...required, [name]: lan.user_alert_passnotmatch })
        }else{
            setRequired({ ...required, [name]: "" })
        }
    });

   function loadData(state) {
     console.log('state', state)
       if(state){
        setRequired({
            email:'',
            fullName:'',
        })
        setBody(state)
        setIdUser(state._id)
        setIsUpdate(true)
       }
       
   }





 


   function checkedInputsPages() {
     let rutes = Helpers.decryptData(window.localStorage.getItem("token-t")).pages
        navigation.map((data,i)=>{
          let index = body.pages.findIndex(i => i.name === data.href)
            if(document.getElementById(data.href)){
              (document.getElementById(data.href).checked =  index !== -1)
            }
            if(data.children&&data.children.length>0 && index !== -1){
              data.children.map((child,i)=>{
                (document.getElementById(child.href).checked =  (body.pages[index].children.indexOf(child.href) !== -1))

              }
              )
            }
            
        }) 
        subNavigations.map((data,i)=>{
          if(document.getElementById(data.href)){
            (document.getElementById(data.href).checked =  (body.pages.findIndex(i => i.name === data.href) !== -1))
          }
          if(data.children&&data.children.length>0){
            data.children.map((child,i)=>
              (document.getElementById(child.href).checked =  (body.pages.findIndex(i => i.name === data.href) !== -1))
            )
          }
        }) 
        othersNavigation.map((data,i)=>{
          if(document.getElementById(data.href)){
            (document.getElementById(data.href).checked =  (body.pages.findIndex(i => i.name === data.href) !== -1))
          }
          if(data.children&&data.children.length>0){
            data.children.map((child,i)=>
              (document.getElementById(child.href).checked =  (body.pages.findIndex(i => i.name === data.href) !== -1))
            )
          }
        }) 
   }

   function checkedInputRoles() { 
           for (let i = 0; i < body.roles.length; i++) {
               document.getElementById(body.roles[i])&&
               (document.getElementById(body.roles[i]).checked = true)
           }
       
   }


    useEffect(()=>{
        loadData(location.state)
        checkedInputsPages()
        checkedInputRoles()
        
    },[body.pages, body.roles])


    

    


  

    async function updateUser() {
        delete body.create
        delete body.__v
        delete body._id
        delete body.dateCreate
        delete body.hourCreate
        
        if(Helpers.checkIsRequired(required)){

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "warning",
                text:lan.user_alert_field_empty
              })
        }

        if(!Helpers.checkIsRequired(required) ){
          
            
            const roles = await services.Users.updateUserRolesAndPages({roles:body.roles,pages:body.pages},idUser)
            delete body.roles
            delete body.pages
            body.needSing = true;
            const result = await services.Users.updateUser(body,idUser)
            if(result.status ===200){
              //updateUserRolesAndPages
                MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "success",
                    text:lan.user_alert_userupdate
                  })
                return 
            }

            if(result.response && result.response.data.statusCode === 400){
                return  MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "error",
                    title:lan.user_alert_errorupdate,
                    text: "Error: "+result.response.data.message
                    })
            }

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "error",
                text:lan.user_alert_error500
                })
        }
        
        
    }

    async function save() {
        
        delete body.password2
        delete body.dateCreate
        delete body.hourCreate

        if(Helpers.checkIsRequired(required)){

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "warning",
                text:lan.user_alert_field_empty
              })
        }
        
        if(!Helpers.checkIsRequired(required)){
            const bodyRole = {
              roles: body.roles,
              pages:body.pages
            }
            delete body.roles
            delete body.pages
            const result = await services.Users.saveUser(body)
            if(result.status ===201){
              const roles = await services.Users.updateUserRolesAndPages(bodyRole,idUser)
                MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "success",
                    text:lan.user_alert_usercreate
                  })
                return navigate('/users')
            }

            if(result.response && result.response.data.statusCode === 400){
                return  MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "error",
                    title:lan.user_alert_errorcreate,
                    text: "Error: "+result.response.data.message
                    })
            }

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "error",
                text:lan.user_alert_error500
                })
                
        }
        

    }

    async function  changPassword(){
        let bodyPass = {
            id:idUser,
            newpassword: body.password
        }

        if(!bodyPass.newpassword){
           return  MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "warning",
                    text:lan.user_alert_pass_empty
                    })
        }
        const result = await services.Users.updateUserPassword(bodyPass)

        if(result.status ===200){
           return MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "success",
                text:lan.user_alert_passsuccess
              })
        }

        if(result.response && result.response.data.statusCode === 400){
            return  MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "error",
                title:lan.user_alert_passerror,
                text: "Error: "+result.response.data.message
                })
        }

    }


  return (
    <div className="space-y-10 divide-y divide-gray-900/10">
    <div className="grid grid-cols-1 pt-10 gap-x-8 gap-y-8 md:grid-cols-3">
        
      <div className="px-4 sm:px-0">
        <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">{lan.user_personal}</h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">{lan.user_personal_sub}</p>
      </div>

      <div className="bg-white shadow-sm dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-slate-800 ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
        <div className="px-4 py-6 sm:p-8">
          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
               {lan.user_field_fullname} <span className={required.fullName?"text-red-700":""}>*</span>
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name={"fullName"}
                  id={"fullName"}
                  value={body.fullName}
                  onChange={setBodyData}
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                />
              </div>
              <span className='text-xs text-red-700'>{required.fullName?required.fullName:""}</span>
            </div>

           

            <div className="sm:col-span-3">
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
            {lan.user_field_email} <span className={required.email?"text-red-700":""}>*</span>
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={body.email}
                  onChange={setEmailValidate}
                  className="block w-full rounded-md border-0 py-1.5 dark:text-white dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                />
              </div>
              <span className='text-xs text-red-700'>{required.email?required.email:""}</span>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                {lan.user_field_pass} <span className={required.password?"text-red-700":""}>*</span>
              </label>
              <div className="mt-2">
                <input
                  type="password"
                  name="password"
                  id="password"
                  autoComplete='new-password'
                  value={body.password}
                  onChange={setBodyData}
                  className="block w-full rounded-md border-0 py-1.5 dark:text-white dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                />
              </div>
              <span className='text-xs text-red-700'>{required.password?required.password:""}</span>
            </div>
            <div className="sm:col-span-3" >
             

                   

                    {!isUpdate&&
                        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                        {lan.user_field_repass} <span className={required.password2?"text-red-700":""}>*</span>
                        </label>
                    }

                    {isUpdate&&
                        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                        <br/>
                        </label>
                    }
             
              <div className="mt-2" >
                  {isUpdate
                  ?
                    <button
                        onClick={()=> changPassword()}
                        className="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-blue-primary hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        {lan.user_button_changepass}
                    </button>
                  :
                    <input
                    type="password"
                    name="password2"
                    id="password2"
                    value={body.password2}
                    onChange={comparePass}
                    disabled={!body.password}
                    className={ (body.password? "":"bg-gray-200 dark:bg-gray-800 " )+  "block w-full rounded-md border-0 py-1.5 dark:text-white dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"}
                    />
                  }
                
              </div>
              <span className='text-xs text-red-700 '>{required.password2?required.password2:""}</span>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                {lan.user_field_type} <span className={required.type?"text-red-700":""}>*</span>
              </label>
              <div className="mt-2">
                <select
                  name="type"
                  id="type"
                  value={body.type}
                  onChange={setBodyData}
                  className="block w-full rounded-md border-0 py-1.5 dark:text-white dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                >
                   <option className='dark:bg-slate-900' value={""}>Seleccione</option>
                  <option className='dark:bg-slate-900' value={"employee"}>Empleado</option>
                  <option className='dark:bg-slate-900' value={"customer"}>Cliente</option>
                </select>
              </div>
              <span className='text-xs text-red-700'>{required.type?required.type:""}</span>
            </div>

            <div className="col-span-full">
            <div className="relative flex gap-x-3">
                  <div className="flex items-center h-6">
                    <input
                      id="isActive"
                      name="isActive"
                      type="checkbox"
                      checked={body.isActive}
                      onClick={setCheckActive}
                      className="w-4 h-4 border-gray-300 rounded text-blue-primary focus:blue-primary"
                    />
                  </div>
                  <div className="text-sm leading-6">
                    <label htmlFor="comments" className="font-medium text-gray-900 dark:text-white">
                      {lan.user_field_isActive}
                    </label>
                    <p className="text-gray-500">{lan.user_field_isActive_sub}</p>
                  </div>
                </div>
            </div>

           

           

            
          </div>
        </div>
       
      </div>
    </div>
    {user&&user.roles.includes("superadmin")&&(
      <div className="grid grid-cols-1 pt-10 gap-x-8 gap-y-8 md:grid-cols-3">
      <div className="px-4 sm:px-0">
        <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">{lan.user_roles}</h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">
            {lan.user_roles_sub}
        </p>
      </div>

      <form className="bg-white shadow-sm dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-slate-800 ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
        <div className="px-4 py-6 sm:p-8">
          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            


            <div className="col-span-full">
            <div className="relative flex gap-x-3">
                  <div className="flex items-center h-6">
                    <input
                      id="read"
                      name="read"
                      type="checkbox"
                      onClick={setCheckRoles}
                      className="w-4 h-4 border-gray-300 rounded text-blue-primary focus:blue-primary"
                    />
                  </div>
                  <div className="text-sm leading-6">
                    <label htmlFor="comments" className="font-medium text-gray-900 dark:text-white">
                      {lan.user_field_read}
                    </label>
                    <p className="text-gray-500">{lan.user_field_read_sub}</p>
                  </div>
                </div>
            </div>

            <div className="col-span-full">
            <div className="relative flex gap-x-3">
                  <div className="flex items-center h-6">
                    <input
                      id="edit"
                      name="edit"
                      type="checkbox"
                      onClick={setCheckRoles}
                      className="w-4 h-4 border-gray-300 rounded text-blue-primary focus:blue-primary"
                    />
                  </div>
                  <div className="text-sm leading-6">
                    <label htmlFor="comments" className="font-medium text-gray-900 dark:text-white">
                      {lan.user_field_edit}
                    </label>
                    <p className="text-gray-500">{lan.user_field_edit_sub}</p>
                  </div>
                </div>
            </div>

            <div className="col-span-full">
            <div className="relative flex gap-x-3">
                  <div className="flex items-center h-6">
                    <input
                      id="admin"
                      name="admin"
                      type="checkbox"
                      onClick={setCheckRoles}
                      className="w-4 h-4 border-gray-300 rounded text-blue-primary focus:blue-primary"
                    />
                  </div>
                  <div className="text-sm leading-6">
                    <label htmlFor="comments" className="font-medium text-gray-900 dark:text-white">
                      {lan.user_field_admin}
                    </label>
                    <p className="text-gray-500">{lan.user_field_admin_sub}</p>
                  </div>
                </div>
            </div>


          </div>
        </div>
        
      </form>
      
    </div>
    )}
    

    <div className="grid grid-cols-1 pt-10 gap-x-8 gap-y-8 md:grid-cols-3">
      <div className="px-4 sm:px-0">
        <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">{lan.user_pages}</h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">
            {lan.user_pages_sub}
        </p>
      </div>

      <form className="bg-white shadow-sm dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-slate-800 ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
        <div className="px-4 py-6 sm:p-8">
          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            

          <legend className="text-sm font-semibold leading-6 text-blue-primary">{lan.user_pages_titlemain}</legend>
          
            <div className="col-span-full">
           
                {navigation.length>0&& navigation.map((data,i)=>
                    <div>
                      <br/>
                      <div className="relative flex flex-col p-2 shadow-sm sm:rounded-xl md:col-span-2 gap-x-3 dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-slate-800 ring-1 ring-gray-900/5" key={data.name+i}>
                    <div className="flex items-center h-6">
                    <input
                        id={data.href}
                        name={data.href}
                        onClick={setCheckPages}
                        type="checkbox"
                        className="w-4 h-4 border-gray-300 rounded text-blue-primary focus:blue-primary"
                    />
                    </div>
                    
                    <div className="text-sm leading-6">
                    <label htmlFor="comments" className="font-medium text-gray-900 dark:text-white">
                        {data.name}
                    </label>
                    <p className="text-gray-500">{lan.user_pages_rutes+" "} {data.href}</p>
                    </div>
                    <div>
                    <label className='text-xs dark:text-white'>sub-ruta:</label>
                    <hr />
                      {data.children.length>0&&data.children.map((chil,i)=>
                       <div className=''>
                         <br/>
                         <div className="flex items-start h-6">
                          <input
                              id={chil.href}
                              name={chil.href}
                              onClick={(e)=>setCheckPagesChild(e,data.href)}
                              type="checkbox"
                              className="w-4 h-4 border-gray-300 rounded text-blue-primary focus:blue-primary"
                          />
                          </div>
                          
                          <div className="text-sm leading-6">
                          <label htmlFor="comments" className="font-medium text-gray-900 dark:text-white">
                              {chil.name}
                          </label>
                          <p className="text-gray-500">{lan.user_pages_rutes+" "} {chil.href}</p>
                          </div>
                       </div>
                      )}
                    </div>
                    </div>
                    </div>
                )}
                
            </div>

            <legend className="text-sm font-semibold leading-6 text-blue-primary">{lan.user_page_titleadmin}</legend>
            <div className="col-span-full">
                {subNavigations.length>0&& subNavigations.map((data,i)=>
                    <div className="relative flex gap-x-3" key={data.name+i}>
                    <div className="flex items-center h-6">
                    <input
                        id={data.href}
                        name={data.href}
                        onClick={setCheckPages}
                        type="checkbox"
                        className="w-4 h-4 border-gray-300 rounded text-blue-primary focus:blue-primary"
                    />
                    </div>
                    <div className="text-sm leading-6">
                    <label htmlFor="comments" className="font-medium text-gray-900 dark:text-white">
                        {data.name}
                    </label>
                    <p className="text-gray-500">{lan.user_pages_rutes+" "} {data.href}</p>
                    </div>
                </div>
                )}
                <legend className="text-sm font-semibold leading-6 text-blue-primary">{lan.user_page_titleother}</legend>
                  <br/>

                {othersNavigation.length>0&& othersNavigation.map((data,i)=>
                    <div className="relative flex gap-x-3" key={data.name+i}>
                    <div className="flex items-center h-6">
                    <input
                        id={data.href}
                        name={data.href}
                        onClick={setCheckPages}
                        type="checkbox"
                        className="w-4 h-4 border-gray-300 rounded text-blue-primary focus:blue-primary"
                    />
                    </div>
                    <div className="text-sm leading-6">
                    <label htmlFor="comments" className="font-medium text-gray-900 dark:text-white">
                        {data.name}
                    </label>
                    <p className="text-gray-500">{lan.user_pages_rutes+" "} {data.href}</p>
                    </div>
                </div>
                )}
            </div>


          </div>
        </div>
        
      </form>
      
    </div>

    <div className="flex items-center justify-end px-4 py-4 border-t col-span-full gap-x-6 border-gray-900/10 dark:border-white/10 sm:px-8">
          <button type="button" className="text-sm font-semibold leading-6 text-blue-primary" onClick={()=>navigate(-1)}>
            {lan.user_button_cancel}
          </button>
          <button
            type="submit"
            onClick={()=> isUpdate? updateUser() : save()}
            className="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-blue-primary hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {lan.user_button_save}
          </button>
        </div>
  </div>
  )
}
