import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {ServicesProvider} from './context/contextServices'
import { ProfileProvider } from './context/contextProfile';
import { AlertProvider } from './context/contextAlert';
import { BrowserRouter as Router } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ServicesProvider>
      <ProfileProvider>
        <AlertProvider>
        <Router>
          <App />
        </Router>
        </AlertProvider>
      </ProfileProvider>
    </ServicesProvider>
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

