



import {useEffect,useContext} from 'react'
import ProfileContext from '../../context/contextProfile'
import AlertContext from '../../context/contextAlert'
export default function Permission({children}) {
    const {permisionForRutes,user}  =  useContext(ProfileContext)

    useEffect(()=>{
       
    },[permisionForRutes()])


    if(Object.keys(user).length===0){
        
        return <h1>cargando...</h1>
    }
    return children;
}

