import React, { useEffect,useReducer, useState, useContext,useCallback } from 'react'
import * as Helpers from '../../helpers/helpers'
import AlertContext from '../../context/contextAlert'
import { PlusIcon } from '@heroicons/react/24/solid'

import { useNavigate , useLocation, useLoaderData } from "react-router-dom"
import FileDocument from '../../components/files/fileDocument'

import ServicesContext from '../../context/contextServices'
import lan from '../../translate'
import { country } from '../../helpers/country'
import ProfileContext from '../../context/contextProfile'
import moment from 'moment'
import Select from '../../components/select/select'
import DinamicField from '../../components/customForm/dinamicFields'
import Combobox from '../../components/combobox/combobox'
import PaxBody from '../../components/paxBody/paxBody'

const selectedValueInfo = {
    value:"",
    label:""
}
const bodyPsgField = {
    document:"",
    name:"",
    lastname:"",
    document_exp:"",
    year_old:0,
    birthday:"",
    nacionality:"",
    sex:"",
    position:0,
    pnr:""
}

const initialState = {
    passengers:[
        bodyPsgField
    ],
    total_tax:0,
    dateCreate:moment().format('DD/MM/YYYY'),
    hourCreate:moment().hours() + ":"+ moment().minutes(),
    dateUpdate:moment().format('DD/MM/YYYY'),
    hourUpdate:moment().hours() + ":"+ moment().minutes()

}



export default function ToursBookingsCreate() {

    const services =  useContext(ServicesContext)
    const MySwal = useContext(AlertContext)
    const {user}  =  useContext(ProfileContext)
    const location = useLocation();
    const navigate = useNavigate();
    const [body,setBody] = useState(initialState)
    const [urlQuotes,setUrlQuotes] = useState(false)
    
    const [customFields,setCustomFields] = useState([])
    const [idUser, setIdUser] = useState(0)
    const [isUpdate, setIsUpdate] = useState(false)
    const [required,setRequired] = useState([])
    const [options, setOptions] = useState(selectedValueInfo)
    let [psg, dispatch] = useReducer((psg, { type, value, position }) => {
        let passengers = body.passengers
        switch (type) {
          case "add":
            passengers[position] = value
            return [...psg, value];
          case "update":
              
              let newPassenger = Object.assign({},body.passengers[position],{
                ["passengers"]:value
              })
              passengers[position] = newPassenger
            return [...passengers];
          case "remove":
            return psg.filter((_, index) => index !== value);
          case "load":
              console.log('load :>> ',value);
            body.passengers = value
            return value
          default:
            return psg;
        }
      }, []);
    let [tourList, dispatchTour] = useReducer((tourList, { type, value, position }) => {
        
        switch (type) {
          case "add":
            return [...tourList, value];
          case "update":
              if(!value)return [...tourList];
              let price = body.price_total?parseFloat(body.price_total):0
              let total = parseFloat(value?.price_sell)+price
              let tax = total*0.18
              let total_full = total + tax
            setBody({
                ...body, 
                price_total:total,
                tax:tax,
                total_tax:total_full
            })
            tourList[position] =  value
            return [...tourList];
          case "remove":
            let price1 = body.price_total
            let total1 = price1-parseFloat(tourList.filter((_, index) => index === value)[0].price_sell)
            let tax1 = total1*0.18
            let total_full1 = total1 + tax1
            setBody({
                ...body, 
                price_total:total1,
                tax:tax1,
                total_tax:total_full1
            })
            return tourList.filter((_, index) => index !== value);
          case "load":
            return value
          default:
            return tourList;
        }
      }, []);
    
    let [fileList, dispatchFile] = useReducer((fileList, { type, value, position }) => {
    switch (type) {
        case "add":
        return [...fileList, value];
        case "show":
        return value
        case "update":
            fileList[position] =  value
        return [...fileList];
        case "remove":
        return fileList.filter((data,i)=> data.name !== value)
        case "load":
        return value
        default:
        return fileList;
    }
    }, []);


    const setBodyData = useCallback(event => {
        let { name, value,type,checked } = event.target;

        if(type==="checkbox"){
            setBody({ ...body, [name]: checked });
            if(!checked){
                setRequired({ ...required, [name]: lan.user_alert_fielderror })
            }else{
                setRequired({ ...required, [name]: "" })
            }
            
            body[name] = checked
            required[name] = checked
        }else{
            value = type ==="number"? parseFloat(value):value
            setBody({ ...body, [name]: value });
            if(!value){
                setRequired({ ...required, [name]: lan.user_alert_fielderror })
            }else{
                setRequired({ ...required, [name]: "" })
            }
            body[name] = value
            required[name] = value
        }
        
        
        

    });

    const setBodyDataSelect = useCallback((value,i) => {
        console.log('value,i', value,i)
        dispatchTour({ type: "update", value: value, position:i})
    });

    const setCheckActive = useCallback(event => {
        const { name, checked } = event.target;
        setBody({ ...body, [name]: checked });
      
    });


    

  

    function loadData(state,isShow) {
        console.log('state', state)

        if(state){
         setRequired({
             name:'',
         })
         
//
         dispatch({ type: "load", value: state.data.passengers, position:0})
         dispatchTour({ type: "load", value: state.data.tours, position:0})
         dispatchFile({ type: "show", value: state.data.files, position:0})
         console.log('state.data.passengers :>> ', state.data.passengers);
         setBody({...state.data,date_booking:moment(state.data.date_booking,"DD/MM/YYYY").format('YYYY-MM-DD')})
         setIdUser(state.data._id)
         
         setIsUpdate(true)
         console.log('object :>> ', body);
        }
        
    }

    function loadFields() {
        
        const result =  services.Tour.customFiledsBookings()
        console.log('result', result)
         result.map((b,i)=>{
            body[b.name] = b.value
            required[b.name] = b.required
        })
        setBody({
            ...body,
            dateCreate:moment().format('DD/MM/YYYY'),
            hourCreate:moment().hours() + ":"+ moment().minutes(),
            user_id:user._id
        })
        setRequired(required)
        setCustomFields(result)
    }
    useEffect(()=>{
        
        console.log('body111', body)
        if(!body.date_booking){
            loadFields()
        }
        loadData(location.state)
        
    },[])


    async function save() {
        MySwal.fire({
            title: lan.loading_save,
            allowOutsideClick: false,
          })
          MySwal.showLoading();
        if(Helpers.checkIsRequired(required) ||  tourList.length ===0){

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "warning",
                text:lan.user_alert_field_empty
              })
        }

       console.log('body,required', body,required)

        if(!Helpers.checkIsRequired(required) && tourList.length>0 && psg.length>0){
            if(fileList.length>0){
                console.log('fileList save', fileList)
                const resultFile = await services.File.uplodMultipleFile({files:fileList})
                if(resultFile.status !== 200 && resultFile.status !==201){
                  return  MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "error",
                    title:lan.alert_errorcreate_generic,
                    text: "Error: FileServer"
                    }) 
                }
                body.files = resultFile.data
            }
            body.tours = tourList;
            body.date_booking = moment(body.date_booking,'YYYY-MM-DD').format('DD/MM/YYYY')
            console.log('body', body)
            const result = await services.Tour.createBookings(body)
            console.log('result', result.status)
            if(result.status ===201){
                
                 MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "success",
                    text:lan.alert_create_generic
                  })
                  return navigate("/worktours/bookings")
                  
            }

            if(result.response && result.response.data.statusCode === 400){
                return  MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "error",
                    title:lan.alert_errorcreate_generic,
                    text: "Error: "+result.response.data.message
                    })
            }

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "error",
                text:lan.user_alert_error500
                })
                
        }
    }

    async function update() {
        MySwal.fire({
            title: lan.loading_save,
            allowOutsideClick: false,
          })
          MySwal.showLoading();
        delete body.create
        delete body.__v
        delete body._id
        delete body.dateCreate
        delete body.hourCreate
        delete body.userInfo
        delete body.dateStart
        delete body.tourNames
        delete body.body
        if(Helpers.checkIsRequired(required) ||  tourList.length ===0){

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "warning",
                text:lan.user_alert_field_empty
            })
        }
        if(!Helpers.checkIsRequired(required) && tourList.length>0 ){
            const diffFile =  fileList.filter(
                fileBody => !body.files.some(newFile => fileBody.name === newFile.name)
                )
                
                if(diffFile.length>0){
                  const resultFile = await services.File.uplodMultipleFile({files:diffFile})
                  if(resultFile.status !== 200 && resultFile.status !==201){
                    return  MySwal.fire({
                      confirmButtonColor: '#1EA0D5',
                      icon: "error",
                      title:lan.alert_errorcreate_generic,
                      text: "Error: FileServer"
                      }) 
                  }
                  for (const key in resultFile.data) {
                    body.files.push(resultFile.data[key])
                  }
                 
                }else{
                    body.files = fileList
                }
            body.tours = tourList
            body.country = body.country?._id? body.country._id:body.country
           // body.images = fileList
            console.log('body.images', body.files)
            
            body.date_booking = moment(body.date_booking,"YYYY-MM-DD").format("DD/MM/YYYY")
            const result = await services.Tour.updateBookings(body,idUser)
            if(result.status ===200){
                MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "success",
                    text:lan.alert_update_generic
                  })
                  navigate("/worktours/bookings")
                return 
            }

            if(result.response && result.response.data.statusCode === 400){
                return  MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "error",
                    title:lan.alert_errorupdate_generic,
                    text: "Error: "+result.response.data.message
                    })
            }

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "error",
                text:lan.user_alert_error500
                })
        }
        
        
    }

    useEffect(()=>{
    },[tourList])

    async function generateQuotes() {
        delete body._id
        delete body.__v
        MySwal.fire({
            title: lan.loading_save,
            allowOutsideClick: false,
        })
        MySwal.showLoading();
        const quotes = await services.File.createQuotesTours(body)
        if(quotes.data.ok){
            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "success",
                text:lan.alert_update_generic
              })
              setUrlQuotes(quotes.data.msg.secure_url)
              window.open(quotes.data.msg.secure_url,"_blank")
            return 
        }

        MySwal.fire({
            confirmButtonColor: '#1EA0D5',
            icon: "error",
            text:lan.user_alert_error500
            })


    }

  
    
console.log('body final', body)
    return (
        <div className="space-y-10 divide-y divide-gray-900/10">
        <div className="grid grid-cols-1 pt-10 gap-x-8 gap-y-8 md:grid-cols-3">
            
          <div className="px-4 sm:px-0">
            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">{lan.tour_title} {body.quotes_id?" - "+body.quotes_id:""}</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">{lan.tour_titlesub}</p>
          </div>
    
          <div className="bg-white shadow-sm dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-slate-800 ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
            {console.log('body.passengers :>> ', body.passengers)}
          {
               
               body.passengers&& body.passengers.length>0&&body.passengers.map((data,i)=>
                <div>
                    <PaxBody 
                    services={services}
                    body={body}
                    i={i}
                    dispatch={dispatch}
                    location={location}
                    setBody={setBody}
                    setBodyData={setBodyData}
                    />
                    
                </div>
                
                   

               )
           }
           
          </div>
        </div>


        <div className="grid grid-cols-1 pt-10 gap-x-8 gap-y-8 md:grid-cols-3">
            
          <div className="px-4 sm:px-0">
            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">{lan.tour_names_include}</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">{lan.generic_list_subtitle}</p>
          </div>
    
          <div className="bg-white shadow-sm dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-slate-800 ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
            <div className="px-4 py-6 sm:p-8">
                
            {tourList.length>0&& tourList.map((data,i) =>
                    
                     <div className="grid max-w-3xl grid-cols-1 p-1 gap-x-8 gap-y-8 sm:grid-cols-1">
                    
                    <Combobox 
                    nameDefault={i}
                    labels={["name","price_sell","type_tour"]}
                    services={services.Tour.getAllToursAndPackage} 
                    value={data?.name}
                    allDataMethod={setBodyDataSelect}/>
                     
                    <div className='flex justify-between w-full col-12'>
                    <div className="p-1">
                        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                            {lan.tour_field_price_sell} 
                        </label>
                        <input
                        type="text"
                        name={i+".price_sell"}
                        id={i+".price_sell"}
                        value={data?.price_sell}
                        autoComplete="given-name"
                        disabled
                        className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                        />
                        
                    </div>
                    <div className="p-1">
                        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                            {lan.tour_field_type_tour} 
                        </label>
                        <input
                        type="text"
                        name={i+".type_tour"}
                        id={i+".type_tour"}
                        value={data?.type_tour}
                        autoComplete="given-name"
                        disabled
                        className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                        />
                        
                    </div>
                    
                    </div>
                    <div className="p-1">
                        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                            {lan.tour_field_description} 
                        </label>
                        <input
                        type="text"
                        name={i+".description"}
                        id={i+".description"}
                        value={data?.description}
                        autoComplete="given-name"
                        disabled
                        className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                        />
                        
                    </div>
                    
                    <div>
                        <button type="button" onClick={()=>dispatchTour({ type: "remove", value: i, position:i})} className="px-3 py-2 text-sm font-semibold text-white bg-red-700 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" >
                            {lan.generic_list_btn_delete}
                        </button>
                    </div>
                
                
                </div>  
            )}
            <br/>
            <button type="button" onClick={()=>dispatchTour({ type: "add", value: "", position:0})} className="px-3 py-2 text-sm font-semibold text-white bg-green-700 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" >
                {lan.generic_list_btn_add}
            </button>
            </div>

        
           
          </div>
        </div>



        <div  className="grid grid-cols-1 pt-10 gap-x-8 gap-y-8 md:grid-cols-3">
        
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">{lan.tour_booking_information}</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">{lan.tour_booking_subinformation}</p>
        </div>
  
        <div className="bg-white shadow-sm dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-slate-800 ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            {customFields.length>0&&customFields.map((fields,i)=>
                    <DinamicField 
                    type={fields.type}
                    value={body[fields.name]}
                    name={fields.name}
                    options={fields.options}
                    required={required[fields.name]}
                    label={fields.label}
                    setBodyData={setBodyData}
                    />
                )}
              
            </div>
          </div>
         
        </div>
        </div>
        
        <div className='flex justify-end text-2xl text-gray-900 dark:text-white'>
            TOTAL DE LA RESERVA: <label className='font-bold text-green-700'>{Helpers.formatToCurrency(body.total_tax,{typeField:"currency"})  }</label>
        </div>
        <div  className="grid grid-cols-1 pt-10 gap-x-8 gap-y-8 md:grid-cols-3">
        
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">{lan.workflight_title_file}</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">{lan.workflight_subtitle_file}</p>
        </div>
  
        <div className="bg-white shadow-sm dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-slate-800 ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                
            {fileList.length>0&&fileList.map((data,i)=>
              <div className="flex flex-wrap sm:col-span-3">
                <FileDocument ruteFile={"trolbis/tours/bookings/"+body.name} category={"bookings"}  position={i} fileList={fileList} setMethod={dispatchFile}/>
              </div>
            )}
             <div className="sm:col-span-3">
              <div onClick={()=>dispatchFile({ type: "add", value: "", position:0})} className='flex flex-wrap w-full gap-12 cursor-pointer'>
                <div className="flex justify-center px-6 py-8 mt-2 border border-indigo-600 border-dashed rounded-lg">
                  <div className="text-center">
                    <PlusIcon  className="h-8 mx-auto text-gray-300 w-15" aria-hidden="true"/>
                    <div className="flex justify-center mt-4 text-sm leading-6 text-center text-gray-600">
                     
                    </div>
                    <p className="text-xs leading-5 text-gray-600"> Agregar  Archivos </p>

                  </div>
              </div>
              </div>
                </div>
              
            </div>
          </div>
         
        </div>
        </div>
        
    
        
    
       
    
        <div className="flex items-center justify-end px-4 py-4 border-t col-span-full gap-x-6 border-gray-900/10 dark:border-white/10 sm:px-8">
              <button type="button" className="text-sm font-semibold leading-6 text-blue-primary" onClick={()=>navigate(-1)}>
                {lan.user_button_cancel}
              </button>
              <button
                type="submit"
                onClick={()=> isUpdate? update() : save()}
                className="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-blue-primary hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {lan.user_button_save}
              </button>
             {isUpdate&&(
                  <button
                  type="submit"
                  onClick={()=> generateQuotes()}
                  className="px-3 py-2 text-sm font-semibold text-white bg-green-700 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                  {lan.tour_btn_quotes}
                </button>
             )}
             {urlQuotes&&(
                 <button
                 type="submit"
                 onClick={()=> window.open(urlQuotes,"_blank")}
                 className="px-3 py-2 text-sm font-semibold text-white bg-yellow-700 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                 >
                 Ver
               </button>
             )}
            </div>
      </div>
      )

}