

import React, { useEffect,useContext, useState } from 'react'
import ServicesContext from '../../context/contextServices'
export default function SelectApi({name,value,apiType,onChange,id}) {
    const services =  useContext(ServicesContext)
    const [ options, setOptions] = useState([])

    async function getInfoApi() {
        let result = []
        console.log('apiType', apiType,value)
        switch (apiType) {
            case 'supplier':
                result = await services.Supplier.getAll(1000)
                if(result.data){
                    setOptions(result.data.results.map((data,i)=>data.legal_name))
                }
                break;
        
            default:
                result = await services.GenericList.getListByType(apiType)
                if(result.data.ok){
                    setOptions(result.data.data.values)
                }
                break; 
        }
        
    }
    useEffect(()=>{
        getInfoApi()
    },[value])
    
  return (
    <div className='flex flex-wrap w-full gap-12'>
           
           <select name={name} id={id} value={value}  onChange={onChange} className='block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6'>
               <option className='dark:bg-slate-900'>Selecione</option>
               {options.length>0&& options.map((data,)=>
                <option className='dark:bg-slate-900' key={data} value={data}>{data}</option>
               )}
           </select>
           
    </div>
  )
}
