import { Dialog, DialogBody, DialogFooter } from '@material-tailwind/react'
import lan from '../../translate'
export default function Modal({open=false,setOpen,body,action}) {

 

  return (
      <div className=''>
            <Dialog open={open} handler={setOpen} className='p-10 overflow-x-hidden overflow-y-auto pt-60 overlay'   >
                <div className='p-1 bg-white rounded-lg dark:text-white dark:bg-slate-800'>
                    {/* <DialogHeader>Nuevo Modal</DialogHeader> */}
                    <DialogBody>{body}</DialogBody>
                    <DialogFooter>
                    <div className='flex gap-5'>
                            <button
                                type="button"
                                className="inline-flex justify-center w-full px-3 py-2 mt-3 text-sm font-semibold text-gray-900 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                onClick={() => setOpen(false)}
                                data-autofocus
                            >
                                {lan.modal_cancel}
                            </button>
                            {action&&(
                                <button
                                type="button"
                                className="inline-flex justify-center w-full px-3 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                                onClick={() => action()}
                                >
                                    {lan.modal_done}
                                </button>
                            )}
                            
                    </div>
                    </DialogFooter>
                </div>
         </Dialog>
        </div>
  
   
  )
}
