import lan from '../../translate';
import api from '../api/create';


function getAll(limit=10,page=1) {
    return api.get(`/supplier?limit=${limit}&skip=${page}`)
}

function getAllFull() {
    return api.get(`/supplier/full`)
}

function findAllLike(body,limit=1000,page=1) {
    return api.post(`/supplier/find?limit=${limit}&skip=${page}`,body)
}

function findAllLikeSub(body,limit=1000,page=1) {
    return api.post(`/supplier/find/findsub?limit=${limit}&skip=${page}`,body)
}

function findMultipleFilters(body,limit=1000,page=1) {
    return api.post(`/supplier/find/multiple?limit=${limit}&skip=${page}`,body)
}

function findAllForSelect(body,limit=150) {
    return api.post(`/supplier/find?limit=${limit}`,body)
}

function findRangeDate(body,limit=1000,page=1) {
    return api.post(`/supplier/find/rangedate?limit=${limit}&skip=${page}`,body)
}

function remove(id) {
    return api.delete(`/supplier/${id}`)
}

function save(body) {
    return api.post(`/supplier/`,body)
}

function update(body,id) {
    return api.patch(`/supplier/${id}`,body)
}

function customFilter() {
    
}


function headers() {
    const headers = {
        legal_name:lan.supplier_header_legal_name,
        type_document:lan.supplier_header_type_document,
        document:lan.supplier_header_document,
        email:lan.supplier_header_email,
        phone:lan.supplier_header_phone,
    }

    return headers
}





function btnTable() {
    const btn = {
        create:{name:lan.workflight_btn_create, colorClass:"block px-3 py-2 text-sm font-semibold text-center text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" },
        update:{name:lan.airlines_btn_delete, colorClass:"text-blue-primary   hover:text-indigo-900"},
        delete:{name:lan.airlines_btn_update, colorClass:"text-red-800   hover:text-red-900"},
    }
    return btn
}


function customFields() {
    return  [
        {
            name:"legal_name",
            value:"",
            required:true,
            type:"text",
            label:lan.supplier_header_legal_name
        },
        {
            name:"type_document",
            value:"",
            required:true,
            type:"select",
            label:lan.supplier_header_type_document,
            apiType:"document_person"
        },
        {
            name:"document",
            value:"",
            required:true,
            type:"text",
            label:lan.supplier_header_document
        },
        {
            name:"email",
            value:"",
            required:"",
            type:"text",
            label:lan.supplier_header_email
        },
        {
            name:"phone",
            value:"",
            required:"",
            type:"text",
            label:lan.supplier_header_phone
        },
        {
            name:"address",
            value:"",
            required:"",
            type:"text",
            label:lan.supplier_header_address
        },
    ]
    
}


export {
    getAll,
    findAllLike,
    findRangeDate,
    headers,
    btnTable,
    remove,
    save,
    update,
    getAllFull,
    findAllForSelect,
    findAllLikeSub,
    findMultipleFilters,
    customFilter,
    customFields
}


