import React, { useEffect, useState, useContext,useCallback, cloneElement } from 'react'
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import {navigation, subNavigations, othersNavigation} from '../../routers'
import * as Helpers from '../../helpers/helpers'
import AlertContext from '../../context/contextAlert'
import { useNavigate , useLocation, useLoaderData } from "react-router-dom"
import ServicesContext from '../../context/contextServices'
import lan from '../../translate'
import { country } from '../../helpers/country'
import ProfileContext from '../../context/contextProfile'
import moment from 'moment'
import Select from '../../components/select/select'
import SelectApi from '../../components/select/selectApi'


const initialState = {
    document:"",
    name:"",
    lastname:"",
    type_document:"",
    year_old:0, 
    document_exp:"",
    birthday:"",
    nacionality:"",
    sex:"",
    user_id:"",
    dateCreate:moment().format('DD/MM/YYYY'),
    hourCreate:moment().hours() + ":"+ moment().minutes()

}

const fieldRequired = {
    document:true,
    name:true,
    lastname:true,
    type_document:true,
    year_old:true, 
    birthday:true,
    nacionality:true,
    sex:true,
}
const selectedValueInfo = {
  value:"",
  label:""
}

export default function PassengerCreate() {
    const services =  useContext(ServicesContext)
    const MySwal = useContext(AlertContext)
    const {user}  =  useContext(ProfileContext)
    const location = useLocation();
    const navigate = useNavigate();
    const [body,setBody] = useState(initialState)
    const [idUser, setIdUser] = useState(0)
    const [isUpdate, setIsUpdate] = useState(false)
    const [required,setRequired] = useState(fieldRequired)
    const [options, setOptions] = useState(selectedValueInfo)
    

    const setBodyData = useCallback(event => {
        const { name, value } = event.target;
        console.log('value,name', value,name)
        setBody({ ...body, [name]: value });
        if(!value){
            setRequired({ ...required, [name]: lan.user_alert_fielderror })
        }else{
            setRequired({ ...required, [name]: "" })
        }
        if(name === 'birthday'){
            let years = Helpers.getYearsOld(value)
            setBody({ ...body, year_old: years,[name]: value  });
            setRequired({ ...required, year_old: "" , [name]: "" })
        }
    });

    const setBodyDataOnlyLetter = useCallback(event => {
        const { name, value } = event.target;
         if(!Helpers.allLetter(value) ) return 
        setBody({ ...body, [name]: value });
        if(!value){
            setRequired({ ...required, [name]: lan.user_alert_fielderror })
        }else{
            setRequired({ ...required, [name]: "" })
        }

        if(name === 'birthday'){
            let years = Helpers.getYearsOld(value)
            setBody({ ...body, year_old: years,[name]: value  });
            setRequired({ ...required, year_old: "" , [name]: "" })
        }
    });

    const setBodyDataSelect = useCallback(event => {
        const { name, value } = event.target;
        setBody({ ...body, [name]: value.label });
        if(!value){
            setRequired({ ...required, [name]: lan.user_alert_fielderror })
        }else{
            setRequired({ ...required, [name]: "" })
        }
    });

    const setCheckActive = useCallback(event => {
        const { name, checked } = event.target;
        if(checked){
            setBody({ ...body, document_exp: "" });
        }else{
            setRequired({ ...required, document_exp: !checked })
        }
        
      
    });

    const setBodyDataFile = useCallback(async event => {
      MySwal.fire({
          title: lan.loading_save,
          allowOutsideClick: false,
        })
        MySwal.showLoading();

      const file = event.target.files[0]
   
      const base64 = await convertBase64(file)
      const result = await services.File.getPassengerInfo({base64:base64.split(",")[1]})
      console.log('moment', moment(result.data.msg.date_of_birth,"DD/MM/YYYY").format("YYYY-MM-DD"))
      if(result.data.ok){
        const passengerInfo = {
          document:result.data.msg.passport_number,
          name:result.data.msg.given_names,
          lastname:result.data.msg.surname,
          document_exp:moment(result.data.msg.date_of_expiry,"DD/MM/YYYY").format("YYYY-MM-DD"),
          year_old: Helpers.getYearsOld(moment(result.data.msg.date_of_birth,"DD/MM/YYYY").format("YYYY-MM-DD")),
          birthday:moment(result.data.msg.date_of_birth,"DD/MM/YYYY").format("YYYY-MM-DD"),
          sex:result.data.msg.gender,
        }
        setBody({...body,...passengerInfo});
        //
        for (const key in Object.keys(passengerInfo)) {
          let positon = Object.keys(passengerInfo)[key]
          
          if(passengerInfo[positon]){
            passengerInfo[positon] = ""
          }else{
            passengerInfo[positon] = true
          }
          
        }
        setRequired({ ...required, ...passengerInfo })

        MySwal.fire({
            confirmButtonColor: '#1EA0D5',
            icon: "success",
            text:"Datos extraidos"
        })
      }
      
        console.log(body);
    });

  function convertBase64(file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
          resolve(fileReader.result);
        }
        fileReader.onerror = (error) => {
          reject(error);
        }
      })
  }

    async function getAllValue() {
      const dataServices = await services.Countries.getAllFull()
      console.log('dataServices', dataServices)
      const list = dataServices.data
      let allItem = list.map((data,i)=>{
          return {value:data._id,label:data.name}
      })
      setOptions(allItem)
  }

    useEffect(()=>{
        loadData(location)
        getAllValue()
    },[])


    async function save() {
        if(Helpers.checkIsRequired(required)){

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "warning",
                text:lan.user_alert_field_empty
              })
        }

        if(!Helpers.checkIsRequired(required)){
            body.user_id = user._id
            body.birthday = moment(body.birthday,'YYYY-MM-DD').format('DD/MM/YYYY')
            if(body.document_exp){
                body.document_exp = moment(body.document_exp,'YYYY-MM-DD').format('DD/MM/YYYY')
            }
            const result = await services.Passengers.savePassenger(body)
            if(result.status ===201){
                MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "success",
                    text:lan.passengers_alert_psgcreaate
                  })
                return navigate(-1)
            }

            if(result.response && result.response.data.statusCode === 400){
                return  MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "error",
                    title:lan.user_alert_errorcreate,
                    text: "Error: "+result.response.data.message
                    })
            }

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "error",
                text:lan.user_alert_error500
                })
                
        }
    }


    async function update() {
        delete body.create
        delete body.__v
        delete body._id
        delete body.dateCreate
        delete body.hourCreate
        delete body.userInfo
        
        if(Helpers.checkIsRequired(required)){

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "warning",
                text:lan.user_alert_field_empty
              })
        }

        if(!Helpers.checkIsRequired(required) ){
            const result = await services.Passengers.updatePassenger(body,idUser)
            if(result.status ===200){
                MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "success",
                    text:lan.passengers_alert_psgupdate
                  })
                  navigate(-1)
                return 
            }

            if(result.response && result.response.data.statusCode === 400){
                return  MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "error",
                    title:lan.passengers_alert_psgerrorupdte,
                    text: "Error: "+result.response.data.message
                    })
            }

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "error",
                text:lan.user_alert_error500
                })
        }
        
        
    }

    function loadData() {
      let state = location.state
        if(state){
         setRequired({
             email:'',
             fullName:'',
         })
         let birthday  = moment(state.birthday,'DD/MM/YYYY').format('YYYY-MM-DD')
         let date_exp  = moment(state.document_exp,'DD/MM/YYYY').format('YYYY-MM-DD')
         setBody({...state, birthday:birthday, document_exp:date_exp})
         setIdUser(state._id)
         setIsUpdate(true)
        }
        
    }

   
     
  return (
    <div className="space-y-10 divide-y divide-gray-900/10">
    <div className="grid grid-cols-1 pt-10 gap-x-8 gap-y-8 md:grid-cols-3">
        
      <div className="px-4 sm:px-0">
        <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">{lan.passengers_title}</h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">{lan.passengers_subtitle}</p>
      </div>

      <div className="bg-white shadow-sm dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-slate-800 ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
        <div className="px-4 py-6 sm:p-8">
          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            
          <div className="sm:col-span-6">
              <div className="mt-2">
                  <input
                  type={"file"}
                  name={"base64"}
                  id={"base64"}
                  accept=".jpg,.jpeg,.png"
                  onChange={setBodyDataFile}
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                  />
              </div>
          </div>
            
            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
               {lan.passengers_field_name} <span className={required.name?"text-red-700":""}>*</span>
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name={"name"}
                  id={"name"}
                  value={body.name}
                  onChange={setBodyData}
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                />
              </div>
              <span className='text-xs text-red-700'>{required.name?required.name:""}</span>
            </div>

           

            <div className="sm:col-span-3">
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
            {lan.passengers_field_lastname} <span className={required.lastname?"text-red-700":""}>*</span>
              </label>
              <div className="mt-2">
                <input
                  id="lastname"
                  name="lastname"
                  type="text"
                  value={body.lastname}
                  onChange={setBodyData}
                  className="block w-full rounded-md border-0 py-1.5 dark:text-white dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                />
              </div>
              <span className='text-xs text-red-700'>{required.email?required.email:""}</span>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                {lan.passengers_field_bithday} <span className={required.birthday?"text-red-700":""}>*</span>
              </label>
              <div className="mt-2">
                <input
                  type="date"
                  name="birthday"
                  id="birthday"
                  value={ body.birthday}
                  onChange={setBodyData}
                  className="block w-full rounded-md border-0 py-1.5 dark:text-white dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                />
              </div>
              <span className='text-xs text-red-700'>{required.birthday?required.birthday:""}</span>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                {lan.passengers_field_yearsold} <span className={required.year_old?"text-red-700":""}>*</span>
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  name="year_old"
                  id="year_old"
                  value={body.year_old}
                  onChange={setBodyData}
                  className="block w-full rounded-md border-0 py-1.5 dark:text-white dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                />
              </div>
              <span className='text-xs text-red-700'>{required.year_old?required.year_old:""}</span>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                {lan.headers_passengers_type_document} <span className={required.type_document?"text-red-700":""}>*</span>
              </label>
              <div className="mt-2">
                
                  <SelectApi
                    apiType={"document_person"}
                    name={"type_document"}
                    id={"type_document"}
                    value={body.type_document}
                    onChange={setBodyData}
                    autoComplete="given-name"
                  />
              </div>
              <span className='text-xs text-red-700'>{required.type_document?required.type_document:""}</span>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                {lan.headers_passengers_document} <span className={required.document?"text-red-700":""}>*</span>
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="document"
                  id="document"
                  value={body.document}
                  onChange={setBodyData}
                  className="block w-full rounded-md border-0 py-1.5 dark:text-white dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                />
              </div>
              <span className='text-xs text-red-700'>{required.document?required.document:""}</span>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                {lan.headers_passengers_document_exp} <span className={required.document_exp?"text-red-700":""}>*</span>
              </label>
              <div className="mt-2">
                <input
                  type="date"
                  name="document_exp"
                  id="document_exp"
                  value={body.document_exp}
                  onChange={setBodyData}
                  className="block w-full rounded-md border-0 py-1.5 dark:text-white dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                />
              </div>
              <span className='text-xs text-red-700'>{required.document_exp?required.document_exp:""}</span>
            </div>

            <div className="sm:col-span-3">
            <div className="relative flex gap-x-3">
                  <div className="flex items-center h-6">
                    <input
                      id="noExp"
                      name="noExp"
                      type="checkbox"
                      checked={!body.document_exp}
                      onClick={setCheckActive}
                      className="w-4 h-4 border-gray-300 rounded text-blue-primary focus:blue-primary"
                    />
                  </div>
                  <div className="text-sm leading-6">
                    <label htmlFor="comments" className="font-medium text-gray-900 dark:text-white">
                      {lan.passengers_field_no_exp}
                    </label>
                    <p className="text-gray-500">{lan.passengers_field_no_exp_sub}</p>
                  </div>
                </div>
            </div>
           
            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                {lan.passengers_field_nacionality} <span className={required.nacionality?"text-red-700":""}>*</span>
              </label>
              <div className="mt-2">
              <Select 
              options={options} 
              nameDefault={"nacionality"}
              onMethod={setBodyDataSelect} 
              valueDefault={body.nacionality}/>
                  
              </div>
              <span className='text-xs text-red-700'>{required.nacionality?required.nacionality:""}</span>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                {lan.headers_passengers_sex} <span className={required.sex?"text-red-700":""}>*</span>
              </label>
              <div className="mt-2">
                  <select
                   name="sex"
                   id="sex"
                   value={body.sex}
                   onChange={setBodyData}
                   className="block w-full rounded-md border-0 py-1.5 dark:text-white dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                  >
                      <option className='dark:bg-slate-900' value={""}>{lan.passengers_field_select}</option>
                      <option className='dark:bg-slate-900' value={"M"}>{lan.passengers_field_sex_m}</option>
                      <option className='dark:bg-slate-900' value={"F"}>{lan.passengers_field_sex_f}</option>
                      
                  </select>
              </div>
              <span className='text-xs text-red-700'>{required.sex?required.sex:""}</span>
            </div>
            
          </div>
        </div>
       
      </div>
    </div>

    

   

    <div className="flex items-center justify-end px-4 py-4 border-t col-span-full gap-x-6 border-gray-900/10 dark:border-white/10 sm:px-8">
          <button type="button" className="text-sm font-semibold leading-6 text-blue-primary" onClick={()=>navigate(-1)}>
            {lan.user_button_cancel}
          </button>
          <button
            type="submit"
            onClick={()=> isUpdate? update() : save()}
            className="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-blue-primary hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {lan.user_button_save}
          </button>
        </div>
  </div>
  )
}
