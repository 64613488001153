import React, { useEffect,useContext } from 'react'
import moment from 'moment'
import SelectApi from '../select/selectApi'
import ServicesContext from '../../context/contextServices'
import Combobox from '../../components/combobox/combobox'


export default function CustomFilters({firstFilter=true,cusFilters=false,searchMethod=false,searchRangeDate=false}) {
    const services =  useContext(ServicesContext)

  function setValue(e,filters) {
      const value = e.target.value
console.log('value Set :>> ', value);
      switch(filters.type){
            case "date":
            searchMethod(filters.field,moment(value,'YYYY-MM-DD').format('DD/MM/YYYY'))
            break;
            case "number":
            searchMethod(filters.field,value)
            break;
            case "text":
            searchMethod(filters.field,value)
            break;
            case "select":
            searchMethod(filters.field,value)
            break;
            case "time":
            searchMethod(filters.field,value)
            break;
            case "apiSelect":
            searchMethod(filters.field,value)
            break;
      }
      
  }

  useEffect(()=>{
      if(!firstFilter && cusFilters.length>0){
          console.log('custFielters :>> ', cusFilters);
          cusFilters.map((filter,i)=>
          document.getElementById(filter.field).value = ""
          )
      }
    })

    return (
    <div className='flex flex-wrap gap-10'>

        {cusFilters.length>0&&cusFilters.map((filters,i)=>
        <div className=" sm:col-span-3">
            <div>
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                        {filters.label}:
                </label>
            </div>
            {filters.type === "select"&&
                <select
                id={filters.field}
                className="block w-36   border-0 py-1.5 dark:text-white dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                onChange={e =>setValue(e,filters)}
                >
                    <option className='dark:bg-slate-900' value="">seleccione</option>
                    {filters.options.length>0&&filters.options.map((opt,i)=>
                        <option className='dark:bg-slate-900' value={opt.value}>{opt.label}</option>
                    )}
                </select>
            }
            {filters.type === "apiSelect"&&
                <div className='flex'>
                    <SelectApi
                    apiType={filters.api_type}
                    name={filters.field}
                    id={filters.field}
                    value={null}
                    onChange={e =>setValue(e,filters)}
                    />
                </div>
            }
            {filters.type !== "select"&& filters.type !== "apiSelect"&&
                <div className='flex'>
                    <input
                    id={filters.field}
                    type={filters.type}
                    onChange={e =>setValue(e,filters)}
                    className="block w-36   border-0 py-1.5 dark:text-white dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                    />
                </div>
            }

            
            
            
                    
        </div>
        
        
        )}
           
    </div>
  )
}
