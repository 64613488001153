import lan from '../../translate';
import api from '../api/create';


function getAll(limit=10,page=1) {
    return api.get(`/generic-list?limit=${limit}&skip=${page}`)
}

function getListByType(type) {
    return api.get(`/generic-list/type/${type}`)
}


function getAllFull() {
    return api.get(`/generic-list/full`)
}

function findAllLike(body,limit=1000,page=1) {
    return api.post(`/generic-list/find?limit=${limit}&skip=${page}`,body)
}

function findAllLikeSub(body,limit=1000,page=1) {
    return api.post(`/generic-list/find/findsub?limit=${limit}&skip=${page}`,body)
}

function findMultipleFilters(body,limit=1000,page=1) {
    return api.post(`/generic-list/find/multiple?limit=${limit}&skip=${page}`,body)
}

function findAllForSelect(body,limit=150) {
    return api.post(`/generic-list/find?limit=${limit}`,body)
}

function findRangeDate(body,limit=1000,page=1) {
    return api.post(`/generic-list/find/rangedate?limit=${limit}&skip=${page}`,body)
}

function remove(id) {
    return api.delete(`/generic-list/${id}`)
}

function save(body) {
    return api.post(`/generic-list/`,body)
}

function update(body,id) {
    return api.patch(`/generic-list/${id}`,body)
}

function customFilter() {
    
}


function headers() {
    const headers = {
        name:lan.generic_header_name,
        type:lan.generic_header_type,
        description:lan.generic_header_description,
    }

    return headers
}





function btnTable() {
    const btn = {
        create:{name:lan.workflight_btn_create, colorClass:"block px-3 py-2 text-sm font-semibold text-center text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" },
        update:{name:lan.airlines_btn_delete, colorClass:"text-blue-primary   hover:text-indigo-900"},
        delete:{name:lan.airlines_btn_update, colorClass:"text-red-800   hover:text-red-900"},
    }
    return btn
}


function customFields() {
    return  [
        {
            name:"name",
            value:"",
            required:true,
            type:"text",
            label:lan.generic_header_name
        },
        {
            name:"type",
            value:"",
            required:true,
            type:"text",
            label:lan.generic_header_type
        },
        {
            name:"description",
            value:"",
            required:true,
            type:"textarea",
            label:lan.generic_header_description
        },
    ]
    
}


export {
    getAll,
    findAllLike,
    findRangeDate,
    headers,
    btnTable,
    remove,
    save,
    update,
    getAllFull,
    findAllForSelect,
    findAllLikeSub,
    findMultipleFilters,
    customFilter,
    customFields,
    getListByType
}


