
import { BrowserRouter as Router, Routes, Route, Redirect, redirect } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react'
import Login from './views/login/login';
import {navigation, userNavigation, subNavigations, othersNavigation} from './routers'
import Permission from './components/permission/permission'
import Navigations from './components/navigations/navigations';
import ProfileContext from './context/contextProfile'
import { useNavigate } from "react-router-dom"
import * as Helpers from './helpers/helpers'

function App() {
  const {user}  =  useContext(ProfileContext)
  const navigate = useNavigate();
  useEffect(()=>{
    console.log(Helpers.decryptData(window.localStorage.getItem("token-t")));
    if(!Helpers.decryptData(window.localStorage.getItem("token-t")) ){
      navigate("/login")
    }
  },[])
  return (
    <>
    
        {window.location.pathname === "/login" 
        ?
        
          <Routes>
            <Route 
             path="/login"
             exact 
             element={<Login/>} />
          </Routes>
       

       :

       <Navigations>
       
         <Routes>
           <Route path="*" element={<h1>notMatch 404</h1>} />

           {navigation.map((data,i)=> 
           !data.hidden && 
          <Route>
          <Route path={data.href} key={i+data.name} element={<Permission>{data.element}</Permission>}/>
            {data.children.length>0&&data.children.map((child,i)=>
              <Route path={child.href} key={i+child.name} element={<Permission>{child.element}</Permission>}/>
            )}
          </Route>
           )
          
           }

           {userNavigation.map((data,i)=> 
           !data.hidden && <Route path={data.href} key={i+data.name} element={<Permission>{data.element}</Permission>}/>)}

           {subNavigations.map((data,i)=> 
           !data.hidden && <Route path={data.href} key={i+data.name} element={<Permission>{data.element}</Permission>}/>)}

           {othersNavigation.map((data,i)=> 
           !data.hidden && <Route path={data.href} key={i+data.name} element={<Permission>{data.element}</Permission>}/>)}

         </Routes>
       
     </Navigations>
        
      }
    </>
  );
}

export default App;
