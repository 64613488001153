import { useContext, useEffect, useState } from 'react'
import lan from '../../translate/index'
import Tablet from '../../components/tablet/tablet'
import ServicesContext from '../../context/contextServices'
import AlertContext from '../../context/contextAlert'
import { useNavigate } from "react-router-dom"
import ProfileContext from '../../context/contextProfile'
import moment from 'moment'
import Paginations from '../../components/pagination/paginations'
import {removeRepeat} from '../../helpers/helpers'




let dataInitial = {
    results:[],
    total:0,
    pageCurrent:1,
    limit:10,
    pageNext:2,
    pagesTotal:0
  }
  
  export default function Files() {
      const services =  useContext(ServicesContext)
      const {user}  =  useContext(ProfileContext)
      const MySwal = useContext(AlertContext)
      const navigate = useNavigate();
      const [data,setData] = useState(dataInitial)
      const [dataReload,setDataReload] = useState([])
      const [headers,setHeaders] = useState([])
      const [btn,setBtn] = useState([])
      const [firsFilter,setFirsFilter] = useState(false)
      const [allFilters, setAllFilters] = useState([])
      const [limit, setLimit] = useState(10)
  
  
      async function getDataAll() {
          const dataServices = await services.File.getAll(limit)
          const headerServices = await services.File.headers()
          const btnServices = await services.File.btnTable()
          if(dataServices.data){
              setData(dataServices.data)
              setDataReload(dataServices.data)
              setHeaders(headerServices)
              setBtn(btnServices)
          }
         
      }
  
      function redirectNew() {
          navigate("/files/new")
      }
  
      function edit(data) {
            navigate("/files/new",{state:data})
      }


      
      async function findAllLike(key,value) {

        if(key && value){
          let bodyFind = {
             key:key,
             value:value
          }
         const resul = await services.File.findAllLike(bodyFind)
         if(resul.status === 201 && resul.data.results.length>0){
             setData(resul.data)
         }
         if(resul.status === 201 && resul.data.results.length===0){
             setData(false)
         }
      }else{
         setData(dataReload)
      }
        
    }
  
  
      async function findRangeDate(key,body) {
          if(key && body.dateOne && body.dateTwo){
            let bodyFind = {
               key:key,
               dateOne:moment(body.dateOne,'YYYY-MM-DD').format('DD/MM/YYYY') ,
               dateTwo:moment(body.dateTwo,'YYYY-MM-DD').format('DD/MM/YYYY') 
            }
           const resul = await services.File.findRangeDate(bodyFind)
           if(resul.status === 201 && resul.data.results.length>0){
               setData(resul.data)
           }
           if(resul.status === 201 && resul.data.results.length===0){
               setData(false)
           }
        }else{
           setData(dataReload)
        }
      }
  
  
      function remove(id) {
          MySwal.fire({
              title: lan.flight_alert_delete,
              text: lan.user_alert_deleteuser_sub,
              icon: 'warning',
              confirmButtonColor: "#db292f",
              confirmButtonText: "Si",
              showCancelButton: true,
              cancelButtonText: 'No'
           }).then(async function(btn){
              // function when confirm button clicked
              if(btn.isConfirmed){
                  const result = await services.File.removeFile(id)
                  console.log('result', result)
                  if(result.data&&result.data.result ==='ok'){
                      MySwal.fire({
                          confirmButtonColor: '#1EA0D5',
                          icon: "success",
                          text:lan.flight_alert_deletesuccess
                        })
                        getDataAll()
                  }
      
                  if(!result.data|| result.data.result==='not found'){
                      MySwal.fire({
                          confirmButtonColor: '#1EA0D5',
                          icon: "error",
                          text:lan.flight_alert_deleteerror
                        })
                  }
              }
      
              
              
           });     
      }
  
      async function changePages(skip) {
          const dataServices = await services.File.getAll(limit,skip)
          setData(dataServices.data)
      }

      function clearFilter() {
        setFirsFilter(false)
        setData(dataReload)
        setAllFilters([])

      }

      async function setLimitFunc(limitChange){
        setLimit(limitChange)
        const dataServices = await services.File.getAll(limitChange,1)
        setData(dataServices.data)
      }
  
      useEffect(()=>{
          getDataAll()
        },[])
  
      
      
    return (
      <div>
        <Tablet 
          filters={true}
          searchMethod={findAllLike}
          //searchRangeDate={findRangeDate}
          limit={limit}
          setLimit={setLimitFunc}
          search={
            {
              "name":lan.file_header_name,
              "public_id":lan.file_header_public_id,
              "category":lan.file_header_category
            }
          }
          createBtn={redirectNew}
          deleteBtn={remove}
          editBtn={edit}
          data={data.results} 
          headers={headers}
          btn={btn}
          title={lan.file_path}
          subTitle={lan.flight_subtitle}/>
  
        <Paginations data={data} changePage={changePages}/>
      </div>
    )
  }
  