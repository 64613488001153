import moment from 'moment'
import React, { useState } from 'react'
import lan from '../../translate'

export default function OneDate({searchMethod=false}) {

  return (
    <div className=" sm:col-span-3">
        <div>
            <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                    {lan.filter_onedate}:
            </label>
        </div>
        <div className='flex'>
          

            <input
            type="date"
            onChange={e =>searchMethod&&searchMethod("dateCreate",moment(e.target.value,'YYYY-MM-DD').format('DD/MM/YYYY'))}
            className="block w-36   border-0 py-1.5 dark:text-white dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
            />
        </div>
         
                
    </div>
  )
}
