import React, { useEffect, useState, useContext,useCallback,useReducer } from 'react'
import { PhotoIcon, UserCircleIcon, PlusIcon } from '@heroicons/react/24/solid'
import {navigation, subNavigations, othersNavigation} from '../../routers'
import * as Helpers from '../../helpers/helpers'
import AlertContext from '../../context/contextAlert'
import { useNavigate , useLocation, useLoaderData } from "react-router-dom"
import ServicesContext from '../../context/contextServices'
import lan from '../../translate'
import { country } from '../../helpers/country'
import ProfileContext from '../../context/contextProfile'
import moment from 'moment'
import Select from '../../components/select/select'
import FileDocument from '../../components/files/fileDocument'
import SelectApi from '../../components/select/selectApi'
const PdfImage = require("../../images/pdf.png")
const bodyPsgField = {
    document:"",
    name:"",
    lastname:"",
    document_exp:"",
    year_old:0,
    birthday:"",
    nacionality:"",
    sex:"",
    position:0,
    pnr:""
}

const bodyPsgFieldRequired = {
    document:true,
    name:true,
    lastname:true,
    document_exp:true,
    year_old:true,
    birthday:true,
    nacionality:true,
    sex:true
}

const initialState = {
    passengers:[
        bodyPsgField
    ],
   
    supplier:"",
    ident:"",
    code:"",
    count:0,
    dateStart:"",
    dateReturn:"",
    status:"enabled",
    files:[],
    etickets:[],
    type:"",
    workflow:"create",
    userCreate:"",
    userUpdate:"",
    dateCreate:moment().format('DD/MM/YYYY'),
    hourCreate:moment().hours() + ":"+ moment().minutes(),
    dateUpdate:moment().format('DD/MM/YYYY'),
    hourUpdate:moment().hours() + ":"+ moment().minutes()

}

const fieldRequired = {
   
    supplier:true,
    ident:true,
    code:true,
    dateStart:true,
    type:true,
    supplier:true
}

const selectedValueInfo = {
  value:"",
  label:""
}



export  const WorkFlightCreate = () => {
    const services =  useContext(ServicesContext)
    const MySwal = useContext(AlertContext)
    const {user}  =  useContext(ProfileContext)
    const location = useLocation();
    const navigate = useNavigate();
    const [body,setBody] = useState(initialState)
    const [bodyFlight,setBodyFlight] = useState({})
    const [idUser, setIdUser] = useState(0)
    const [isUpdate, setIsUpdate] = useState(false)
    const [required,setRequired] = useState(fieldRequired)
    const [optionsPassenger, setOptionsPassenger] = useState([{label:"busca un pasajero.."}])
    const [optionsFlight, setOptionsFlight] = useState([{label:"busca un vuelo.."}])
    let [pnr, dispatch] = useReducer((pnr, { type, value, position }) => {
      let passengers = body.passengers
      switch (type) {
        case "add":
          return [...pnr, value];
        case "update":
            
            let newPassenger = Object.assign({},body.passengers[position],{
              ["pnr"]:value
            })
            passengers[position] = newPassenger
          return [...pnr];
        case "remove":
          return pnr.filter((_, index) => index !== value);
        case "load":
          return value
        default:
          return pnr;
      }
    }, []);

    let [fileList, dispatchFile] = useReducer((fileList, { type, value, position }) => {
      switch (type) {
        case "add":
          return [...fileList, value];
        case "show":
          return value
        case "update":
            fileList[position] =  value
          return [...fileList];
        case "remove":
          return fileList.filter((data,i)=> data.name !== value)
        case "load":
          return value
        default:
          return fileList;
      }
    }, []);

    const setBodyData = useCallback(event => {
        const { name, value } = event.target;
        setBody({ ...body, [name]: value });
        if(!value){
            setRequired({ ...required, [name]: lan.user_alert_fielderror })
        }else{
            setRequired({ ...required, [name]: "" })
        }
    });

    const setBodyDataUpperCase = useCallback(event => {
      const { name, value } = event.target;
      setBody({ ...body, [name]: value.toUpperCase() });
      if(!value){
          setRequired({ ...required, [name]: lan.user_alert_fielderror })
      }else{
          setRequired({ ...required, [name]: "" })
      }
  });

    
    const setBodyDataPsg = useCallback(event => {
        const { name, value } = event.target;
        let passengers = body.passengers
        let requireds = required.passengers
        let valueArray = name.split('.')
        let newPassenger = Object.assign({},body.passengers[valueArray[0]],{
          [valueArray[1]]:value
        })
        passengers[valueArray[0]] = newPassenger
       

    });

    function RequiredDisabled() {
      let requireds = required
      
      setRequired({ ...requireds})
    }

    

    function addPassenger() {
        body.passengers.push(bodyPsgField)
        setBody({ ...body, body });
    }
    function removePassenger(i) {
        body.passengers.splice(i,1)
        setBody({ ...body, body });
    }

    function editPassenger(i) {
      let data = body.passengers[i]
      delete data.position
      navigate("/passengers/new",{state:data})
    }
    function createPassenger(i) {
      let data = body.passengers[i]
      delete data.position
      navigate("/passengers/new")
    }

    async function editFlight() {
      const dataServices =  await services.Flights.getFlightById(bodyFlight._id)
      const result = dataServices.data[0]
      navigate("/flights/new",{state:result})
    }

    useEffect(()=>{
     loadData(location.state.data, location.state.isShow)
     
    },[ body.passengers])

    useEffect(()=>{
      if(!isUpdate  ){
        console.log("toy entrnado");
       setBody(initialState)
       body.passengers = [bodyPsgField]
       dispatch({type: "load", value: [bodyPsgField], position:0})
      }
    },[])

    // async function getAllValue() {
    //   const dataServices = await services.Passengers.getAllPassengers()
    //   const list = dataServices.data
    //   let allItem = list.map((data,i)=>{
    //       return {value:data._id,label:data.name}
    //     })
    // }


    function loadData(state,isShow) {
      console.log('state', state)
        if(state){
         setRequired({
          supplier:"",
          ident:"",
          dateStart:"",
          type:"",
          code:"",
          supplier:""
         })
        let  date1 =  moment(state.dateStart,'DD/MM/YYYY').format('YYYY-MM-DD')
         let date2 =  moment(state.dateReturn,'DD/MM/YYYY').format('YYYY-MM-DD')
         setBody({...state,
           
             no_flight: state.ruteFlight._id,
             numberFlight:state.ruteFlight.no_flight,
             hour_start: state.hour_start,
             hour_arrive: state.ruteFlight.hour_arrive,
             airline: state.ruteFlight.flightAirlines,
             origin: state.ruteFlight.iataOrigin,
             destination: state.ruteFlight.iataDestination,
             dateStart:  date1,
             dateReturn:  date2,

           } )
         dispatchFile({type: "show", value: state.files, position:0})
         setBodyFlight({...state, _id:state.no_flight})
         setIdUser(state._id)
         setIsUpdate(true)
         
         
        }

        
    }


    


    const setBodyDataSelectPassenger = useCallback(async event => {
      

      if(event.target.value.length>0){
        
        const dataServices =  await services.Passengers.getAllForSelect(event.target.value)
        if(dataServices.data){
          
          let allItem = dataServices.data.map((data,i)=>{
            return {value:data._id,label:data.name+' '+data.lastname+' - '+data.document}
        })
        setOptionsPassenger(allItem)
        }
        
      }
      return optionsPassenger
          
       
    });

    const setBodyDataSelectFlight = useCallback(async event => {
      

      if(event.target.value.length>0){
        
        const dataServices =  await services.RuteFlight.findAllLike({
          key:"name",
          value:event.target.value
        })
        if(dataServices.data){
          
          let allItem = dataServices.data.results.map((data,i)=>{
            return {
              value:data._id,
              label:data.nameRute+' | '+
              data.flightAirlines+' | '+
              data.hour_start +' | '+
              data.iataOrigin + ' - '+ data.iataDestination
            }
        })
        
        setOptionsFlight(allItem)
        }
        
      }
      return optionsFlight
          
       
    });

    const setBodyDataSelect = useCallback(async event  => {
      const {name,value} = event.target
      let passengers = body.passengers
      const dataServices =  await services.Passengers.getPassengerById(value.value)
      let passengerObject = dataServices.data[0]
      
      let newPassenger = Object.assign({},body.passengers[name],passengerObject)
      passengers[name] = newPassenger
      RequiredDisabled()

        
    });
    const setBodyDataFlight = useCallback(async event  => {
      const {name,value} = event.target
      let passengers = body.passengers
      const dataServices =  await services.RuteFlight.getFlightById(value.value)
      const result = dataServices.data
      setBody({
        ...body,
        no_flight:result._id,
        numberFlight: result.no_flight,
        origin:result.iataOrigin,
        destination:result.iataDestination,
        hour_start:result.hour_start,
        hour_arrive:result.hour_arrive,
        airline:result.flightAirlines
      })
      setBodyFlight(result)
      RequiredDisabled()

        
    });




    async function update() {
      MySwal.fire({
        title: lan.loading_save,
        allowOutsideClick: false,
      })
      MySwal.showLoading();
      if(Helpers.dateIsBefore(body.dateStart,body.dateReturn)){
        return MySwal.fire({
          confirmButtonColor: '#1EA0D5',
          icon: "warning",
          text:lan.workflight_field_date_before
        })
      }

      if(Helpers.checkIsRequired(required) || !body.no_flight || !body.passengers[0].document){

        MySwal.fire({
            confirmButtonColor: '#1EA0D5',
            icon: "warning",
            text:lan.user_alert_field_empty
          })
      }

      if(!Helpers.checkIsRequired(required) && body.no_flight && body.passengers[0].document){
       
       const diffFile =  fileList.filter(
        fileBody => !body.files.some(newFile => fileBody.name === newFile.name)
        )
        if(diffFile.length>0){
          const resultFile = await services.File.uplodMultipleFile({files:diffFile})
          if(resultFile.status !== 200 && resultFile.status !==201){
            return  MySwal.fire({
              confirmButtonColor: '#1EA0D5',
              icon: "error",
              title:lan.alert_errorcreate_generic,
              text: "Error: FileServer"
              }) 
          }
          for (const key in resultFile.data) {
            body.files.push(resultFile.data[key])
          }
        }else{
          body.files = fileList
        }
        
        body.userUpdate = user._id
        body.dateUpdate = moment().format("DD/MM/YYYY")
        body.hourUpdate = moment().hours() + ":"+ moment().minutes()
        body.count = body.passengers.length.toString()
        body.supplier=body.supplier.toUpperCase()
        body.ident=body.ident.toUpperCase()
        body.code = body.code.toUpperCase().trim()
        body.flight = bodyFlight._id
        body.dateStart = moment(body.dateStart,'YYYY-MM-DD').format("DD/MM/YYYY")
        body.dateReturn = moment(body.dateReturn,'YYYY-MM-DD').format("DD/MM/YYYY")
        delete body.no_flight
        delete body.create
        delete body.__v
        delete body._id
        delete body.dateCreate
        delete body.hourCreate
        delete body.userCreateInfo
        delete body.userUpdateInfo
        delete body.flightInfo
        delete body.flightAirline
        delete body.flightOrigin
        delete body.flightDestination
        delete body.userCreate
        delete body.countryOrigin
        delete body.countryDestiny
        delete body.hour_start
        delete body.hour_arrive
        delete body.duration
        delete body.airlineInfo
        delete body.airline
        delete body.origin
        delete body.destination
        delete body.numberFlight
        delete body.airlinesNames
        delete body.iataDestination
        delete body.ruteFlight
        delete body.body
        if(location.state.isShow){
          const result = await services.WorkFlight.update({dateReturn:body.dateReturn}, idUser)
          if(result.status ===200){
            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "success",
                text:lan.alert_create_generic
              })
            return navigate('/workflight/documents')
          }
          return
        }
        const result = await services.WorkFlight.update(body, idUser)
        if(result.status ===200){
            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "success",
                text:lan.alert_create_generic
              })

            return navigate('/workflight/passengers')
        }

        if(result.response && result.response.data.statusCode === 400){
            return  MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "error",
                title:lan.alert_errorcreate_generic,
                text: "Error: "+result.response.data.message
                })
        }

        MySwal.fire({
            confirmButtonColor: '#1EA0D5',
            icon: "error",
            text:lan.user_alert_error500
            })
            
      }

    }


    async function save() {
      MySwal.fire({
        title: lan.loading_save,
        allowOutsideClick: false,
      })
      MySwal.showLoading();

      if(Helpers.dateIsBefore(body.dateStart,body.dateReturn)){
        return MySwal.fire({
          confirmButtonColor: '#1EA0D5',
          icon: "warning",
          text:lan.workflight_field_date_before
        })
      }
      
      if(Helpers.checkIsRequired(required) || !body.no_flight || !body.passengers[0].document){

        MySwal.fire({
            confirmButtonColor: '#1EA0D5',
            icon: "warning",
            text:lan.user_alert_field_empty
          })
      }
      
      if(!Helpers.checkIsRequired(required) && body.no_flight && body.passengers[0].document){
        if(fileList.length>0){
          const resultFile = await services.File.uplodMultipleFile({files:fileList})
          if(resultFile.status !== 200 && resultFile.status !==201){
            return  MySwal.fire({
              confirmButtonColor: '#1EA0D5',
              icon: "error",
              title:lan.alert_errorcreate_generic,
              text: "Error: FileServer"
              }) 
          }
          body.files = resultFile.data
        }
       
        
        body.userCreate = user._id
        body.userUpdate = user._id
        body.count = body.passengers.length.toString()
        body.supplier=body.supplier.toUpperCase()
        body.ident=body.ident.toUpperCase()
        body.flight = bodyFlight._id
        body.code = body.code.toUpperCase().trim()
        body.filesDoc = []
        
        body.dateStart = moment(body.dateStart,'YYYY-MM-DD').format("DD/MM/YYYY")
        body.dateReturn = moment(body.dateReturn,'YYYY-MM-DD').format("DD/MM/YYYY")
        delete body.no_flight
        delete body.countryOrigin
        delete body.countryDestiny
        delete body.hour_start
        delete body.hour_arrive
        delete body.duration
        delete body.airlineInfo
        delete body.airline
        delete body.origin
        delete body.destination
        delete body.body
        delete body.numberFlight
        const result = await services.WorkFlight.save(body)
        if(result.status ===201){
            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "success",
                text:lan.alert_create_generic
              })
              dispatch({ type: "load", value: [], position:0})

            return navigate('/workflight/passengers')
        }

        if(result.response && result.response.data.statusCode === 400){
            return  MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "error",
                title:lan.alert_errorcreate_generic,
                text: "Error: "+result.response.data.message
                })
        }

        MySwal.fire({
            confirmButtonColor: '#1EA0D5',
            icon: "error",
            text:lan.user_alert_error500
            })
            
      }

    }

    async function download(filesDoc){
      let urls = filesDoc.map((data,i)=> data.public_id)
      const result = await services.File.downloadFiles(urls)
      if(result.data.ok){
        window.open(result.data.zip)
      }
    }


  
    
    
  return (
    <div className="space-y-10 divide-y divide-gray-900/10">
    <div className="grid grid-cols-1 pt-10 gap-x-8 gap-y-8 md:grid-cols-3">
        
      <div className="px-4 sm:px-0">
        <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">{lan.flight_new_title}</h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">{lan.flight_new_subtitle}</p>
      </div>

      <div className="bg-white shadow-sm dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-slate-800 ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
        <div className="px-4 py-6 sm:p-8" id="psgContainer">
            {
               
                body.passengers&& body.passengers.length>0&&body.passengers.map((data,i)=>
                <div>
                    <label className='text-blue-primary'><b>Pasajero {i+1}</b></label>
                    {bodyPsg(required,body,setBodyDataPsg,i,data,optionsPassenger,setBodyDataSelectPassenger,setBodyDataSelect,dispatch,location)}
                    <br/>
                    <button hidden={location.state.isShow} type="button" className="px-3 py-2 text-sm font-semibold text-white bg-red-700 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" 
                        onClick={()=>removePassenger(i)}>
                        {lan.workflight_btn_remove_psg}
                    </button>&nbsp;&nbsp;
                    <button hidden={location.state.isShow} type="button" className="px-3 py-2 text-sm font-semibold text-white bg-green-700 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" 
                        onClick={()=>createPassenger(i)}>
                        {lan.workflight_btn_creat_psg}
                    </button>&nbsp;&nbsp;
                    { !location.state.isShow && body.passengers[i].document&&
                      <button type="button" className="px-3 py-2 text-sm font-semibold text-white bg-blue-700 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" 
                      onClick={()=>editPassenger(i)}>
                      {lan.workflight_btn_edit_psg}
                      </button>
                    }
                    <br/>
                    <br/>
                    <hr/>
                    <br/>
                </div>
                    

                )
            }
            
        </div>
        
        <div className="flex items-center justify-end px-4 py-4 border-t col-span-full gap-x-6 border-gray-900/10 dark:border-white/10 sm:px-8">
          
          {!location.state.isShow&&
              <button
              onClick={()=> addPassenger()}
              className="px-3 py-2 text-sm font-semibold text-white bg-indigo-500 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {lan.workflight_btn_add_psg}
            </button>
          }
        </div>
       
      </div>
    </div>




    <div className="grid grid-cols-1 pt-10 gap-x-8 gap-y-8 md:grid-cols-3">
        
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">{lan.flight_new_title}</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">{lan.flight_new_subtitle}</p>
        </div>
  
        <div className="bg-white shadow-sm dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-slate-800 ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

            

              <div className="sm:col-span-6">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                 {lan.workflight_search_flight} <span className={required.no_flight?"text-red-700":""}>*</span>
                </label>
                <div className="mt-2">
                <Select 
                  options={optionsFlight} 
                  nameDefault={"id"}
                  inputMethod={true}
                  asyncOption={true}
                  onMethodClick={setBodyDataFlight}
                  onMethod={setBodyDataSelectFlight} 
                  valueDefault={body.no_flight}/>
                </div>
                <span className='text-xs text-red-700'>{required.no_flight?required.no_flight:""}</span>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                 {lan.flights_field_noflight} <span className={required.no_flight?"text-red-700":""}>*</span>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name={"numberFlight"}
                    id={"numberFlight"}
                    disabled
                    value={body.numberFlight}
                    onChange={setBodyData}
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                  />
                  
                </div>
                <span className='text-xs text-red-700'>{required.no_flight?required.no_flight:""}</span>
              </div>
  
              
              <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                 {lan.flights_field_airline} <span className={required.airline?"text-red-700":""}>*</span>
                </label>
                
                <input
                    type="text"
                    name={"airline"}
                    id={"airline"}
                    value={body.airline}
                    onChange={setBodyData}
                    disabled
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                  />
                <span className='text-xs text-red-700'>{required.airline?required.airline:""}</span>
                
              </div>
  
              <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                 {lan.flights_field_origin} <span className={required.origin?"text-red-700":""}>*</span>
                </label>
                
                <input
                    type="text"
                    name={"origin"}
                    id={"origin"}
                    value={body.origin}
                    onChange={setBodyData}
                    disabled
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                  />
                <span className='text-xs text-red-700'>{required.origin?required.origin:""}</span>
                
              </div>
  
              <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                 {lan.flights_field_destination} <span className={required.destination?"text-red-700":""}>*</span>
                </label>
               
                <input
                    type="text"
                    name={"destination"}
                    id={"destination"}
                    value={body.destination}
                    onChange={setBodyData}
                    disabled
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                  />
                <span className='text-xs text-red-700'>{required.destination?required.destination:""}</span>
                
              </div>
  
              <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                 {lan.flights_field_hourstart} <span className={required.hour_start?"text-red-700":""}>*</span>
                </label>
                <div className="mt-2">
                  <input
                    type="time"
                    name={"hour_start"}
                    id={"hour_start"}
                    value={body.hour_start}
                    onChange={setBodyData}
                    disabled
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                  />
                </div>
                <span className='text-xs text-red-700'>{required.hour_start?required.hour_start:""}</span>
              </div>
  
              <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                 {lan.flights_field_hourarrive} <span className={required.hour_arrive?"text-red-700":""}>*</span>
                </label>
                <div className="mt-2">
                  <input
                    type="time"
                    name={"hour_arrive"}
                    id={"hour_arrive"}
                    value={body.hour_arrive}
                    onChange={setBodyData}
                    disabled
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                  />
                </div>
                <span className='text-xs text-red-700'>{required.hour_arrive?required.hour_arrive:""}</span>
              </div>
  
              {/* <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                 {lan.flights_field_duration} <span className={required.duration?"text-red-700":""}>*</span>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name={"duration"}
                    id={"duration"}
                    value={body.duration}
                    disabled
                    onChange={setBodyData}
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                  />
                </div>
                <span className='text-xs text-red-700'>{required.duration?required.duration:""}</span>
              </div> */}
  
              

   
              
            </div>
            <br/>
            <button hidden={location.state.isShow} type="button" className="px-3 py-2 text-sm font-semibold text-white bg-green-700 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" 
                        onClick={()=>navigate('/flights/new')}>
                        {lan.workflight_btn_add_flight}
              </button>&nbsp;&nbsp;
              {!location.state.isShow&&
              <button  type="button" hidden={!body.no_flight } className="px-3 py-2 text-sm font-semibold text-white bg-blue-700 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" 
              onClick={()=>editFlight()}>
              {lan.workflight_btn_edit_flight}
              </button>
              }
              &nbsp;&nbsp;
          </div>
         
        </div>
    </div>

    <div className="grid grid-cols-1 pt-10 gap-x-8 gap-y-8 md:grid-cols-3">
        
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">{lan.flight_new_title}</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">{lan.flight_new_subtitle}</p>
        </div>
  
        <div className="bg-white shadow-sm dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-slate-800 ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

            

             

  
              
              <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                 {lan.workflight_field_supplier} <span className={required.supplier?"text-red-700":""}>*</span>
                </label>
                
               
                  <SelectApi
                  apiType={"supplier"}
                  name={"supplier"}
                  id={"supplier"}
                  value={body.supplier}
                  onChange={setBodyData}
                  autoComplete="given-name"
                />
                <span className='text-xs text-red-700'>{required.supplier?required.supplier:""}</span>
                
              </div>
  
              <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                 {lan.workflight_field_ident} <span className={required.ident?"text-red-700":""}>*</span>
                </label>
                
                <input
                    type="text"
                    name={"ident"}
                    id={"ident"}
                    value={body.ident}
                    onChange={setBodyData}
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                  />
                <span className='text-xs text-red-700'>{required.ident?required.ident:""}</span>
                
              </div>
  
             
  
              <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                 {lan.workflight_field_date_flight} <span className={required.dateStart?"text-red-700":""}>*</span>
                </label>
                <div className="mt-2">
                  <input
                    type="date"
                    name={"dateStart"}
                    id={"dateStart"}
                    value={body.dateStart}
                    onChange={setBodyData}
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                  />
                </div>
                <span className='text-xs text-red-700'>{required.dateStart?required.dateStart:""}</span>
              </div>
  
              <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                 {lan.workflight_field_date_flight_return} 
                </label>
                <div className="mt-2">
                  <input
                    type="date"
                    name={"dateReturn"}
                    id={"dateReturn"}
                    value={body.dateReturn}
                    onChange={setBodyData}
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                 {lan.workflight_field_code} <span className={required.code?"text-red-700":""}>*</span>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name={"code"}
                    id={"code"}
                    value={body.code}
                    onChange={setBodyDataUpperCase}
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                  />
                </div>
                <span className='text-xs text-red-700'>{required.code?required.code:""}</span>
              </div>
  
              <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                 {lan.workflight_field_type} <span className={required.type?"text-red-700":""}>*</span>
                </label>
               
                
                <SelectApi
                  apiType={"type_reservation"}
                  name={"type"}
                  id={"type"}
                  value={body.type}
                  onChange={setBodyData}
                  autoComplete="given-name"
                />
                <span className='text-xs text-red-700'>{required.type?required.type:""}</span>
                
              </div>
              
            </div>
          </div>
         
        </div>
    </div>
          

    <div style={{display:location.state.isShow&&'none'}} className="grid grid-cols-1 pt-10 gap-x-8 gap-y-8 md:grid-cols-3">
        
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">{lan.workflight_title_file}</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">{lan.workflight_subtitle_file}</p>
        </div>
  
        <div className="bg-white shadow-sm dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-slate-800 ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

        
            {fileList.length>0&&fileList.map((data,i)=>
              <div className="flex flex-wrap sm:col-span-3">
                <FileDocument ruteFile={"trolbis/workspace_flight/"+moment().format('DDMMYYYY')} category={"documents"}  position={i} fileList={fileList} setMethod={dispatchFile}/>
              </div>
            )}
             <div className="sm:col-span-3">
              <div onClick={()=>dispatchFile({ type: "add", value: "", position:0})} className='flex flex-wrap w-full gap-12 cursor-pointer'>
                <div className="flex justify-center px-6 py-8 mt-2 border border-indigo-600 border-dashed rounded-lg">
                  <div className="text-center">
                    <PlusIcon  className="h-8 mx-auto text-gray-300 w-15" aria-hidden="true"/>
                    <div className="flex justify-center mt-4 text-sm leading-6 text-center text-gray-600">
                     
                    </div>
                    <p className="text-xs leading-5 text-gray-600"> Agregar  Archivos </p>

                  </div>
              </div>
              </div>
                </div>
              
            </div>
          </div>
         
        </div>
    </div>

    <div className="grid grid-cols-1 pt-10 gap-x-8 gap-y-8 md:grid-cols-3">
        
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">{lan.workflight_title_fileDoc}</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">{lan.workflight_subtitle_fileDoc}</p>
        </div>
  
        <div className="bg-white shadow-sm dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-slate-800 ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              {body.filesDoc&&body.filesDoc.length>0&& body.filesDoc.map((data,i)=>
                <div className="sm:col-span-3">
                  <div onClick={()=> window.open(data.file,"_blank")} className='flex flex-wrap w-full gap-12 cursor-pointer'>
                    <div className="flex justify-center px-6 py-8 mt-2 border border-indigo-600 border-dashed rounded-lg">
                      <div className="text-center">
                        <center>
                        <img src={PdfImage} width={70} aria-hidden="true"/>
                        </center>
                        <div className="flex justify-center mt-4 text-sm leading-6 text-center text-gray-600">
                        
                        </div>
                        <p className="text-xs leading-5 text-gray-600"> {data.name} </p>

                      </div>
                    </div>
                  </div>
                </div>
              )}
              {body.etickets&&body.etickets.length>0&& body.etickets.map((data,i)=>
                <div className="sm:col-span-3">
                  <div onClick={()=> window.open(data.file,"_blank")} className='flex flex-wrap w-full gap-12 cursor-pointer'>
                    <div className="flex justify-center px-6 py-8 mt-2 border border-indigo-600 border-dashed rounded-lg">
                      <div className="text-center">
                        <center>
                        <img src={PdfImage} width={70} aria-hidden="true"/>
                        </center>
                        <div className="flex justify-center mt-4 text-sm leading-6 text-center text-gray-600">
                        
                        </div>
                        <p className="text-xs leading-5 text-gray-600"> {data.name} </p>

                      </div>
                    </div>
                  </div>
                </div>
              )}
              
            </div>
          </div>
          <div className='p-2'>
            {location.state.isShow&&
            <button hidden={body.filesDoc&&body.filesDoc.length===0} onClick={()=>download(body.filesDoc)} className='px-3 py-2 text-sm font-semibold text-white bg-green-800 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>
                  {lan.download_button}
            </button>
            }
          </div>
        </div>
    </div>
          



    

   

    <div className="flex items-center justify-end px-4 py-4 border-t col-span-full gap-x-6 border-gray-900/10 dark:border-white/10 sm:px-8">
          <button type="button" className="text-sm font-semibold leading-6 text-blue-primary"
           onClick={()=>navigate(-1)}>
            {lan.user_button_cancel}
          </button>
            <button
            onClick={()=> isUpdate? update() : save()}
            className="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-blue-primary hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {lan.user_button_save}
          </button>
          
    </div>
  </div>
  )
}




function bodyPsg(required,body,setBodyDataPsg,id,data,optionsPassenger,setBodyDataSelectPassenger,setBodyDataSelect,dispatch,location) {
    return (
        <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6" id="psg" key={location.key}>
          
          
          <div className="sm:col-span-6">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                Buscar el pasajero
              </label>
              <Select 
              options={optionsPassenger} 
              nameDefault={id}
              inputMethod={true}
              asyncOption={true}
              onMethodClick={setBodyDataSelect}
              onMethod={setBodyDataSelectPassenger} 
              valueDefault={body.passengers[id].name}/>
              
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
               {lan.passengers_field_document} 
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name={id+".document"}
                  id={id+".document"}
                  value={body.passengers[id].document}
                  onChange={setBodyDataPsg}
                  autoComplete="given-name"
                  disabled
                  className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                />
              </div>
              
            </div>
                 
            <div className="sm:col-span-2">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
               {lan.passengers_field_name} 
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name={id+".name"}
                  id={id+".name"}
                  value={body.passengers[id].name}
                  onChange={setBodyDataPsg}
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                />
              </div>
              
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
               {lan.passengers_field_lastname} 
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name={id+".lastname"}
                  id={id+".lastname"}
                  value={body.passengers[id].lastname}
                  onChange={setBodyDataPsg}
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                />
              </div>
              
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
               {lan.passengers_field_document_exp} 
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name={id+".document_exp"}
                  id={id+".document_exp"}
                  value={body.passengers[id].document_exp}
                  onChange={setBodyDataPsg}
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
               {lan.passengers_field_yearsold} 
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  name={id+".year_old"}
                  id={id+".year_old"}
                  value={body.passengers[id].year_old}
                  onChange={setBodyDataPsg}
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
               {lan.passengers_field_bithday} 
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name={id+".birthday"}
                  id={id+".birthday"}
                  value={body.passengers[id].birthday}
                  onChange={setBodyDataPsg}
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
               {lan.passengers_field_nacionality} 
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name={id+".nacionality"}
                  id={id+".nacionality"}
                  value={body.passengers[id].nacionality}
                  onChange={setBodyDataPsg}
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
               {lan.passengers_field_sex} 
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name={id+".sex"}
                  id={id+".sex"}
                  value={body.passengers[id].sex}
                  onChange={setBodyDataPsg}
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
               {lan.workflight_field_code} 
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name={id+".pnr"}
                  id={id+".pnr"}
                  value={body.passengers[id].pnr}
                  onChange={(e)=>dispatch({ type: "update", value: e.target.value, position:id})}
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            
          </div>
    )
    
}


export default WorkFlightCreate
