
import React, { useContext, useState } from 'react';
import lan from '../../translate/index'
import ServicesContext from "../../context/contextServices";
import AlertContext from "../../context/contextAlert";
import { useNavigate } from "react-router-dom"
import { Loading } from '../../components/loading/loading'
import * as Helpers from '../../helpers/helpers'
const Logo = require("../../images/logo.png")
export default  function Login() {
  const services = useContext(ServicesContext)
  const MySwal = useContext(AlertContext)
  const navigate = useNavigate();

  const [email,setEmail] = useState("")
  const [password,setPassword] = useState("")
  const [load,setLoad] = useState(false)



  async function loginUser() {
    setLoad(true)
    let body = {
      email:email,
      password:password
    }
    if(!email || !password){
      
      MySwal.fire({
        confirmButtonColor: '#1EA0D5',
        icon: "warning",
        text:lan.user_alert_fielderrors
      })
      return
    }
    const result = await services.Users.login(body)


      if(result.data&&result.data.token&& !result.data.isActive){
        setLoad(false)
        return MySwal.fire({
          confirmButtonColor: '#1EA0D5',
          icon: "warning",
          text:lan.user_alert_contactadmin,
          title:lan.user_alert_userinactive
        })
        
      }

    if(result.data&&result.data.token&& result.data.isActive){
      
      
      if(result.data.type === "customer"){
        setLoad(false)
        return MySwal.fire({
          confirmButtonColor: '#1EA0D5',
          icon: "warning",
          text:lan.user_alert_contactadmin,
          title:lan.user_alert_usercustomer
        })
      }
      
      MySwal.fire({
        confirmButtonColor: '#1EA0D5',
        icon: "success",
        text:lan.user_alert_welcome
      })
      window.localStorage.setItem("token-t",Helpers.encryptData(result.data))
      const loadLang = await services.File.getFileOneLang("es")
      if(loadLang.data)
      window.localStorage.setItem("lan-t",JSON.stringify(loadLang.data))

      window.location.href = "/"
    }else{
      MySwal.fire({
        confirmButtonColor: '#1EA0D5',
        icon: "error",
        text:lan.user_alert_error_credential
      })
    }
    setLoad(false)
    
   
  }
 
    return (
      <>
    
        <div className="flex flex-col justify-center flex-1 min-h-full px-6 py-12 bg-white lg:px-8 dark:bg-slate-800 bg-ex" >
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              className="w-auto h-24 mx-auto"
              src={Logo}
              alt="Your Company"
            />
            
            {/* <h2 className="mt-10 text-2xl font-bold leading-9 tracking-tight text-center text-gray-900">
              Trolbis Admin
            </h2> */}
          </div>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
                 {lan.email}
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e)=>setEmail(e.target.value)}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-secondary sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              
              <br/>
              <div>
                <div className="flex items-center justify-between">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
                    {lan.password}
                  </label>
                  
                  <div className="text-sm">
                    <a href="#" className="font-semibold text-blue-secondary hover:text-indigo-500">
                     {lan.forgot}
                    </a>
                  </div>
                  
                </div>
                
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={password}
                    autoComplete="current-password"
                    onChange={(e)=>setPassword(e.target.value)}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-secondary  sm:text-sm sm:leading-6"
                  />
                  
                </div>
                <br/>
                <Loading show={load} classCustome="text-center" w={20} h={20}/>
               
              </div>
              <br/>
             
              <div>
                <button
                  onClick={()=> loginUser()}
                  className="flex w-full justify-center rounded-md bg-blue-secondary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {lan.sign_in}
                </button>

               
              </div>
  
           
          </div>
        </div>
      </>
    )
  }
  