import React, { useState } from 'react'
import lan from '../../translate'

export default function Search({searchValue=[],searchMethod=false}) {
    const [key,setKey] = useState("")
    const [value,setValue] = useState("")
  return (
    <div className="sm:col-span-3">
        <div>
            <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                    {lan.filter_search}:
            </label>
        </div>
        <div className='flex'>
            <select
                id="findLike"
                name="findLike"
                onChange={(e)=>setKey(e.target.value)}
                className="block w-24   border-0  py-1.5 dark:text-white dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
            >
                 <option className='dark:bg-slate-900' value={"data"}>{lan.filter_selected}</option>
                { Object.keys(searchValue).length>0&&Object.keys(searchValue).map((data,i)=>
                    <option className='dark:bg-slate-900' value={data}>{searchValue[data]}</option>
                )}
            </select>

            <input
            type="search"
            id="findLikeValue"
            name="findLikeValue"
            onChange={(e)=>setValue(e.target.value)}
            className="block w-36   border-0 py-1.5 dark:text-white dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
            />
            &nbsp;
            <button
                onClick={()=> searchMethod&&searchMethod(key,value)}
                className="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-blue-primary hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
                {lan.filter_buttom}
            </button>
        </div>
         
                
    </div>
  )
}
