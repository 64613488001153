import { createContext, useState } from "react";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const AlertContext = createContext()

const AlertProvider =  ({children}) =>{
    const MySwal = withReactContent(Swal)
    
    return <AlertContext.Provider value={MySwal}>
                {children}
            </AlertContext.Provider>;
    
}
export { AlertProvider}
export default AlertContext