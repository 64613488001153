import lan from '../../translate';
import api from '../api/create';

function getAllPassengers(limit=10,page=1) {
    return api.get(`/passengers?limit=${limit}&skip=${page}`)
}

function findAllLike(body,limit=1000,page=1) {
    return api.post(`/passengers/find?limit=${limit}&skip=${page}`,body)
}

function getAllForSelect(search) {
    return api.get(`/passengers/findselect/${search}`)
}

function getPassengerById(id) {
    return api.get(`/passengers/${id}`)
}

function findRangeDate(body,limit=1000,page=1) {
    return api.post(`/passengers/find/rangedate?limit=${limit}&skip=${page}`,body)
}

function deletePassenger(id) {
    return api.delete(`/passengers/${id}`)
}

function savePassenger(body) {
    return api.post(`/passengers/`,body)
}

function updatePassenger(body,id) {
    return api.patch(`/passengers/${id}`,body)
}


function headers() {
    const headers = {
        type_document:lan.headers_passengers_type_document,
        document:lan.headers_passengers_document,
        name:lan.headers_passengers_name,
        lastname:lan.headers_passengers_lastname,
        birthday:lan.headers_passengers_birthday,
        sex:lan.headers_passengers_sex,
        dateCreate:lan.headers_passengers_dateCreate

    }

    return headers
}

function fields(setState) {
    const fields_init = {
        document:{name:lan.headers_passengers_document, key:"document", required: true,value:""},
        name:{name:lan.headers_passengers_name, key:"name", required: true,value:""},
        passport:{name:lan.headers_passengers_lastname, key:"passport", required: true,value:""},
        type_document:{name:lan.headers_passengers_type_document, key:"type_document", required: true,value:""},
        year_old:{name:lan.headers_passengers_yearsold, key:"year_old", required: true,value:""},
        document_exp:{name:lan.headers_passengers_document_exp, key:"document_exp", required: true,value:""},
        nacionality:{name:lan.headers_passengers_nacionality, key:"nacionality", required: true,value:""},
        sex:{name:lan.headers_passengers_sex, key:"sex", required: true,value:""},
        dateCreate:{name:lan.headers_passengers_dateCreate, key:"dateCreate", required: true,value:""},
        hourCreate:{name:lan.headers_passengers_hourCreate, key:"hourCreate", required: true,value:""},
    }
    setState(fields_init)
}

function btnTable() {
    const btn = {
        create:{name:lan.table_btn_passengers_create, colorClass:"block px-3 py-2 text-sm font-semibold text-center text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" },
        update:{name:lan.table_btn_passengers_update, colorClass:"text-blue-primary   hover:text-indigo-900"},
        delete:{name:lan.table_btn_passengers_delete, colorClass:"text-red-800   hover:text-red-900"},
    }
    return btn
}

export {
    getAllPassengers,
    findAllLike,
    findRangeDate,
    fields,
    headers,
    btnTable,
    deletePassenger,
    savePassenger,
    updatePassenger,
    getAllForSelect,
    getPassengerById
}
