
import { useContext, useEffect, useState } from 'react'

import ProfileContext from '../../context/contextProfile'



export default  function Dashboard() {
  const {setUser, user, getUserInfo} =  useContext(ProfileContext)

 
  useEffect(()=>{
  },[])
  return (
    <h1>hola mundo {user?.email}</h1>
  )
}
