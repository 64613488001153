



import React from 'react'

export const Loading = ({show,classCustome="absolute top-80 left-1/2 -translate-x-1/4 -translate-y-1/4",w=8,h=8}) => {

   
    if(show){
        return (
            <div className={classCustome}>
                 <div
                    className="inline-block h-8 w-8 animate-spin rounded-full  text-blue-secondary border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status">
                    <span
                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                    >Loading...</span>
                </div>
            </div>
            
          )
    }
    return<></>

    
 
}
