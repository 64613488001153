import { useContext, useEffect, useState,useReducer } from 'react'
import lan from '../../translate/index'
import Tablet from '../../components/tablet/tablet'
import ServicesContext from '../../context/contextServices'
import AlertContext from '../../context/contextAlert'
import { useNavigate } from "react-router-dom"
import ProfileContext from '../../context/contextProfile'
import moment from 'moment'
import Paginations from '../../components/pagination/paginations'
import {removeRepeat,formatDataDocuments} from '../../helpers/helpers'
import Modal from '../../components/modal/modal'
import ModalDocuments from './modalDocuments'
import * as Helpers from '../../helpers/helpers'



let dataInitial = {
    results:[],
    total:0,
    pageCurrent:1,
    limit:10,
    pageNext:2,
    pagesTotal:0
  }
  
  export default function WorkFlight() {
      const services =  useContext(ServicesContext)
      const {user}  =  useContext(ProfileContext)
      const MySwal = useContext(AlertContext)
      const navigate = useNavigate();
      const [data,setData] = useState(dataInitial)
      const [dataReload,setDataReload] = useState([])
      const [headers,setHeaders] = useState([])
      const [btn,setBtn] = useState([])
      const [cusFilters,setCusFilters] = useState([])
      const [firsFilter,setFirsFilter] = useState(false)
      const [allFilters, setAllFilters] = useState([])
      const [open,setOpen] = useState(false)
      const [limit, setLimit] = useState(10)
      let [documents, dispatch] = useReducer((documents, { type, value, position }) => {
        switch (type) {
          case "add":
            return [...documents, value];
          case "remove":
            return documents.filter((doc,i)=> doc.ident !== value.ident)
          case "reload":
            return []
        }
      }, []);
  
  
      async function getDataAll() {
          const dataServices = await services.WorkFlight.getAll(limit)
          const headerServices = await services.WorkFlight.headersDocuments()
          const btnServices = await services.WorkFlight.btnTableDocuments()
          const filters = await services.WorkFlight.customFilter()
          if(dataServices.data){
              setData(dataServices.data)
              setDataReload(dataServices.data)
              setHeaders(headerServices)
              setBtn(btnServices)
              setCusFilters(filters)
          }
          
         
      }
  
      
      


      
      async function findAllLike(key,value) {

        let allFilterArray = allFilters
        let isDuplicate = removeRepeat(allFilterArray,{[key]:{"$regex": value, "$options" : "i"}})
        if(!isDuplicate){
          allFilterArray.push({[key]:{"$regex": value, "$options" : "i"}})
        }
        const resul = await services.WorkFlight.findMultipleFilters({filter:allFilterArray})
        
        if(resul.status === 201 && resul.data.results.length>0){
          setData(resul.data)
        }
        if(resul.status === 201 && resul.data.results.length===0){
            setData(false)
        }
        setAllFilters(allFilterArray)
        setFirsFilter(true)
        
        
        
      }

      
  
  
      async function findRangeDate(key,body) {
          if(key && body.dateOne && body.dateTwo){
            let bodyFind = {
               key:key,
               dateOne:moment(body.dateOne,'YYYY-MM-DD').format('DD/MM/YYYY') ,
               dateTwo:moment(body.dateTwo,'YYYY-MM-DD').format('DD/MM/YYYY') 
            }
           const resul = await services.WorkFlight.findRangeDate(bodyFind)
           if(resul.status === 201 && resul.data.results.length>0){
               setData(resul.data)
           }
           if(resul.status === 201 && resul.data.results.length===0){
               setData(false)
           }
        }else{
           setData(dataReload)
        }
      }
  
  
     
  
      async function changePages(skip) {
          const dataServices = await services.WorkFlight.getAll(limit,skip)
          setData(dataServices.data)
      }

      function clearFilter() {
        setFirsFilter(false)
        setData(dataReload)
        setAllFilters([])
        dispatch({ type: "reload", value: [], position:0})
        Helpers.clearCheckboxes("table-check")

      }

      async function setLimitFunc(limitChange){
        setLimit(limitChange)
        const dataServices = await services.WorkFlight.getAll(limitChange,1)
        setData(dataServices.data)
      }
  
      useEffect(()=>{
          getDataAll()
        },[])

        function checkBtn(e,data) {
            if(data.type.toLowerCase() !== "comprado" || !data.dateReturn || data.dateReturn === "Invalid date"){
                e.target.checked = false
                return MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "warning",
                    text:lan.workflight_btn_check_empty_alert
                  })
            }

            if( moment(data.dateStart,"DD/MM/YYYY").isBefore(moment(), 'day')){
              e.target.checked = false
                return MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "warning",
                    text:lan.workfligt_document_dateBefore
                  })
            }
              console.log('data', data)
            let dataFlight = formatDataDocuments(data)
            if(e.target.checked){
                dispatch({ type: "add", value: dataFlight, position:0})
            }else{
                dispatch({ type: "remove", value: dataFlight, position:0})
            }

        }
  
      

        function createDocuments(){
            if(documents.length>0){
                setOpen(true)
            }else{
                MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "warning",
                    text:lan.workflight_btn_create_alert
                  })
            }
        }

        function edit(data) {
            navigate("/workflight/new",{state:{data:data,isShow:true}})
        }


        async function createDocumentSave() {
            try {
                setOpen(false)
                MySwal.fire({
                    title: lan.document_loading,
                    allowOutsideClick: false,
                })
                MySwal.showLoading();
                const result = await services.File.createDoc({allDocuments:documents})
                getDataAll()
                MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "success",
                    text:lan.alert_create_generic
                })
                clearFilter()
            } catch (error) {
                console.log('error', error)
                MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "error",
                    title:lan.alert_errorcreate_generic,
                    text: "Error: FileServer"
                })
            }
            
        }
    return (
      <div>
        <Tablet 
          filters={true}
          cusFilters={cusFilters}
          clearFilter={clearFilter}
          firstFilter={firsFilter}
          limit={limit}
          setLimit={setLimitFunc}
          searchMethod={findAllLike}
          searchRangeDate={findRangeDate}
          search={
            {
             "passengers.name":"Nombre Pasajero",
              "passengers.pasaport":"Pasaporte Pasajero",
              "flightInfo.hour_start":"Hora de salida",
              "flightInfo.duration":"Duracion",
              "flightAirline.name":"Aerolinea", 
              "flightDestination.name":"Destino",
              supplier:"Proveedor",
              code:"PNR"
            }
          }
          createBtn={()=> createDocuments()}
         // deleteBtn={remove}
          checkBtn={checkBtn}
          editBtn={edit}
          data={data.results} 
          headers={headers}
          btn={btn}
          title={lan.workflight_title_create_document}
          subTitle={lan.workflight_subtitle_create_document}/>
  
        <Paginations data={data} changePage={changePages}/>
        <Modal open={open} setOpen={setOpen} action={createDocumentSave} body={<ModalDocuments docs={documents} services={services}/>}/>
      </div>
    )
  }
  