import lan from '../../translate';
import api from '../api/create';

function getAll(limit=10,page=1) {
    return api.get(`/flights?limit=${limit}&skip=${page}`)
}

function getFlightById(id) {
    return api.get(`/flights/${id}`)
}

function findForSelect(search) {
    return api.get(`/flights/findselect/${search}`)
}


function findAllLike(body,limit=1000,page=1) {
    return api.post(`/flights/find?limit=${limit}&skip=${page}`,body)
}

function findAllLikeSub(body,limit=1000,page=1) {
    return api.post(`/flights/find/findsub?limit=${limit}&skip=${page}`,body)
}

function findRangeDate(body,limit=1000,page=1) {
    return api.post(`/flights/find/rangedate?limit=${limit}&skip=${page}`,body)
}

function remove(id) {
    return api.delete(`/flights/${id}`)
}

function save(body) {
    return api.post(`/flights/`,body)
}

function update(body,id) {
    return api.patch(`/flights/${id}`,body)
}


function headers() {
    const headers = {
        no_flight:lan.flights_headers_noflight,
        airlineInfo:{headerName:lan.flights_headers_airline,keyObject:"name"},
        countryOrigin:{headerName:lan.flights_headers_origin,keyObject:"name"},
        countryDestiny:{headerName:lan.flights_headers_destination,keyObject:"name"},
    }

    return headers
}

function fields(setState) {
    const fields_init = {
        no_flight:{name:lan.flights_field_noflight, key:"no_flight", required: true,value:""},
        airline:{name:lan.flights_field_airline, key:"airline", required: true,value:""},
        origin:{name:lan.flights_headers_origin, key:"origin", required: true,value:""},
        destination:{name:lan.flights_field_destination, key:"destination", required: true,value:""},
        hour_start:{name:lan.flights_field_hourstart, key:"hour_start", required: true,value:""},
        hour_arrive:{name:lan.flights_field_hourarrive, key:"hour_arrive", required: true,value:""},
        duration:{name:lan.flights_field_duration, key:"duration", required: true,value:""},
    }
    setState(fields_init)
}

function btnTable() {
    const btn = {
        create:{name:lan.table_btn_flight_create, colorClass:"block px-3 py-2 text-sm font-semibold text-center text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" },
        update:{name:lan.table_btn_flight_delete, colorClass:"text-blue-primary   hover:text-indigo-900"},
        delete:{name:lan.table_btn_flight_update, colorClass:"text-red-800   hover:text-red-900"},
    }
    return btn
}



export {
    getAll,
    findAllLike,
    findRangeDate,
    fields,
    headers,
    btnTable,
    remove,
    save,
    update,
    findAllLikeSub,
    findForSelect,
    getFlightById
}
