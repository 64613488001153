
import { useContext, useEffect, useState } from 'react'
import { btnTable } from '../../services/collections/user'
import { TrashIcon,PencilSquareIcon, EyeIcon } from '@heroicons/react/20/solid'
import { Loading } from '../loading/loading'
import lan from '../../translate/index'
import * as Helpers from '../../helpers/helpers'
import ProfileContext from '../../context/contextProfile'
import Filters from '../filters/filters'
import CustomFilters from '../filters/customFilters'
import Modal from '../modal/modal'
import ModalShow from './modalShow'




export default  function Tablet({...item}) {
    const {user}  =  useContext(ProfileContext)
    const [open,setOpen] = useState(false)
    const [info,setInfo] = useState(false)

    // if(item.data && item.data.length===0 && item.data !==false){
    //     return <Loading show={item.data.length===0} w={20} h={20}/>
    // }


    // useEffect(()=>{
    //   console.log("dfsdfs");
    // },[Helpers.changeDataColor])

    function showInfoChoose(infomation) {
      setInfo(infomation)
      setOpen(true)
    }

  return (
    <div className="px-4 sm:px-6 lg:px-8" id='table-check'>
    <div className="sm:flex sm:items-center">
      <div className="sm:flex-auto">
        <h1 className="text-base font-semibold leading-6 text-gray-900 dark:text-white">{item.title}</h1>
        <p className="mt-2 text-sm text-gray-700 dark:text-gray-400">
          {item.subTitle}
        </p>
      </div>
      {item.btn&&item.btn.create&&Helpers.permissionForAction(user.roles,["admin","edit"])&&
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
                type="button"
                onClick={()=> item.createBtn()}
                className={item.btn.create.colorClass}
            >
                {item.btn.create.name}
            </button>
        </div>
      }
      
    </div>
    
    <div className="flow-root mt-8">
        <Filters search={item.search} searchMethod={item.searchMethod} searchRangeDate={item.searchRangeDate}/>
        <br/>
        <CustomFilters firstFilter={item.firstFilter} cusFilters={item.cusFilters} searchMethod={item.searchMethod}  data={item.data}/>
        <br/>
        {item.firstFilter&& <button className='block px-3 py-2 text-sm font-semibold text-center text-white bg-green-600 rounded-md shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600' onClick={()=> item.clearFilter()}>{lan.workflight_btn_filter_clear}</button>}
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        
          <table className="min-w-full divide-y divide-gray-300 table-responsive">
            {item.data === false&&
                <thead>
                    <br/>
                    <tr>
                        <th className='dark:text-white'>
                            {lan.table_result_search}
                        </th>
                    </tr>
                    
                </thead>

            }


          {item.data&&
            <thead>
            <tr>
            
                
                {item.headers&& Object.values(item.headers).map((data,i)=>
                    data && typeof data === 'object'?
                    <th 
                    scope="col" 
                    key={i}
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white">
                        {data.headerName}
                    </th>
                    :
                    <th 
                    scope="col" 
                    key={i}
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white">
                        {data}
                    </th>
                )}
                <th scope="col" className=" py-3.5 pl-3 pr-4 sm:pr-0">
                <select 
           className="block w-15   border-0 py-1.0 dark:text-white dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
           value={item.limit} onChange={(e)=>item.setLimit(parseInt(e.target.value))}>
             <option className='dark:bg-slate-900' value={10}>10</option>
             <option className='dark:bg-slate-900' value={20}>20</option>
             <option className='dark:bg-slate-900' value={50}>50</option>
             <option className='dark:bg-slate-900' value={100}>100</option>
        </select>
                <span className="sr-only">Accion</span>
                </th>
            </tr>
            </thead>
          }
            
            {item.data === false&&
                <tbody>
                    <tr >
                        <td className='dark:text-white'>
                            {lan.table_result_not_found}
                        </td>
                    </tr>
                </tbody>
            }
            {item.data&&
                <tbody className="divide-y divide-gray-200">
                {item.data.length>0&&item.data.map((data,i)=>
                    <tr key={i} className=''>
                        
                        { item.headers&&Object.keys(item.headers).map((headers,x)=>
                       
                            <td onClick={()=>showInfoChoose(data)} data-label={item.headers[headers].headerName?item.headers[headers].headerName +":":item.headers[headers] +":"} key={x}
                            className={ "cursor-pointer	 "+ Helpers.changeDataColor(data[headers],item.headers[headers].colorChoose)}>
                                {Helpers.formatToCurrency( Helpers.changeFormatData(data[headers],headers,item.headers),item.headers[headers])?.slice(0,20) }
                                {Helpers.formatToCurrency( Helpers.changeFormatData(data[headers],headers,item.headers),item.headers[headers])?.length>20&&"..."}
                            </td>
                        )} 

                        <td data-label={"Accion"} className='flex justify-center w-8 gap-2 align-items text-grey-900 dark:text-white '>
                            {item.btn&&item.btn.update&&Helpers.permissionForAction(user.roles,["admin","edit"])&&
                            <a onClick={()=> item.editBtn(data)}  className={item.btn.update.colorClass + ' cursor-pointer'}>
                                <PencilSquareIcon className='w-6 '/>
                            </a>
                         
                            }

                            {item.btn&&item.btn.delete&&Helpers.permissionForAction(user.roles,["admin","edit"])&&
                            <a onClick={()=>item.deleteBtn(data._id)}   className={item.btn.delete.colorClass + ' cursor-pointer' }>
                                <TrashIcon className='w-6'/>
                            </a>
                        
                            }
                            
                          {item.btn&&item.btn.checkbox&&Helpers.permissionForAction(user.roles,["admin","edit"])&&
                            <input
                            id={data._id}
                            aria-describedby="comments-description"
                            name={data.ident}
                            type="checkbox"
                            checked={document.getElementById(data.ident)?.checked}
                            onClick={(e)=>item.checkBtn(e,data)}
                            className={item.btn.checkbox.colorClass}
                          />
                        
                            }
                        </td>

                    
                    </tr>
                )}
            
            </tbody>
            }
            
          </table>
          
        </div>
      </div>
    </div>
    <Modal open={open} setOpen={setOpen}  body={<ModalShow info={info}/>}/>

  </div>
  )
}





