import React, { useState,useCallback } from 'react'
import lan from '../../translate';
const data = {
    dateOne:"",
    dateTwo:""
}

export default function TwoDate({searchRangeDate}) {

    
    const setBodyData = useCallback(event => {
        const { name, value } = event.target;
        data[name] =value
        searchRangeDate("dateCreate",data)
    });
  return (
    <div className=" sm:col-span-3">
        <div>
            <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                    {lan.filter_rangedate}:
            </label>
        </div>
        <div className='flex'>
          
            <input
            type="date"
            name='dateOne'
            onChange={setBodyData}
            className="block w-36   border-0 py-1.5 dark:text-white dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
            />

            <input
            type="date"
            name='dateTwo'
            onChange={setBodyData}
            className="block w-36   border-0 py-1.5 dark:text-white dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
            />
        </div>
         
                
    </div>
  )
}
