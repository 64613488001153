import React, { useEffect, useState, useContext,useCallback } from 'react'
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import {navigation, subNavigations, othersNavigation} from '../../routers'
import * as Helpers from '../../helpers/helpers'
import AlertContext from '../../context/contextAlert'
import { useNavigate , useLocation, useLoaderData } from "react-router-dom"
import ServicesContext from '../../context/contextServices'
import lan from '../../translate'
import { country } from '../../helpers/country'
import ProfileContext from '../../context/contextProfile'
import moment from 'moment'
import Select from '../../components/select/select'
import Combobox from '../../components/combobox/combobox'

const initialState = {
    no_flight:"",
    airline:{
      name:""
    },
    origin:{
      name:""
    },
    destination:{
      name:""
    },
    hour_start:0, 
    hour_arrive:"",
    duration:"",
    user_id:"",
    dateCreate:moment().format('DD/MM/YYYY'),
    hourCreate:moment().hours() + ":"+ moment().minutes()

}

const fieldRequired = {
    no_flight:true,
    airline:true,
    origin:true,
    destination:true,
    hour_start:true, 
    hour_arrive:true,
    duration:true,
}



export const FlightCreate = () => {
    const services =  useContext(ServicesContext)
    const MySwal = useContext(AlertContext)
    const {user}  =  useContext(ProfileContext)
    const location = useLocation();
    const navigate = useNavigate();
    const [body,setBody] = useState(initialState)
    const [idUser, setIdUser] = useState(0)
    const [isUpdate, setIsUpdate] = useState(false)
    const [required,setRequired] = useState(fieldRequired)

    const setBodyData = useCallback(event => {
        const { name, value } = event.target;
        setBody({ ...body, [name]: value });
        console.log('value', value,name)
        if(!value){
            setRequired({ ...required, [name]: lan.user_alert_fielderror })
        }else{
            setRequired({ ...required, [name]: "" })
        }
        console.log('required', required)
    });

    const setBodyDataSelect = useCallback(event => {
        const { name, value } = event.target;
        setBody({ ...body, [name]: value });
        if(!value){
            setRequired({ ...required, [name]: lan.user_alert_fielderror })
        }else{
            setRequired({ ...required, [name]: "" })
        }
    });





    useEffect(()=>{
        loadData(location.state)
        getAllValue()
        
    },[])


    async function getAllValue() {
      const dataServices = await services.Airports.getAllFull()
      const list = dataServices.data
      let allItem = list.map((data,i)=>{
          return {value:data._id,label:data.name}
      })
      
  }


    async function save() {
      
        if(Helpers.checkIsRequired(required)){

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "warning",
                text:lan.user_alert_field_empty
              })
        }

        if(!Helpers.checkIsRequired(required)){
          console.log('body', body)

            body.user_id = user._id
            body.duration=body.duration.toUpperCase()
            const result = await services.Flights.save(body)
            if(result.status ===201){
                MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "success",
                    text:lan.alert_create_generic
                  })
                return navigate(-1)
            }

            if(result.response && result.response.data.statusCode === 400){
                return  MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "error",
                    title:lan.alert_errorcreate_generic,
                    text: "Error: "+result.response.data.message
                    })
            }

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "error",
                text:lan.user_alert_error500
                })
                
        }
    }

    async function update() {
        delete body.create
        delete body.__v
        delete body._id
        delete body.dateCreate
        delete body.hourCreate
        delete body.userInfo
        delete body.countryOrigin
        delete body.countryDestiny
        delete body.airlineInfo
        
        
        if(Helpers.checkIsRequired(required)){

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "warning",
                text:lan.user_alert_field_empty
              })
        }

        if(!Helpers.checkIsRequired(required) ){
          console.log('body', body)
            body.duration=body.duration.toUpperCase()
           
            const result = await services.Flights.update(body,idUser)
            if(result.status ===200){
                MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "success",
                    text:lan.alert_update_generic
                  })
                  navigate(-1)
                return 
            }

            if(result.response && result.response.data.statusCode === 400){
                return  MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "error",
                    title:lan.alert_errorupdate_generic,
                    text: "Error: "+result.response.data.message
                    })
            }

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "error",
                text:lan.user_alert_error500
                })
        }
        
        
    }


    function loadData(state) {
        if(state){
         setRequired({
          no_flight:'',
          airline:'',
          origin:'',
          destination:'',
          hour_start:'', 
          hour_arrive:'',
          duration:'',
         })
         setBody(state)
         setIdUser(state._id)
         setIsUpdate(true)
         console.log('body', body)
        }
        
    }

  return (
    <div className="space-y-10 divide-y divide-gray-900/10">
    <div className="grid grid-cols-1 pt-10 gap-x-8 gap-y-8 md:grid-cols-3">
        
      <div className="px-4 sm:px-0">
        <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">{lan.flight_new_title}</h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">{lan.flight_new_subtitle}</p>
      </div>

      <div className="bg-white shadow-sm dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-slate-800 ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
        <div className="px-4 py-6 sm:p-8">
          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
               {lan.flights_field_noflight} <span className={required.no_flight?"text-red-700":""}>*</span>
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name={"no_flight"}
                  id={"no_flight"}
                  value={body.no_flight}
                  onChange={setBodyData}
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                />
              </div>
              <span className='text-xs text-red-700'>{required.no_flight?required.no_flight:""}</span>
            </div>

            
            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
               {lan.flights_field_airline} <span className={required.airline?"text-red-700":""}>*</span>
              </label>
              <Combobox
              nameDefault={"airline"}
              labels={["name","iata"]}
              services={services.Airlines.findAllLike} 
              value={body.airlineInfo?.name}
              method={setBodyData}/>

              <span className='text-xs text-red-700'>{required.airline?required.airline:""}</span>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
               {lan.flights_field_origin} <span className={required.origin?"text-red-700":""}>*</span>
              </label>
              <Combobox 
              nameDefault={"origin"}
              services={services.Airports.findAllLike} 
              labels={["iata","name"]}
              value={body.countryOrigin?.name}
              method={setBodyData}/>
              <span className='text-xs text-red-700'>{required.origin?required.origin:""}</span>
              
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
               {lan.flights_field_destination} <span className={required.destination?"text-red-700":""}>*</span>
              </label>
              <Combobox 
              nameDefault={"destination"}
              services={services.Airports.findAllLike} 
              labels={["iata","name"]}
              value={body.countryDestiny?.name}
              method={setBodyData}/>
              <span className='text-xs text-red-700'>{required.destination?required.destination:""}</span>
              
            </div>

            <div className="sm:col-span-3">
            <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
               {lan.flights_field_hourstart} <span className={required.hour_start?"text-red-700":""}>*</span>
              </label>
              <div className="mt-2">
                <input
                  type="time"
                  name={"hour_start"}
                  id={"hour_start"}
                  value={body.hour_start}
                  onChange={setBodyData}
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                />
              </div>
              <span className='text-xs text-red-700'>{required.hour_start?required.hour_start:""}</span>
            </div>

            <div className="sm:col-span-3">
            <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
               {lan.flights_field_hourarrive} <span className={required.hour_arrive?"text-red-700":""}>*</span>
              </label>
              <div className="mt-2">
                <input
                  type="time"
                  name={"hour_arrive"}
                  id={"hour_arrive"}
                  value={body.hour_arrive}
                  onChange={setBodyData}
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                />
              </div>
              <span className='text-xs text-red-700'>{required.hour_arrive?required.hour_arrive:""}</span>
            </div>

            <div className="sm:col-span-3">
            <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
               {lan.flights_field_duration} <span className={required.duration?"text-red-700":""}>*</span>
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name={"duration"}
                  id={"duration"}
                  value={body.duration}
                  onChange={setBodyData}
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                />
              </div>
              <span className='text-xs text-red-700'>{required.duration?required.duration:""}</span>
            </div>

            



 
            
          </div>
        </div>
       
      </div>
    </div>

    

   

    <div className="flex items-center justify-end px-4 py-4 border-t col-span-full gap-x-6 border-gray-900/10 dark:border-white/10 sm:px-8">
          <button type="button" className="text-sm font-semibold leading-6 text-blue-primary" onClick={()=>navigate(-1)}>
            {lan.user_button_cancel}
          </button>
          <button
            type="submit"
            onClick={()=> isUpdate? update() : save()}
            className="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-blue-primary hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {lan.user_button_save}
          </button>
        </div>
  </div>
  )
}
