import React from 'react'

export default function ModalShow({info}) {


    function objectInfo(infoObj,name) {
        return infoObj._id&&Object.keys(infoObj).map((data,i)=>{
        
            return data !=="password"&& <div className="sm:col-span-2">
            <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
           {name} - {data} 
            </label>
            
            <div className="mt-2">
                <input
                type={"text"}
                name={infoObj[data]}
                id={infoObj[data]}
                disabled
                value={typeof infoObj[data] === 'object' && infoObj[data] !== null ? JSON.stringify(infoObj[data]):infoObj[data]}
                autoComplete="given-name"
                className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                />
            </div>
            </div>
        })
    }
  return (
    <div className='grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6'>
        {info._id&&Object.keys(info).map((data,i)=>
        typeof info[data] === 'object' && info[data] !== null  ?
            objectInfo(info[data],data )
        :
        <div className="sm:col-span-2">
        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
        {data} 
        </label>
        
        <div className="mt-2">
            <input
            type={"text"}
            name={info[data]}
            id={info[data]}
            disabled
            value={typeof info[data] === 'object' && info[data] !== null ? JSON.stringify(info[data]):info[data]}
            autoComplete="given-name"
            className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
            />
        </div>
        </div>
        
        )}
    </div>
  )
}
