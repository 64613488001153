/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useContext, useEffect, useState } from 'react'
import { Dialog, Menu, Transition,Disclosure } from '@headlessui/react'
import {
  Bars3Icon,
  BellIcon,
  Cog6ToothIcon,
  XMarkIcon,
  ChevronRightIcon
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import {navigation,userNavigation,subNavigations, othersNavigation} from '../../routers'
import * as Helpers from '../../helpers/helpers'
import ProfileContext from '../../context/contextProfile'
import ServicesContext from '../../context/contextServices'
import lan from '../../translate'
const Logo = require("../../images/logo.png")
const Logo_white = require("../../images/logo_white.png")



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}





function classAcitveIcon(rute){
  return rute === window.location.pathname ? 
  'text-white hover:text-white hover:bg-blue-300 dark:hover:bg-slate-700 dark:bg-slate-800 bg-blue-400' :
  'text-white hover:text-white hover:bg-blue-300 dark:hover:bg-slate-700'
}

const  initialState = {
      navigation:[],
      othersNavigation:[],
      subNavigations:[],
      userNavigation:[]
}
export default  function Navigations({children}) {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const {setUser, user, getUserInfo} =  useContext(ProfileContext)
  const [navigationRutes, setnavigationRutes] = useState(initialState)
  const Services =  useContext(ServicesContext)

  async function logOut() {
    const result = Services.Users.logOut()
  }

  

  useEffect( ()=>{
    Helpers.permissionForRute(navigationRutes,setnavigationRutes)
  },[])

  async function changeLanguage(e){
    const lan = e.target.value
    const results = await Services.File.getFileOneLang(lan)
    localStorage.setItem("lan",lan)
    localStorage.setItem("lan-t",JSON.stringify(results.data))
     
      window.location.reload()
  }

  if(window.location.pathname === '/login'){
    return(<></>)
  }

  if(window.location.pathname !== '/login'){
    return (
      <>
     
        <div>
          
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-900/80" />
              </Transition.Child>
  
              <div className="fixed inset-0 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative flex flex-1 w-full max-w-xs mr-16">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 flex justify-center w-16 pt-5 left-full">
                        <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                          <span className="sr-only">Close sidebar</span>
                          <XMarkIcon className="w-6 h-6 text-white" aria-hidden="true" />
                        </button>
                      </div>
                    </Transition.Child>
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex flex-col px-6 pb-4 overflow-y-auto bg-blue-800 grow gap-y-5">
                      <div className="flex items-center justify-center h-16 shrink-0">
                      <img
                        className="w-auto h-12"
                        src={Logo_white}
                        alt="TROLBIS SRL"
                      />
                      </div>
                      <nav className="flex flex-col flex-1">
                        <ul role="list" className="flex flex-col flex-1 gap-y-7">
                          <li>
                            <ul role="list" className="-mx-2 space-y-1">
                              {navigationRutes.navigation.length>0&& navigationRutes.navigation.map((item) => (
                                !item.hidden &&
                                
                                <li key={item.name}>
                                {!item.children ? (
                                  <a
                                    href={item.href}
                                    className={
                                      classAcitveIcon(item.href) +
                                      ' group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                    }
                                  >
                                    {item.name}
                                  </a>
                                ) : (
                                  <Disclosure as="div">
                                    {({ open }) => (
                                      <>
                                        <Disclosure.Button
                                          className={
                                            classAcitveIcon(item.href) +
                                            ' group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full'
                                          }
                                        >
                                          <ChevronRightIcon
                                            className={classNames(
                                              open ? 'rotate-90 text-gray-500' : 'text-gray-400',
                                              'h-5 w-5 shrink-0'
                                            )}
                                            aria-hidden="true"
                                          />
                                          {item.name}
                                        </Disclosure.Button>
                                        <Disclosure.Panel as="ul" className="px-2 mt-1">
                                          {item.children.map((subItem) => (
                                            <li key={subItem.name}>
                                              <Disclosure.Button
                                                as="a"
                                                href={subItem.href}
                                                className={
                                                  classAcitveIcon(subItem.href) +
                                                  ' group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                                }
                                              >
                                                {subItem.name}
                                              </Disclosure.Button>
                                            </li>
                                          ))}
                                        </Disclosure.Panel>
                                      </>
                                    )}
                                  </Disclosure>
                                )}
                              </li>
                              ))}
                            </ul>
                          </li>
                          <li>
                            <div className="text-xs font-semibold leading-6 text-indigo-200">{lan.navbar_settings}</div>
                            <ul role="list" className="mt-2 -mx-2 space-y-1">
                              {navigationRutes.subNavigations.map((team) => (
                                !team.hidden &&
                                <li key={team.name} >
                                  <a
                                    href={team.href}
                                    className={
                                      classAcitveIcon(team.href) +
                                      ' group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full'
                                    }
                                  >
                                    <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-blue-primary bg-white text-[0.625rem] font-medium text-white">
                                      {team.initial}
                                    </span>
                                    <span className="truncate">{team.name}</span>
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </li>
                          <li className="mt-auto">
                            <a
                              href="#"
                              className="flex p-2 -mx-2 text-sm font-semibold leading-6 text-indigo-200 rounded-md group gap-x-3 hover:bg-indigo-700 hover:text-white"
                            >
                              <Cog6ToothIcon
                                className="w-6 h-6 text-indigo-200 shrink-0 group-hover:text-white"
                                aria-hidden="true"
                              />
                              {lan.navbar_settings_bottom}
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
  
          {/* Static sidebar for desktop */}
          <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex flex-col px-6 pb-4 overflow-y-auto grow gap-y-5 bg-blue-primary">
              <div className="flex items-center justify-center h-16 shrink-0">
              <img
                className="w-auto h-12"
                src={Logo_white}
                alt="TROLBIS SRL"
              />
              </div>
              <nav className="flex flex-col flex-1">
                <ul role="list" className="flex flex-col flex-1 gap-y-7">
                  <li>
                    <ul role="list" className="-mx-2 space-y-1">
                      {navigationRutes.navigation.length>0&& navigationRutes.navigation.map((item) => (
                        !item.hidden &&
                        <li key={item.name}>
                                {!item.children ? (
                                  <a
                                    href={item.href}
                                    className={
                                      classAcitveIcon(item.href) +
                                      ' group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full'
                                    }
                                  >
                                    {item.name}
                                  </a>
                                ) : (
                                  <Disclosure as="div">
                                    {({ open }) => (
                                      <>
                                        <Disclosure.Button
                                           className={
                                            classAcitveIcon(item.href) +
                                            ' group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full'
                                          }
                                        >
                                          <ChevronRightIcon
                                            className={classNames(
                                              open ? 'rotate-90 text-gray-500' : 'text-gray-400',
                                              'h-5 w-5 shrink-0'
                                            )}
                                            aria-hidden="true"
                                          />
                                          {item.name}
                                        </Disclosure.Button>
                                        <Disclosure.Panel as="ul" className="px-2 mt-1">
                                          {item.children.map((subItem) => (
                                            <li key={subItem.name}>
                                              <Disclosure.Button
                                                as="a"
                                                href={subItem.href}
                                                className={
                                                  classAcitveIcon(subItem.href) +
                                                  ' group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                                }
                                              >
                                                {subItem.name}
                                              </Disclosure.Button>
                                            </li>
                                          ))}
                                        </Disclosure.Panel>
                                      </>
                                    )}
                                  </Disclosure>
                                )}
                              </li>
                      ))}
                    </ul>
                  </li>
                  <li>
                    <div className="text-xs font-semibold leading-6 text-indigo-200">{lan.navbar_settings}</div>
                    <ul role="list" className="mt-2 -mx-2 space-y-1">
                      {navigationRutes.subNavigations.map((team) => (
                        !team.hidden &&
                        <li key={team.name}>
                          <a
                            href={team.href}
                            className={
                              classAcitveIcon(team.href) +
                              ' group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                            }
                          >
                            <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-blue-primary bg-white text-[0.625rem] font-medium text-blue-primary">
                              {team.initial}
                            </span>
                            <span className="truncate">{team.name}</span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </li>
                  <li className="mt-auto">
                    <a
                      href="#"
                      className="flex p-2 -mx-2 text-sm font-semibold leading-6 text-white rounded-md group gap-x-3 dark:text-white hover:bg-indigo-700 hover:text-white"
                    >
                      <Cog6ToothIcon
                        className="w-6 h-6 text-white shrink-0 group-hover:text-white dark:text-white"
                        aria-hidden="true"
                      />
                      {lan.navbar_settings_bottom}
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
  
          <div className="lg:pl-72">
            <div className="sticky top-0 z-40 flex items-center h-16 px-4 bg-white border-b border-gray-200 shadow-sm shrink-0 gap-x-4 dark:border-slate-700 dark:bg-slate-700 sm:gap-x-6 sm:px-6 lg:px-8">
              <button type="button" className="-m-2.5 p-2.5 text-gray-700 dark:text-white lg:hidden" onClick={() => setSidebarOpen(true)}>
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="w-6 h-6" aria-hidden="true" />
              </button>
  
              {/* Separator */}
              <div className="w-px h-6 bg-gray-900/10 lg:hidden" aria-hidden="true" />
  
              <div className="flex self-stretch flex-1 gap-x-4 lg:gap-x-6">
                <form className="relative flex flex-1" action="#" method="GET">
                  <label htmlFor="search-field" className="sr-only">
                    {lan.navbar_search}
                  </label>
                  <MagnifyingGlassIcon
                    className="absolute inset-y-0 left-0 w-5 h-full text-gray-400 pointer-events-none dark:text-white"
                    aria-hidden="true"
                  />
                  <input
                    id="search-field"
                    className="block w-full h-full py-0 pl-8 pr-0 text-gray-900 border-0 dark:bg-slate-700 placeholder:text-gray-400 dark:placeholder:dark:text-white focus:ring-0 sm:text-sm"
                    placeholder={lan.navbar_search}
                    type="search"
                    name="search"
                  />
                </form>
                <div className="flex items-center gap-x-4 lg:gap-x-6">
                  <select onChange={e => changeLanguage(e)} value={localStorage.getItem("lan")} className='block  rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6'>
                    <option className='dark:bg-slate-900' value={"es"}>es - español</option>
                    <option className='dark:bg-slate-900' value={"en"}>en - ingles</option>
                    <option className='dark:bg-slate-900' value={"pt"}>pt - portuges</option>
                  </select>

                  <button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="w-6 h-6 dark:text-white" aria-hidden="true" />
                  </button>
  
                  {/* Separator */}
                  <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true" />
                 
                  
  
                 
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative">
                    <Menu.Button className="-m-1.5 flex items-center p-1.5">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="w-8 h-8 rounded-full bg-gray-50"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      />
                      <span className="hidden lg:flex lg:items-center">
                        <span className="ml-4 text-sm font-semibold leading-6 text-gray-900 dark:text-white" aria-hidden="true">
                          {user?.name}
                        </span>
                        <ChevronDownIcon className="w-5 h-5 ml-2 text-gray-400 dark:text-white" aria-hidden="true" />
                      </span>
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white dark:bg-slate-700 dark:text-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                        {navigationRutes.userNavigation.map((item) => (
                          !item.hidden &&
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <a
                                href={item.href}
                                className={
                                  classAcitveIcon(item.href) +
                                  ' group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                }
                              >
                                {item.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                        <Menu.Item key={"logout"}>
                           
                              <button
                                onClick={()=>logOut()}
                                className={
                                  
                                  'text-slate-950 dark:text-white hover:text-white hover:bg-blue-300 dark:hover:bg-slate-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                }
                              >
                                {lan.navbar_logout}
                              </button>
                            
                          </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
  
            <main className="py-10">
              <div className="px-4 sm:px-6 lg:px-8">
                  {children}</div>
            </main>
          </div>
        </div>
      </>
    )
  }
  
  
}


