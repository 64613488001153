
import { useContext, useEffect, useState } from 'react'
import lan from '../../translate/index'
import Tablet from '../../components/tablet/tablet'
import ServicesContext from '../../context/contextServices'
import AlertContext from '../../context/contextAlert'
import { useNavigate } from "react-router-dom"
import ProfileContext from '../../context/contextProfile'
import moment from 'moment'
import Paginations from '../../components/pagination/paginations'



let dataInitial = {
    results:[],
    total:0,
    pageCurrent:1,
    limit:10,
    pageNext:2,
    pagesTotal:0
}
export default  function Users() {
  const services =  useContext(ServicesContext)
  const {user}  =  useContext(ProfileContext)
  const MySwal = useContext(AlertContext)
  const navigate = useNavigate();
  const [data,setData] = useState(dataInitial)
  const [dataReload,setDataReload] = useState([])
  const [headers,setHeaders] = useState([])
  const [btn,setBtn] = useState([])
  const [limit, setLimit] = useState(10)

 async function getDataAll() {
     const dataServices = await services.Users.getAllUsers()
     const headerServices = await services.Users.Headers()
     const btnServices = await services.Users.btnTable()
     if(dataServices.data){
         setData(dataServices.data)
         setDataReload(dataServices.data)
         setHeaders(headerServices)
         setBtn(btnServices)
    }
    
 }

 async function findAllLike(key,value) {


     if(key && value){
         let bodyFind = {
            key:key,
            value:value
         }
        const resul = await services.Users.findAllLike(bodyFind)
        if(resul.status === 201 && resul.data.results.length>0){
            setData(resul.data)
        }
        if(resul.status === 201 && resul.data.results.length===0){
            setData(false)
        }
     }else{
        setData(dataReload)
     }
    
     
 }

 async function findRangeDate(key,body) {

    if(key && body.dateOne && body.dateTwo){
        let bodyFind = {
           key:key,
           dateOne:moment(body.dateOne,'YYYY-MM-DD').format('DD/MM/YYYY') ,
           dateTwo:moment(body.dateTwo,'YYYY-MM-DD').format('DD/MM/YYYY') 
        }
       const resul = await services.Users.findRangeDate(bodyFind)
       if(resul.status === 201 && resul.data.results.length>0){
           setData(resul.data)
       }
       if(resul.status === 201 && resul.data.results.length===0){
           setData(false)
       }
    }else{
       setData(dataReload)
    }
 }

 function redirectNew() {
    navigate("/users/new")
 }
 function editUser(data) {
    navigate("/users/new",{state:data})
 }

 async function deleteUser(id) {
    MySwal.fire({
        title: lan.user_alert_deleteuser,
        text: lan.user_alert_deleteuser_sub,
        icon: 'warning',
        confirmButtonColor: "#db292f",
        confirmButtonText: "Si",
        showCancelButton: true,
        cancelButtonText: 'No'
     }).then(async function(btn){
        // function when confirm button clicked
        if(btn.isConfirmed){
            const result = await services.Users.deleteUser(id)
            if(result.data&&result.data.ok){
                MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "success",
                    text:lan.user_alert_deleteuser_success
                  })
                  getDataAll()
            }

            if(!result.data|| !result.data.ok){
                MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "error",
                    text:lan.user_alert_deleteuser_error
                  })
            }
        }

        
        
     });     
    
    
 }

 async function changePages(skip) {
    const dataServices = await services.Users.getAllUsers(10,skip)
    setData(dataServices.data)
 }

 async function setLimitFunc(limitChange){
    setLimit(limitChange)
    const dataServices = await services.Users.getAllUsers(limitChange,1)
    setData(dataServices.data)
  }


  useEffect(()=>{
    getDataAll()
  },[])

  
  return (
      <div>
        <Tablet 
        filters={true}
        searchMethod={findAllLike}
        search={{fullName:"Nombres",email:"Correo"}}
        searchRangeDate={findRangeDate}
        createBtn={redirectNew}
        limit={limit}
        setLimit={setLimitFunc}
        deleteBtn={deleteUser}
        editBtn={editUser}
        data={data.results} 
        headers={headers}
        btn={btn}
        title={lan.users}
        subTitle={lan.user_subtitle}/>
        <Paginations data={data} changePage={changePages}/>
      </div>
   
  )
}
