import React from 'react'
import Search from './search'
import OneDate from './onedate'
import TwoDate from './twodate'





export default function Filters({search=false,searchMethod=false,searchRangeDate=false}) {
  return (
    <div className='flex flex-wrap w-full gap-12'>
           {searchMethod&&(<Search searchValue={search} searchMethod={searchMethod}/>)} 
           {searchMethod&&(<OneDate searchMethod={searchMethod}/>)} 
           {searchRangeDate&&(<TwoDate searchRangeDate={searchRangeDate}/>)}
           
    </div>
  )
}
