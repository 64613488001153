import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Label } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react'
import * as Helpers from '../../helpers/helpers'

const people = [
    { id: 1, name: 'Leslie Alexander' },
    // More users...
  ]

export default function ComboSelect({value='',nameDefault,method=null, allDataMethod=null,services,labels=[]}) {
    const [query, setQuery] = useState('')
    const [selectedPerson, setSelectedPerson] = useState(null)
    const [items, setItems] = useState([])

  const filterItems =
    query === ''
      ? items
      : items.filter((i) => {
          return i[labels[0]].toLowerCase().includes(query.toLowerCase()) 
        })

     async function getData(e) {
       console.log('labels[0]', labels[0])
        const dataServices =  await services({key:labels[0],value:e.target.value})
        if(dataServices.data){
            setItems(dataServices.data.results)
            console.log('items,allItem', items,dataServices.data)
        }
    }

  return (
    <Combobox
      as="div"
      value={selectedPerson}
      onChange={(i) => {
        setQuery('')
        setSelectedPerson(i)
        console.log('i', i,nameDefault)
        method&&method({target:{name:nameDefault,value:i?i._id:""}})
        allDataMethod&&allDataMethod(i,nameDefault)
      }}
    >
      <div className="relative mt-2">
        <ComboboxInput
          className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
          onChange={(event) => getData(event)}
          onBlur={() => setQuery('')}
          displayValue={(i) => i?(i[labels[0]]?i[labels[0]]:value):value}
        />
        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none">
          <ChevronUpDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
        </ComboboxButton>

        {filterItems.length > 0 && (
          <ComboboxOptions className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg dark:ring-inset max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filterItems.map((i) => (
              <ComboboxOption
                key={i._id}
                value={i}
                className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
              >
                <span className="block truncate group-data-[selected]:font-semibold">{
                  Helpers.getNestedProperty( i,labels)
                }</span>

                <span className="absolute inset-y-0 right-0 hidden items-center pr-4 text-indigo-600 group-data-[selected]:flex group-data-[focus]:text-white">
                  <CheckIcon className="w-5 h-5" aria-hidden="true" />
                </span>
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        )}
      </div>
    </Combobox>
  )
}
