import { useContext, useEffect, useState } from 'react'
import lan from '../../translate/index'
import Tablet from '../../components/tablet/tablet'
import ServicesContext from '../../context/contextServices'
import AlertContext from '../../context/contextAlert'
import { useNavigate } from "react-router-dom"
import ProfileContext from '../../context/contextProfile'
import moment from 'moment'
import Paginations from '../../components/pagination/paginations'
import {removeRepeat} from '../../helpers/helpers'




let dataInitial = {
    results:[],
    total:0,
    pageCurrent:1,
    limit:10,
    pageNext:2,
    pagesTotal:0
  }
  
  export default function TourBookings() {
      const services =  useContext(ServicesContext)
      const {user}  =  useContext(ProfileContext)
      const MySwal = useContext(AlertContext)
      const navigate = useNavigate();
      const [data,setData] = useState(dataInitial)
      const [dataReload,setDataReload] = useState([])
      const [headers,setHeaders] = useState([])
      const [btn,setBtn] = useState([])
      const [cusFilters,setCusFilters] = useState([])
      const [firsFilter,setFirsFilter] = useState(false)
      const [allFilters, setAllFilters] = useState([])
      const [limit, setLimit] = useState(10)
  
  
      async function getDataAll() {
          const dataServices = await services.Tour.getAllBookings(limit)
          const headerServices = await services.Tour.headersBookings()
          const btnServices = await services.Tour.btnTable()
          if(dataServices.data){
              setData(dataServices.data)
              setDataReload(dataServices.data)
              setHeaders(headerServices)
              setBtn(btnServices)
          }
         
      }
  
      function redirectNew() {
          navigate("/worktours/bookings/new")
      }
  
      function edit(data) {
            navigate("/worktours/bookings/new",{state:{data:data,isShow:false}})
      }

      function clearFilter() {
        setFirsFilter(false)
        setData(dataReload)
        setAllFilters([])

      }


      
      async function findAllLike(key,value) {

        let allFilterArray = allFilters
        let isDuplicate = removeRepeat(allFilterArray,{[key]:{"$regex": value, "$options" : "i"}})
        if(!isDuplicate){
          allFilterArray.push({[key]:{"$regex": value, "$options" : "i"}})
        }
        const resul = await services.Tour.findMultipleFiltersPackage({filter:allFilterArray})
        
        if(resul.status === 201 && resul.data.results.length>0){
          setData(resul.data)
        }
        if(resul.status === 201 && resul.data.results.length===0){
            setData(false)
        }
        setAllFilters(allFilterArray)
        setFirsFilter(true)
        
        
        
      }
  
  
      async function findRangeDate(key,body) {
          if(key && body.dateOne && body.dateTwo){
            let bodyFind = {
               key:key,
               dateOne:moment(body.dateOne,'YYYY-MM-DD').format('DD/MM/YYYY') ,
               dateTwo:moment(body.dateTwo,'YYYY-MM-DD').format('DD/MM/YYYY') 
            }
           const resul = await services.Tour.findRangeDate(bodyFind)
           if(resul.status === 201 && resul.data.results.length>0){
               setData(resul.data)
           }
           if(resul.status === 201 && resul.data.results.length===0){
               setData(false)
           }
        }else{
           setData(dataReload)
        }
      }
  
  
      function remove(id) {
          MySwal.fire({
              title: lan.flight_alert_delete,
              text: lan.user_alert_deleteuser_sub,
              icon: 'warning',
              confirmButtonColor: "#db292f",
              confirmButtonText: "Si",
              showCancelButton: true,
              cancelButtonText: 'No'
           }).then(async function(btn){
              // function when confirm button clicked
              if(btn.isConfirmed){
                  const result = await services.Tour.removeBookings(id)
                  if(result.data&&result.data.ok){
                      MySwal.fire({
                          confirmButtonColor: '#1EA0D5',
                          icon: "success",
                          text:lan.flight_alert_deletesuccess
                        })
                        getDataAll()
                  }
      
                  if(!result.data|| !result.data.ok){
                      MySwal.fire({
                          confirmButtonColor: '#1EA0D5',
                          icon: "error",
                          text:lan.flight_alert_deleteerror
                        })
                  }
              }
      
              
              
           });     
      }
  
      async function changePages(skip) {
          const dataServices = await services.Tour.getAllBookings(limit,skip)
          setData(dataServices.data)
      }

      function clearFilter() {
        setFirsFilter(false)
        setData(dataReload)
        setAllFilters([])

      }

      async function setLimitFunc(limitChange){
        setLimit(limitChange)
        const dataServices = await services.Tour.getAllBookings(limitChange,1)
        setData(dataServices.data)
      }
  
      useEffect(()=>{
          getDataAll()
        },[])
  
      
      
    return (
      <div>
        <Tablet 
          filters={true}
          searchMethod={findAllLike}
          //searchRangeDate={findRangeDate}
          clearFilter={clearFilter}
          firstFilter={firsFilter}
          limit={limit}
          setLimit={setLimitFunc}
          search={
            {
              "name":lan.tour_field_name,
              "date_booking":lan.tour_field_date_start,
              "price_total":lan.tour_field_price_sell,
              "description":lan.tour_field_description,
              "dateCreate":lan.tour_date_Create_bookings,
            }
          }
          createBtn={redirectNew}
          deleteBtn={remove}
          editBtn={edit}
          data={data.results} 
          headers={headers}
          btn={btn}
          title={lan.tour_reservation_title}
          subTitle={lan.tour_booking_information}/>
  
        <Paginations data={data} changePage={changePages}/>
      </div>
    )
  }
  