import { useContext, useEffect, useState } from 'react'
import ServicesContext from '../../context/contextServices'

export default function Translate() {
    const services =  useContext(ServicesContext)
    const [allWords,setAllWords] = useState([])
    async function getFields() {
        let aLan = []
        const dataServices = await services.File.getFileAllLang()
        console.log('dataServices :>> ', dataServices);
        setAllWords(dataServices.data)
    }


    function addWord(e, position, lang) {
        const updatedWords = [...allWords];
        updatedWords[position] = {
            ...updatedWords[position],
            [lang]: e.target.value
        };
        setAllWords(updatedWords);
    }

    async function saveLang(key,lan,e) {
        const dataServices = await services.File.saveWord({
            key:key,
            lan:lan,
            value:e.target.value
        })
    }

    useEffect(()=>{
        if(allWords.length===0){
            getFields()
        }
        
    },[allWords])



    console.log('aLan :>> ', allWords.length);
  return (
    <div>
        <div className='flex justify-center text-slate-900 dark:text-white'>
            <h1>TRADUCCIONES DE PALABRAS</h1>
        </div>
        <br/>
        <div>
            {allWords.length>0&&allWords.map((data,i)=>
            
                <div>
                    <label htmlFor={data.key} className="block text-lg font-medium font-bold leading-6 text-blue-primary dark:text-blue-primary ">
                        {data.key.toUpperCase()}
                    </label>
                    <div className='grid w-full gap-x-6 gap-y-8 sm:grid-cols-9'>
                    
                    <div className="sm:col-span-3">
                        <label htmlFor={data.key} className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                        Español
                        </label>
                        <input
                            type={"text"}
                            name={data.key+":"+"es"}
                            id={data.key+":"+"es"}
                            //value={data.es}
                            defaultValue={data.es}
                            onChange={()=>(e)=>addWord(e,i,"es")}
                            onBlur={(e)=>saveLang(data.key,"es",e)}
                            style={{border:data.es?"":"solid",borderColor: data.es?"":"red"}}
                            autoComplete="given-name"
                            className='block  w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6'
                        />
                    </div>
                    <div className="sm:col-span-3">
                        <label htmlFor={data.key} className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                        Ingles
                        </label>
                        <input
                            type={"text"}
                            name={data.key+":"+"en"}
                            id={data.key+":"+"en"}
                            defaultValue={data.en}
                            onChange={(e)=>addWord(e,i,"en")}
                            onBlur={(e)=>saveLang(data.key,"en",e)}
                            autoComplete="given-name"
                            style={{border:data.en?"":"solid",borderColor: data.en?"":"red"}}
                            className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                        />
                    </div>
                    <div className="sm:col-span-3">
                        <label htmlFor={data.key} className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                        Portuges
                        </label>
                        <input
                            type={"text"}
                            name={data.key+":"+"pt"}
                            id={data.key+":"+"pt"}
                            //value={data.pt}
                            defaultValue={data.pt}
                            onChange={(e)=>addWord(e,i,"pt")}
                            onBlur={(e)=>saveLang(data.key,"pt",e)}
                            style={{border:data.pt?"":"solid",borderColor: data.pt?"":"red"}}
                            autoComplete="given-name"
                            className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                        />
                    </div>
                    
                    
                    </div>
                    <br/>
                </div>
            )}
        </div>
    </div>
  )
}
