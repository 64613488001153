import React, {useCallback } from 'react'
import lan from '../../translate'
import Combobox from '../../components/combobox/combobox'
import { useNavigate  } from "react-router-dom"
const bodyPsgField = {
    document:"",
    name:"",
    lastname:"",
    document_exp:"",
    year_old:0,
    birthday:"",
    nacionality:"",
    sex:"",
    position:0,
    pnr:""
}

export default function PaxBody({services,location, body,setBody,setBodyData,dispatch,i}) {
    
    const navigate = useNavigate();

    function addPassenger() {
        body.passengers.push(bodyPsgField)
        setBody({ ...body, body });
    }
    function removePassenger(i) {
        body.passengers.splice(i,1)
        setBody({ ...body, body });
    }

    function editPassenger(i) {
      let data = body.passengers[i]
      delete data.position
      navigate("/passengers/new",{state:data})
    }
    function createPassenger(i) {
      let data = body.passengers[i]
      delete data.position
      navigate("/passengers/new")
    }

    const setBodyDataSelect = useCallback(event => {
        console.log('event', event)
        dispatch({ type: "add", value: event, position:i})
    });
    console.log('body', body.passengers , i)
    return (
        <div className="bg-white shadow-sm dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-slate-800 ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
        <div className="px-4 py-6 sm:p-8">
            <div>
                <label className='text-blue-primary'><b>Pasajero {i+1}</b></label>
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-6">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                    Buscar el pasajero
                </label>
                <Combobox
                    nameDefault={i}
                    labels={["name","lastname","document"]}
                    services={services.Passengers.findAllLike} 
                    value={body.passengers[i]?.document}
                    //method={setBodyDataSelect}
                    allDataMethod={setBodyDataSelect}
                    />
                
                </div>
                <div className="sm:col-span-2">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                {lan.passengers_field_document} 
                </label>
                <div className="mt-2">
                    <input
                    type="text"
                    name={i+".document"}
                    id={i+".document"}
                    value={body.passengers[i]?.document}
                    autoComplete="given-name"
                    disabled
                    className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                    />
                </div>
                
                </div>
                    
                <div className="sm:col-span-2">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                {lan.passengers_field_name} 
                </label>
                <div className="mt-2">
                    <input
                    type="text"
                    name={i+".name"}
                    id={i+".name"}
                    value={body.passengers[i]?.name}
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                    />
                </div>
                
                </div>

                <div className="sm:col-span-2">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                {lan.passengers_field_lastname} 
                </label>
                <div className="mt-2">
                    <input
                    type="text"
                    name={i+".lastname"}
                    id={i+".lastname"}
                    value={body.passengers[i]?.lastname}
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                    />
                </div>
                
                </div>

                <div className="sm:col-span-2">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                {lan.passengers_field_document_exp} 
                </label>
                <div className="mt-2">
                    <input
                    type="text"
                    name={i+".document_exp"}
                    id={i+".document_exp"}
                    value={body.passengers[i]?.document_exp}
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                    />
                </div>
                </div>

                <div className="sm:col-span-2">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                {lan.passengers_field_yearsold} 
                </label>
                <div className="mt-2">
                    <input
                    type="number"
                    name={i+".year_old"}
                    id={i+".year_old"}
                    value={body.passengers[i]?.year_old}
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                    />
                </div>
                </div>

                <div className="sm:col-span-2">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                {lan.passengers_field_bithday} 
                </label>
                <div className="mt-2">
                    <input
                    type="text"
                    name={i+".birthday"}
                    id={i+".birthday"}
                    value={body.passengers[i]?.birthday}
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                    />
                </div>
                </div>

                <div className="sm:col-span-2">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                {lan.passengers_field_nacionality} 
                </label>
                <div className="mt-2">
                    <input
                    type="text"
                    name={i+".nacionality"}
                    id={i+".nacionality"}
                    value={body.passengers[i]?.nacionality}
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                    />
                </div>
                </div>

                <div className="sm:col-span-2">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                {lan.passengers_field_sex} 
                </label>
                <div className="mt-2">
                    <input
                    type="text"
                    name={i+".sex"}
                    id={i+".sex"}
                    value={body.passengers[i]?.sex}
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                    />
                </div>
                </div>
                <div className="sm:col-span-2">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                {lan.workflight_field_code} 
                </label>
                <div className="mt-2">
                    <input
                    type="text"
                    name={i+".pnr"}
                    id={i+".pnr"}
                    value={body.passengers[i]?.pnr}
                    onChange={(e)=>dispatch({ type: "update", value: e.target.value, position:i})}
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                    />
                </div>
                </div>
            
                
                </div>
                <br/>
                    <button  type="button" className="px-3 py-2 text-sm font-semibold text-white bg-red-700 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" 
                        onClick={()=>removePassenger(i)}>
                        {lan.workflight_btn_remove_psg}
                    </button>&nbsp;&nbsp;
                    <button  type="button" className="px-3 py-2 text-sm font-semibold text-white bg-green-700 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" 
                        onClick={()=>createPassenger(i)}>
                        {lan.workflight_btn_creat_psg}
                    </button>&nbsp;&nbsp;
                    {  body.passengers[i]?.document&&
                      <button type="button" className="px-3 py-2 text-sm font-semibold text-white bg-blue-700 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" 
                      onClick={()=>editPassenger(i)}>
                      {lan.workflight_btn_edit_psg}
                      </button>
                    }
                    <br/>
                    <br/>
                    <hr/>
                    <br/>
            </div>
        </div>

        <div className="flex items-center justify-end px-4 py-4 border-t col-span-full gap-x-6 border-gray-900/10 dark:border-white/10 sm:px-8">
          
          
              <button
              onClick={()=> addPassenger()}
              className="px-3 py-2 text-sm font-semibold text-white bg-indigo-500 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {lan.workflight_btn_add_psg}
            </button>
          
        </div>
        
        </div>
    )
}
