import React,{useEffect, useContext,useState} from 'react'
import { PhotoIcon, UserCircleIcon, XCircleIcon, DocumentDuplicateIcon } from '@heroicons/react/24/solid'
import lan from '../../translate/'
import ServicesContext from '../../context/contextServices'
import AlertContext from '../../context/contextAlert'
import moment from 'moment'
import * as Helpers from '../../helpers/helpers'
import ProfileContext from '../../context/contextProfile'
const PdfImage = require("../../images/pdf.png")

export default function FileDocument({accept=".jpg,.jpeg,.png,.pdf,.doc,.docx",ruteFile,position,fileList,setMethod,category}) {
    const [fileInfo, setFileInfo] = useState({
        base64:"",
        name:"",
        type:""
    })
    const MySwal = useContext(AlertContext)
    const services =  useContext(ServicesContext)
    const {setUser, user, getUserInfo} =  useContext(ProfileContext)
    const [loading, setLoading] = useState(false)
    function convertBase64(file) {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file)
          fileReader.onload = () => {
            resolve(fileReader.result);
          }
          fileReader.onerror = (error) => {
            reject(error);
          }
        })
    }

    async function handleFileRead(event,position){
        setLoading(true)
        const file = event.target.files[0]
        if(file.size> 9011497){
            setLoading(false)
            return alert("El archivo es muy pesado")
        }
        const base64 = await convertBase64(file)

        setFileInfo({
            file:base64,
            name:file.name,
            type:file.type
        })
        setMethod(
            {
                type: "update", 
                value: {
                    file:base64,
                    name:file.name,
                    type:file.type,
                    public_id:file.name,
                    fileRef:ruteFile,
                    category:category,
                    description:file.type,
                    dateCreate:moment().format('DD/MM/YYYY'),
                    hourCreate:moment().hours() + ":"+ moment().minutes(),
                    user_id:user._id 
                }, 
                position:position
            }
        )
        setLoading(false)
    }

    async function runMethodFile(){
        MySwal.fire({
            title: lan.loading_save,
            allowOutsideClick: false,
          })
          MySwal.showLoading();
        if(fileList[position].id){
          const fileRemove = await services.File.removeFile(fileList[position].id)
          const fileRemoveRute = await services.File.removeFileByRute({fileRef:fileList[position].public_id})
          setMethod({type: "remove", value: fileList[position].name, position:position})
        }
        
        
        MySwal.close()
    }
    return (
      <div className='flex flex-wrap gap-12 !w-full'>
           <div className="flex justify-center !w-full col-span-1 gap-12 px-6 py-10 mt-2 border border-indigo-600 border-dashed rounded-lg">

                  <div className="w-full text-center">
                    {fileList[position].type && fileList[position].type.split('/')[0] === "image" &&
                       
                       <a href={fileList[position].file} target="_blank">
                            <img src={fileList[position].file} className="w-16 h-16 mx-auto text-gray-300 cursor-pointer"/>
                        </a>
                    }
                    {fileList[position].type === "application/pdf"&&
                        <a href={fileList[position].file} target="_blank">
                            <img src={PdfImage} className="w-16 h-16 mx-auto text-gray-300 cursor-pointer"/>
                        </a>
                    }

                    {fileList[position].type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"&&
                        <a href={fileList[position].file} target="_blank">
                          <DocumentDuplicateIcon className="w-12 h-12 mx-auto text-gray-300" aria-hidden="true" />
                        </a>
                    }
                    
                    {!fileList[position].file &&
                        <PhotoIcon className="w-12 h-12 mx-auto text-gray-300" aria-hidden="true" />
                    }
                    {loading&&
                        <div
                            className="inline-block h-8 w-8 animate-spin rounded-full  text-blue-secondary border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                            role="status">
                            <span
                            className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                            >Loading...</span>
                        </div>  
                    }
                    <div className="flex justify-center gap-12 mt-4 text-sm leading-6 text-center text-gray-600">
                    {!fileList[position].file &&
                      <label
                        htmlFor={position+"file"}
                        className="w-full font-semibold text-center text-indigo-600 bg-white rounded-md cursor-pointer dark:bg-transparent focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                      >
                        <span>{lan.file_document_input}</span>
                        <input  accept={accept}  onChange={(e)=> handleFileRead(e,position)} id={position+"file"} name={position+"file"} type="file" className="sr-only" />
                      </label>
}
                    </div>
                    <p className="w-full text-xs leading-5 text-gray-600 md:text-xs">{fileList[position].name|| accept.toUpperCase()+ " up to 9MB"}</p>
                    <br/>
                    {fileList[position].name&&
                    <button type="button" className="px-3 py-2 text-sm font-semibold text-white bg-red-700 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" 
                        onClick={()=> runMethodFile()}>
                        {lan.generic_list_btn_delete}
                    </button>
                    }
                    
                  </div>
                </div>
      </div>
    )
  }